import * as React from 'react'

import { IconComponent } from '../../../../types'

export const MoonriverCircle: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 128 128" {...props}>
    <rect width="128" height="128" fill="url(#paint0_radial_303_494)" rx="64"></rect>
    <g clipPath="url(#clip0_303_494)">
      <path
        fill="#F2B705"
        d="M63.447 25C47.16 25 33.957 38.225 33.957 54.539c0 1.865.175 3.73.522 5.563l3.809-2.615c6.698-4.601 17.56-4.601 24.257 0l3.998 2.743 2.165 1.49c6.037 4.146 15.817 4.146 21.848 0l1.776-1.219c3.272-15.992-7.02-31.613-22.988-34.888a29.424 29.424 0 00-5.886-.6L63.446 25z"
      ></path>
      <path
        fill="#53CBC8"
        d="M80.02 72.286c-4.444 0-8.692-1.207-11.89-3.404l-6.159-4.227c-2.95-2.03-6.909-3.15-11.141-3.15-4.233 0-8.192 1.113-11.142 3.15l-3.981 2.73c-.788.541-1.865.34-2.404-.449a1.734 1.734 0 01.449-2.408l3.982-2.731c3.52-2.42 8.17-3.75 13.096-3.75s9.573 1.33 13.096 3.75l6.159 4.227a19.008 19.008 0 0019.87 0l2.338-1.602a1.728 1.728 0 012.404.45c.54.789.339 1.867-.449 2.407l-2.338 1.603c-3.215 2.203-7.448 3.404-11.889 3.404h-.002z"
      ></path>
      <path
        fill="#53CBC8"
        d="M50.702 84.692v-16.69c-.014-.35 0-.702.044-1.052a1.88 1.88 0 011.866-1.669 1.818 1.818 0 011.76 1.67c.07.578.09 1.164.06 1.746v33.279c-.1 1.235-.872 2.046-1.91 2.024-1.039-.022-1.788-.868-1.794-2.119V90.789l-.029-6.097h.003zM47.197 76.29v8.295c0 1.418-.756 2.358-1.827 2.326-1.072-.034-1.882-.99-1.888-2.342V67.986c0-1.452.844-2.43 1.943-2.376 1.098.057 1.754.934 1.76 2.386.016 2.766.012 5.53.012 8.295zM57.955 76.279v-5.83c0-1.352.716-2.226 1.782-2.226 1.067 0 1.89.902 1.9 2.175.04 3.969.04 7.935 0 11.9 0 1.351-.795 2.165-1.89 2.153-1.094-.012-1.794-.84-1.804-2.225v-5.953l.012.006zM36.262 77.492v-5.83c0-1.352.716-2.226 1.782-2.226 1.067 0 1.889.902 1.9 2.176a655.55 655.55 0 010 11.899c0 1.352-.8 2.165-1.89 2.153-1.088-.012-1.794-.84-1.804-2.225v-5.953l.012.006zM67.001 72.53h-.006c-1.008 0-1.826.818-1.826 1.829v3.173c0 1.01.818 1.83 1.826 1.83h.006c1.01 0 1.827-.82 1.827-1.83V74.36c0-1.01-.818-1.83-1.827-1.83zM74.243 75.307h-.006c-1.009 0-1.826.819-1.826 1.83v17.785c0 1.01.817 1.83 1.826 1.83h.006c1.009 0 1.827-.82 1.827-1.83V77.137c0-1.011-.818-1.83-1.827-1.83zM81.435 75.996h-.006c-1.009 0-1.827.819-1.827 1.83v15.116c0 1.01.818 1.83 1.827 1.83h.006c1.008 0 1.826-.82 1.826-1.83V77.825c0-1.01-.817-1.83-1.826-1.83zM88.727 74.27h-.007c-1.008 0-1.826.82-1.826 1.83v6.521c0 1.01.818 1.83 1.826 1.83h.007c1.008 0 1.826-.82 1.826-1.83v-6.52c0-1.011-.818-1.83-1.826-1.83z"
      ></path>
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_303_494"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(90 0 64) scale(85.5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3F398D"></stop>
        <stop offset="1" stopColor="#3F1262"></stop>
      </radialGradient>
      <clipPath id="clip0_303_494">
        <path fill="#fff" d="M0 0H62V79H0z" transform="translate(33 25)"></path>
      </clipPath>
    </defs>
  </svg>
)
