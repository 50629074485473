// src/Big.ts
import Big from "big.js";
import toFormat from "toformat";
Big.strict = true;
var Big_default = toFormat(Big);

// src/Percent.ts
import { formatPercent } from "@sushiswap/format";

// src/Fraction.ts
import invariant from "tiny-invariant";

// src/Decimal.ts
import Decimal from "decimal.js-light";
import toFormat2 from "toformat";
var Decimal_default = toFormat2(Decimal);

// src/Rounding.ts
var Rounding = /* @__PURE__ */ ((Rounding2) => {
  Rounding2[Rounding2["ROUND_DOWN"] = 0] = "ROUND_DOWN";
  Rounding2[Rounding2["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
  Rounding2[Rounding2["ROUND_UP"] = 3] = "ROUND_UP";
  return Rounding2;
})(Rounding || {});
var Rounding_default = Rounding;

// src/Fraction.ts
var toSignificantRounding = {
  [Rounding_default.ROUND_DOWN]: Decimal_default.ROUND_DOWN,
  [Rounding_default.ROUND_HALF_UP]: Decimal_default.ROUND_HALF_UP,
  [Rounding_default.ROUND_UP]: Decimal_default.ROUND_UP
};
var toFixedRounding = {
  [Rounding_default.ROUND_DOWN]: Big_default.roundDown,
  [Rounding_default.ROUND_HALF_UP]: Big_default.roundHalfUp,
  [Rounding_default.ROUND_UP]: Big_default.roundUp
};
var Fraction = class _Fraction {
  constructor(numerator, denominator = 1n) {
    this.numerator = typeof numerator !== "bigint" ? BigInt(numerator) : numerator;
    this.denominator = typeof denominator !== "bigint" ? BigInt(denominator) : denominator;
  }
  static tryParseFraction(fractionish) {
    if (typeof fractionish === "number" || typeof fractionish === "string" || typeof fractionish === "bigint")
      return new _Fraction(fractionish);
    if ("numerator" in fractionish && "denominator" in fractionish)
      return fractionish;
    throw new Error("Could not parse fraction");
  }
  // performs floor division
  get quotient() {
    return this.numerator / this.denominator;
  }
  // remainder after floor division
  get remainder() {
    return new _Fraction(this.numerator % this.denominator, this.denominator);
  }
  invert() {
    return new _Fraction(this.denominator, this.numerator);
  }
  add(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    if (this.denominator === otherParsed.denominator) {
      return new _Fraction(this.numerator + otherParsed.numerator, this.denominator);
    }
    return new _Fraction(
      this.numerator * otherParsed.denominator + otherParsed.numerator * this.denominator,
      this.denominator * otherParsed.denominator
    );
  }
  subtract(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    if (this.denominator === otherParsed.denominator) {
      return new _Fraction(this.numerator - otherParsed.numerator, this.denominator);
    }
    return new _Fraction(
      this.numerator * otherParsed.denominator - otherParsed.numerator * this.denominator,
      this.denominator * otherParsed.denominator
    );
  }
  lessThan(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return this.numerator * otherParsed.denominator < otherParsed.numerator * this.denominator;
  }
  equalTo(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return this.numerator * otherParsed.denominator === otherParsed.numerator * this.denominator;
  }
  greaterThan(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return this.numerator * otherParsed.denominator > otherParsed.numerator * this.denominator;
  }
  multiply(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return new _Fraction(this.numerator * otherParsed.numerator, this.denominator * otherParsed.denominator);
  }
  divide(other) {
    const otherParsed = _Fraction.tryParseFraction(other);
    return new _Fraction(this.numerator * otherParsed.denominator, this.denominator * otherParsed.numerator);
  }
  toSignificant(significantDigits, format = { groupSeparator: "" }, rounding = Rounding_default.ROUND_HALF_UP) {
    invariant(Number.isInteger(significantDigits), `${significantDigits} is not an integer.`);
    invariant(significantDigits > 0, `${significantDigits} is not positive.`);
    Decimal_default.set({
      precision: significantDigits + 1,
      rounding: toSignificantRounding[rounding]
    });
    const quotient = new Decimal_default(this.numerator.toString()).div(this.denominator.toString()).toSignificantDigits(significantDigits);
    return quotient.toFormat(quotient.decimalPlaces(), format);
  }
  toFixed(decimalPlaces, format = { groupSeparator: "" }, rounding = Rounding_default.ROUND_HALF_UP) {
    invariant(Number.isInteger(decimalPlaces), `${decimalPlaces} is not an integer.`);
    invariant(decimalPlaces >= 0, `${decimalPlaces} is negative.`);
    Big_default.DP = decimalPlaces;
    Big_default.RM = toFixedRounding[rounding];
    return new Big_default(this.numerator.toString()).div(this.denominator.toString()).toFormat(decimalPlaces, format);
  }
  toJSON() {
    return {
      n: this.numerator.toString(),
      d: this.denominator.toString()
    };
  }
  /**
   * Helper method for converting any super class back to a fraction
   */
  get asFraction() {
    return new _Fraction(this.numerator, this.denominator);
  }
};
var Fraction_default = Fraction;

// src/Percent.ts
var ONE_HUNDRED = new Fraction_default(100n);
function toPercent(fraction) {
  return new Percent(fraction.numerator, fraction.denominator);
}
var Percent = class extends Fraction_default {
  constructor() {
    super(...arguments);
    /**
     * This boolean prevents a fraction from being interpreted as a Percent
     */
    this.isPercent = true;
  }
  add(other) {
    return toPercent(super.add(other));
  }
  subtract(other) {
    return toPercent(super.subtract(other));
  }
  multiply(other) {
    return toPercent(super.multiply(other));
  }
  divide(other) {
    return toPercent(super.divide(other));
  }
  toSignificant(significantDigits = 5, format, rounding) {
    return super.multiply(ONE_HUNDRED).toSignificant(significantDigits, format, rounding);
  }
  toFixed(decimalPlaces = 2, format, rounding) {
    return super.multiply(ONE_HUNDRED).toFixed(decimalPlaces, format, rounding);
  }
  toPercentageString(decimalPlaces = 2) {
    return formatPercent(super.toFixed(decimalPlaces + 2));
  }
};
var Percent_default = Percent;

// src/constants/numbers.ts
var ZERO = 0n;
var ONE = 1n;
var TWO = 2n;
var THREE = 3n;
var FIVE = 5n;
var TEN = 10n;
var _100 = 100n;
var _997 = 997n;
var _1000 = 1000n;
var _9994 = 9994n;
var _9995 = 9995n;
var _10000 = 10000n;
var _1e18 = 10n ** 18n;
var _1e12 = 10n ** 12n;
var _1e9 = 10n ** 9n;
var _1e6 = 10n ** 6n;
var MAX_SAFE_INTEGER = BigInt(Number.MAX_SAFE_INTEGER);
var MAX_UINT256 = BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
var MAX_UINT128 = 2n ** 128n - 1n;
var ZERO_PERCENT = new Percent_default(0);

// src/functions/abs.ts
function abs(x) {
  return x < 0n ? -x : x;
}

// src/functions/difference.ts
function difference(a, b) {
  if (a > b) {
    return a - b;
  }
  return b - a;
}

// src/functions/maximum.ts
function maximum(...values) {
  let highest = values[0];
  for (let i = 1; i < values.length; i++) {
    const value = values[i];
    if (value > highest) {
      highest = value;
    }
  }
  return highest;
}

// src/functions/minimum.ts
function minimum(...values) {
  let lowest = values[0];
  for (let i = 1; i < values.length; i++) {
    const value = values[i];
    if (value < lowest) {
      lowest = value;
    }
  }
  return lowest;
}

// src/functions/sqrt.ts
import invariant2 from "tiny-invariant";
function sqrt(value) {
  invariant2(value >= ZERO, "NEGATIVE");
  if (value < MAX_SAFE_INTEGER) {
    return BigInt(Math.floor(Math.sqrt(Number(value))));
  }
  let z;
  let x;
  z = value;
  x = value / TWO + ONE;
  while (x < z) {
    z = x;
    x = (value / x + x) / TWO;
  }
  return z;
}
export {
  Big_default as Big,
  Decimal_default as Decimal,
  FIVE,
  Fraction_default as Fraction,
  MAX_SAFE_INTEGER,
  MAX_UINT128,
  MAX_UINT256,
  ONE,
  Percent_default as Percent,
  Rounding_default as Rounding,
  TEN,
  THREE,
  TWO,
  ZERO,
  ZERO_PERCENT,
  _100,
  _1000,
  _10000,
  _1e12,
  _1e18,
  _1e6,
  _1e9,
  _997,
  _9994,
  _9995,
  abs,
  difference,
  maximum,
  minimum,
  sqrt
};
