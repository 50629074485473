import * as React from 'react'

import { IconComponent } from '../../../../types'

export const GnosisNaked: IconComponent = (props) => (
  <svg
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className="dark:text-white text-gray-700"
  >
    <g clipPath="url(#a)" fill="currentColor">
      <path d="M47.514 70.12c2.3 0 4.542-.775 6.355-2.195L39.29 53.165c-3.513 4.599-2.672 11.222 1.87 14.78a10.465 10.465 0 0 0 6.355 2.177ZM90.879 59.58c0-2.328-.767-4.6-2.169-6.435L74.13 67.906c4.543 3.558 11.066 2.707 14.58-1.892a10.623 10.623 0 0 0 2.169-6.435Z" />
      <path d="m98.224 43.512-6.448 6.53c5.196 6.301 4.374 15.688-1.85 20.95a14.544 14.544 0 0 1-18.842 0L64 78.163l-7.065-7.154c-6.225 5.262-15.496 4.429-20.692-1.873-4.56-5.526-4.56-13.55 0-19.077l-3.308-3.35-3.14-3.198A40.8 40.8 0 0 0 24 64.5C24 86.87 41.907 105 64 105c22.094 0 40-18.13 40-40.5.019-7.4-2-14.667-5.776-20.988Z" />
      <path d="M92.935 36.547C77.7 20.367 52.393 19.761 36.41 35.185c-.467.454-.916.908-1.346 1.362a42.796 42.796 0 0 0-2.803 3.312L64 72.013 95.738 39.86c-.86-1.154-1.813-2.252-2.803-3.312ZM64 29.3c9.346 0 18.056 3.653 24.598 10.314L64 64.52 39.402 39.613C45.944 32.952 54.654 29.3 64 29.3Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currenColor" transform="translate(24 24)" d="M0 0h80v81H0z" />
      </clipPath>
    </defs>
  </svg>
)
