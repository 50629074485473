// src/Token.ts
import { getAddress } from "@ethersproject/address";
import invariant2 from "tiny-invariant";

// src/Currency.ts
import invariant from "tiny-invariant";
var Currency = class {
  /**
   * The chain ID on which this currency resides
   */
  chainId;
  /**
   * The decimals used in representing currency amounts
   */
  decimals;
  /**
   * The symbol of the currency, i.e. a short textual non-unique identifier
   */
  symbol;
  /**
   * The name of the currency, i.e. a descriptive textual non-unique identifier
   */
  name;
  /**
   * Constructs an instance of the abstract class `Currency`.
   * @param chainId the chain ID on which this currency resides
   * @param decimals decimals of the currency
   * @param symbol symbol of the currency
   * @param name of the currency
   * @param rebase of the currency
   */
  constructor({
    chainId: _chainId,
    decimals: _decimals,
    symbol,
    name
  }) {
    const chainId = Number(_chainId);
    const decimals = Number(_decimals);
    invariant(Number.isSafeInteger(chainId), "CHAIN_ID");
    invariant(decimals >= 0 && decimals < 255 && Number.isInteger(decimals), "DECIMALS");
    this.chainId = chainId;
    this.decimals = decimals;
    this.symbol = symbol;
    this.name = name;
  }
};

// src/zod.ts
import z from "zod";
var nativeSchema = z.object({
  isNative: z.literal(true),
  name: z.string().optional(),
  symbol: z.string().optional(),
  decimals: z.number(),
  chainId: z.number()
});
var tokenSchema = z.object({
  isNative: z.literal(false),
  name: z.string().optional(),
  symbol: z.string().optional(),
  decimals: z.number(),
  chainId: z.number(),
  address: z.string()
});
var amountSchema = z.object({
  amount: z.string(),
  currency: z.discriminatedUnion("isNative", [nativeSchema, tokenSchema])
});

// src/Token.ts
var Token = class _Token extends Currency {
  id;
  isNative = false;
  isToken = true;
  /**
   * The contract address on the chain on which this token lives
   */
  address;
  constructor({
    // TODO:
    // id,
    chainId,
    address,
    decimals,
    symbol,
    name
  }) {
    super({
      chainId,
      decimals,
      symbol,
      name
    });
    try {
      this.address = getAddress(address);
      this.id = `${chainId}:${address}`;
    } catch {
      throw `${address} is not a valid address`;
    }
  }
  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  equals(other) {
    return other.isToken && this.chainId === other.chainId && this.address === other.address;
  }
  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  sortsBefore(other) {
    invariant2(this.chainId === other.chainId, "CHAIN_IDS");
    invariant2(this.address !== other.address, "ADDRESSES");
    return this.address.toLowerCase() < other.address.toLowerCase();
  }
  /**
   * Return this token, which does not need to be wrapped
   */
  get wrapped() {
    return this;
  }
  // public get tokenURI(): string {
  //   return `tokens/${this.chainId}/${this.address}.jpg`
  // }
  /**
   * Serialize to JSON object
   */
  serialize() {
    return tokenSchema.parse({
      isNative: this.isNative,
      name: this.name,
      symbol: this.symbol,
      decimals: this.decimals,
      chainId: this.chainId,
      address: this.address
    });
  }
  static deserialize({ name, symbol, address, decimals, chainId }) {
    return new _Token({
      name,
      symbol,
      address,
      decimals,
      chainId
    });
  }
};

// src/addressMapToTokenMap.ts
function addressMapToTokenMap({ decimals, symbol, name }, map) {
  return Object.fromEntries(
    Object.entries(map).map(([chainId, address]) => [
      chainId,
      new Token({
        chainId,
        address,
        decimals,
        symbol,
        name
      })
    ])
  );
}

// src/Amount.ts
import { Big as Big2, Fraction as Fraction2, MAX_UINT256, Rounding as Rounding2, ZERO as ZERO2 } from "@sushiswap/math";
import invariant5 from "tiny-invariant";

// src/Native.ts
import { chains } from "@sushiswap/chain";
import invariant3 from "tiny-invariant";

// src/constants/tokens.ts
import { ChainId as ChainId2 } from "@sushiswap/chain";

// src/constants/token-addresses.ts
import { ChainId } from "@sushiswap/chain";
var AMPL_ADDRESS = {
  [ChainId.ETHEREUM]: "0xD46bA6D942050d489DBd938a2C909A5d5039A161"
};
var axlUSDC_ADDRESS = {
  [ChainId.ARBITRUM]: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
  [ChainId.AVALANCHE]: "0xfaB550568C688d5D8A52C7d794cb93Edc26eC0eC",
  [ChainId.BASE]: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
  [ChainId.BSC]: "0x4268B8F0B87b6Eae5d897996E6b845ddbD99Adf3",
  [ChainId.CELO]: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
  [ChainId.ETHEREUM]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  [ChainId.FANTOM]: "0x1B6382DBDEa11d97f24495C9A90b7c88469134a4",
  // [ChainId.FILECOIN]: '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
  [ChainId.KAVA]: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
  [ChainId.LINEA]: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
  [ChainId.MOONBEAM]: "0xCa01a1D0993565291051daFF390892518ACfAD3A",
  [ChainId.OPTIMISM]: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
  [ChainId.POLYGON]: "0x750e4C4984a9e0f12978eA6742Bc1c5D248f40ed"
};
var axlDAI_ADDRESS = {
  [ChainId.ARBITRUM]: "0x5C7e299CF531eb66f2A1dF637d37AbB78e6200C7",
  [ChainId.AVALANCHE]: "0xC5Fa5669E326DA8B2C35540257cD48811F40a36B",
  [ChainId.BASE]: "0x5C7e299CF531eb66f2A1dF637d37AbB78e6200C7",
  [ChainId.BSC]: "0xF02eaeEa1350DAD8fc7A66d6BddB25876243ed1F",
  [ChainId.CELO]: "0x5C7e299CF531eb66f2A1dF637d37AbB78e6200C7",
  [ChainId.ETHEREUM]: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  [ChainId.FANTOM]: "0xD5d5350F42CB484036A1C1aF5F2DF77eAFadcAFF",
  // [ChainId.FILECOIN]: '0x5C7e299CF531eb66f2A1dF637d37AbB78e6200C7',
  [ChainId.KAVA]: "0x5C7e299CF531eb66f2A1dF637d37AbB78e6200C7",
  [ChainId.LINEA]: "0x5C7e299CF531eb66f2A1dF637d37AbB78e6200C7",
  [ChainId.MOONBEAM]: "0x14dF360966a1c4582d2b18EDbdae432EA0A27575",
  [ChainId.OPTIMISM]: "0x5C7e299CF531eb66f2A1dF637d37AbB78e6200C7",
  [ChainId.POLYGON]: "0xDDc9E2891FA11a4CC5C223145e8d14B44f3077c9"
};
var MANA_ADDRESS = {
  [ChainId.POLYGON]: "0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4"
};
var MKR_ADDRESS = {
  [ChainId.ETHEREUM]: "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2",
  [ChainId.POLYGON]: "0x6f7C932e7684666C9fd1d44527765433e01fF61d",
  [ChainId.AVALANCHE]: "0x88128fd4b259552a9a1d457f435a6527aab72d42",
  [ChainId.ARBITRUM]: "0x2e9a6Df78E42a30712c10a9Dc4b1C8656f8F2879"
};
var YFI_ADDRESS = {
  [ChainId.ETHEREUM]: "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
  [ChainId.POLYGON]: "0xDA537104D6A5edd53c6fBba9A898708E465260b6",
  [ChainId.GNOSIS]: "0xbf65bfcb5da067446CeE6A706ba3Fe2fB1a9fdFd",
  [ChainId.HARMONY]: "0xa0dc05F84A27FcCBD341305839019aB86576bc07",
  [ChainId.AVALANCHE]: "0x9eAaC1B23d935365bD7b542Fe22cEEe2922f52dc",
  [ChainId.FANTOM]: "0x29b0Da86e484E1C0029B56e817912d778aC0EC69",
  [ChainId.ARBITRUM]: "0x82e3a8f066a6989666b031d916c43672085b1582"
};
var ENJ_ADDRESS = {
  [ChainId.ETHEREUM]: "0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c",
  [ChainId.HARMONY]: "0xadbd41bFb4389dE499535C14A8a3A12Fead8F66A"
};
var CRV_ADDRESS = {
  [ChainId.ETHEREUM]: "0xD533a949740bb3306d119CC777fa900bA034cd52",
  [ChainId.POLYGON]: "0x172370d5Cd63279eFa6d502DAB29171933a610AF",
  [ChainId.FANTOM]: "0x1E4F97b9f9F913c46F1632781732927B9019C68b",
  [ChainId.ARBITRUM]: "0x11cdb42b0eb46d95f990bedd4695a6e3fa034978",
  [ChainId.OPTIMISM]: "0x0994206dfE8De6Ec6920FF4D779B0d950605Fb53"
};
var GALA_ADDRESS = {
  [ChainId.ETHEREUM]: "0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA"
};
var MATIC_ADDRESS = {
  [ChainId.POLYGON_ZKEVM]: "0xa2036f0538221a77A3937F1379699f44945018d0"
};
var GNO_ADDRESS = {
  [ChainId.GNOSIS]: "0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb"
};
var ARB_ADDRESS = {
  [ChainId.ARBITRUM]: "0x912CE59144191C1204E64559FE8253a0e49E6548",
  [ChainId.ARBITRUM_NOVA]: "0xf823C3cD3CeBE0a1fA952ba88Dc9EEf8e0Bf46AD",
  [ChainId.ETHEREUM]: "0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1"
};
var KP3R_ADDRESS = {
  [ChainId.ETHEREUM]: "0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44"
};
var LDO_ADDRESS = {
  [ChainId.ETHEREUM]: "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32"
};
var APE_ADDRESS = {
  [ChainId.ETHEREUM]: "0x4d224452801ACEd8B2F0aebE155379bb5D594381"
};
var PRIMATE_ADDRESS = {
  [ChainId.ETHEREUM]: "0x46e98FFE40E408bA6412bEb670507e083C8B95ff"
};
var rETH2_ADDRESS = {
  [ChainId.ETHEREUM]: "0x20BC832ca081b91433ff6c17f85701B6e92486c5"
};
var sETH2_ADDRESS = {
  [ChainId.ETHEREUM]: "0xFe2e637202056d30016725477c5da089Ab0A043A"
};
var SWISE_ADDRESS = {
  [ChainId.ETHEREUM]: "0x48C3399719B582dD63eB5AADf12A40B4C3f52FA2"
};
var FEI_ADDRESS = {
  [ChainId.ETHEREUM]: "0x956F47F50A910163D8BF957Cf5846D573E7f87CA"
};
var TRIBE_ADDRESS = {
  [ChainId.ETHEREUM]: "0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B"
};
var renBTC_ADDRESS = {
  [ChainId.ETHEREUM]: "0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D"
};
var NFTX_ADDRESS = {
  [ChainId.ETHEREUM]: "0x87d73E916D7057945c9BcD8cdd94e42A6F47f776"
};
var OHM_ADDRESS = {
  [ChainId.ETHEREUM]: "0x64aa3364F17a4D01c6f1751Fd97C2BD3D7e7f1D5"
};
var SNX_ADDRESS = {
  [ChainId.ETHEREUM]: "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F",
  [ChainId.POLYGON]: "0x50B728D8D964fd00C2d0AAD81718b71311feF68a",
  [ChainId.FANTOM]: "0x56ee926bD8c72B2d5fa1aF4d9E4Cbb515a1E3Adc",
  [ChainId.HARMONY]: "0x7b9c523d59AeFd362247Bd5601A89722e3774dD2",
  [ChainId.AVALANCHE]: "0xBeC243C995409E6520D7C41E404da5dEba4b209B",
  [ChainId.OPTIMISM]: "0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4"
};
var FTM_ADDRESS = {
  [ChainId.ETHEREUM]: "0x4E15361FD6b4BB609Fa63C81A2be19d873717870"
};
var WBTC_ADDRESS = {
  [ChainId.AVALANCHE]: "0x50b7545627a5162F82A992c33b87aDc75187B218",
  [ChainId.ARBITRUM]: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
  [ChainId.ETHEREUM]: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
  [ChainId.FANTOM]: "0x321162Cd933E2Be498Cd2267a90534A804051b11",
  [ChainId.POLYGON]: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
  [ChainId.OPTIMISM]: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
  [ChainId.ARBITRUM_NOVA]: "0x1d05e4e72cD994cdF976181CfB0707345763564d",
  [ChainId.BOBA]: "0xdc0486f8bf31DF57a952bcd3c1d3e166e3d9eC8b",
  [ChainId.KAVA]: "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
  [ChainId.METIS]: "0xa5B55ab1dAF0F8e1EFc0eB1931a957fd89B918f4",
  [ChainId.FUSE]: "0x33284f95ccb7B948d9D352e1439561CF83d8d00d",
  [ChainId.POLYGON_ZKEVM]: "0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1",
  [ChainId.THUNDERCORE]: "0x18fB0A62f207A2a082cA60aA78F47a1af4985190",
  [ChainId.HAQQ]: "0x5FD55A1B9FC24967C4dB09C513C3BA0DFa7FF687",
  [ChainId.ZKSYNC_ERA]: "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011"
};
var UNI_ADDRESS = {
  [ChainId.ETHEREUM]: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
  [ChainId.GNOSIS]: "0x4537e328Bf7e4eFA29D05CAeA260D7fE26af9D74",
  [ChainId.OPTIMISM]: "0x6fd9d7AD17242c41f7131d257212c54A0e816691",
  [ChainId.AVALANCHE]: "0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580",
  [ChainId.BSC]: "0xBf5140A22578168FD562DCcF235E5D43A02ce9B1",
  [ChainId.POLYGON]: "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
  [ChainId.HECO]: "0x22C54cE8321A4015740eE1109D9cBc25815C46E6",
  [ChainId.HARMONY]: "0x90D81749da8867962c760414C1C25ec926E889b6",
  [ChainId.ARBITRUM]: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0"
};
var BUSD_ADDRESS = {
  [ChainId.BSC]: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  [ChainId.THUNDERCORE]: "0xbeb0131d95ac3f03fd15894d0ade5dbf7451d171"
};
var MAI_ADDRESS = {
  [ChainId.POLYGON]: "0xa3Fa99A148fA48D14Ed51d610c367C61876997F1",
  [ChainId.FANTOM]: "0xfB98B335551a418cD0737375a2ea0ded62Ea213b",
  [ChainId.AVALANCHE]: "0x5c49b268c9841AFF1Cc3B0a418ff5c3442eE3F3b",
  [ChainId.MOONRIVER]: "0xFb2019DfD635a03cfFF624D210AEe6AF2B00fC2C",
  [ChainId.HARMONY]: "0x3F56e0c36d275367b8C502090EDF38289b3dEa0d",
  [ChainId.ARBITRUM]: "0x3F56e0c36d275367b8C502090EDF38289b3dEa0d",
  [ChainId.BOBA]: "0x3F56e0c36d275367b8C502090EDF38289b3dEa0d",
  [ChainId.GNOSIS]: "0x3F56e0c36d275367b8C502090EDF38289b3dEa0d",
  [ChainId.METIS]: "0xdFA46478F9e5EA86d57387849598dbFB2e964b02",
  [ChainId.BSC]: "0x3F56e0c36d275367b8C502090EDF38289b3dEa0d",
  [ChainId.CELO]: "0xB9C8F0d3254007eE4b98970b94544e473Cd610EC",
  [ChainId.OPTIMISM]: "0xdFA46478F9e5EA86d57387849598dbFB2e964b02",
  [ChainId.MOONBEAM]: "0xdFA46478F9e5EA86d57387849598dbFB2e964b02",
  [ChainId.KAVA]: "0xb84Df10966a5D7e1ab46D9276F55d57bD336AFC7",
  [ChainId.ETHEREUM]: "0x8D6CeBD76f18E1558D4DB88138e2DeFB3909fAD6"
};
var TUSD_ADDRESS = {
  [ChainId.ETHEREUM]: "0x0000000000085d4780B73119b644AE5ecd22b376"
};
var ANKR_ADDRESS = {
  [ChainId.ETHEREUM]: "0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4"
};
var AAVE_ADDRESS = {
  [ChainId.ETHEREUM]: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
  [ChainId.POLYGON]: "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
  [ChainId.FANTOM]: "0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B",
  [ChainId.HARMONY]: "0xcF323Aad9E522B93F11c352CaA519Ad0E14eB40F",
  [ChainId.AVALANCHE]: "0x63a72806098Bd3D9520cC43356dD78afe5D386D9",
  [ChainId.BSC]: "0xfb6115445Bff7b52FeB98650C87f44907E58f802",
  [ChainId.OPTIMISM]: "0x76FB31fb4af56892A25e32cFC43De717950c9278"
};
var COMP_ADDRESS = {
  [ChainId.ETHEREUM]: "0xc00e94Cb662C3520282E6f5717214004A7f26888"
};
var JPY_ADDRESS = {
  [ChainId.ETHEREUM]: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
  [ChainId.POLYGON]: "0x6AE7Dfc73E0dDE2aa99ac063DcF7e8A63265108c",
  [ChainId.AVALANCHE]: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
  [ChainId.GNOSIS]: "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB"
};
var LUSD_ADDRESS = {
  [ChainId.ETHEREUM]: "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
  [ChainId.OPTIMISM]: "0xc40F949F8a4e094D1b49a23ea9241D289B7b2819"
};
var WETH9_ADDRESS = {
  [ChainId.ETHEREUM]: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  [ChainId.ROPSTEN]: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
  [ChainId.RINKEBY]: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
  [ChainId.G\u00D6RLI]: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
  [ChainId.KOVAN]: "0xd0A1E359811322d97991E03f863a0C30C2cF029C",
  [ChainId.ARBITRUM]: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
  [ChainId.ARBITRUM_TESTNET]: "0xf8456e5e6A225C2C1D74D8C9a4cB2B1d5dc1153b",
  [ChainId.BSC]: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
  [ChainId.FANTOM]: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
  [ChainId.POLYGON]: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
  [ChainId.POLYGON_TESTNET]: "0x714550C2C1Ea08688607D86ed8EeF4f5E4F22323",
  [ChainId.OKEX]: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
  [ChainId.HECO]: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
  [ChainId.HARMONY]: "0x6983D1E6DEf3690C4d616b13597A09e6193EA013",
  [ChainId.GNOSIS]: "0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1",
  [ChainId.AVALANCHE]: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
  [ChainId.PALM]: "0x726138359C17F1E56bA8c4F737a7CAf724F6010b",
  [ChainId.CELO]: "0x122013fd7dF1C6F636a5bb8f03108E876548b455",
  [ChainId.MOONRIVER]: "0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C",
  [ChainId.TELOS]: "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
  [ChainId.FUSE]: "0xa722c13135930332Eb3d749B2F0906559D2C5b99",
  [ChainId.MOONBEAM]: "0x30D2a9F5FDf90ACe8c17952cbb4eE48a55D916A7",
  [ChainId.OPTIMISM]: "0x4200000000000000000000000000000000000006",
  [ChainId.METIS]: "0x75cb093E4D61d2A2e65D8e0BBb01DE8d89b53481",
  [ChainId.KAVA]: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
  [ChainId.METIS]: "0x420000000000000000000000000000000000000A",
  [ChainId.ARBITRUM_NOVA]: "0x722E8BdD2ce80A4422E880164f2079488e115365",
  [ChainId.BOBA]: "0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000",
  // [ChainId.SEPOLIA]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  // [ChainId.CONSENSUS_ZKEVM_TESTNET]: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
  // [ChainId.SCROLL_ALPHA_TESTNET]: '0xa1EA0B2354F5A344110af2b6AD68e75545009a03',
  // [ChainId.BASE_TESTNET]: '0x4200000000000000000000000000000000000006',
  [ChainId.BTTC]: "0x1249C65AfB11D179FFB3CE7D4eEDd1D9b98AD006",
  [ChainId.THUNDERCORE]: "0x6576Bb918709906DcbFDCeae4bB1e6df7C8a1077",
  [ChainId.POLYGON_ZKEVM]: "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9",
  [ChainId.CORE]: "0xeAB3aC417c4d6dF6b143346a46fEe1B847B50296",
  [ChainId.HAQQ]: "0xecEEEfCEE421D8062EF8d6b4D814efe4dc898265",
  [ChainId.ZKSYNC_ERA]: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
  [ChainId.LINEA]: "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
  [ChainId.BASE]: "0x4200000000000000000000000000000000000006"
};
var WNATIVE_ADDRESS = {
  [ChainId.ETHEREUM]: WETH9_ADDRESS[ChainId.ETHEREUM],
  [ChainId.ROPSTEN]: WETH9_ADDRESS[ChainId.ROPSTEN],
  [ChainId.RINKEBY]: WETH9_ADDRESS[ChainId.RINKEBY],
  [ChainId.G\u00D6RLI]: WETH9_ADDRESS[ChainId.G\u00D6RLI],
  [ChainId.KOVAN]: WETH9_ADDRESS[ChainId.KOVAN],
  [ChainId.OPTIMISM]: WETH9_ADDRESS[ChainId.OPTIMISM],
  [ChainId.ARBITRUM]: WETH9_ADDRESS[ChainId.ARBITRUM],
  [ChainId.ARBITRUM_TESTNET]: WETH9_ADDRESS[ChainId.ARBITRUM_TESTNET],
  [ChainId.FANTOM]: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
  [ChainId.FANTOM_TESTNET]: "0xf1277d1Ed8AD466beddF92ef448A132661956621",
  [ChainId.POLYGON]: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  [ChainId.POLYGON_TESTNET]: "0x5B67676a984807a212b1c59eBFc9B3568a474F0a",
  [ChainId.GNOSIS]: "0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d",
  [ChainId.BSC]: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  [ChainId.BSC_TESTNET]: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
  [ChainId.AVALANCHE]: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  [ChainId.AVALANCHE_TESTNET]: "0xd00ae08403B9bbb9124bB305C09058E32C39A48c",
  [ChainId.HECO]: "0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F",
  [ChainId.HECO_TESTNET]: "0x5B2DA6F42CA09C77D577a12BeaD0446148830687",
  [ChainId.HARMONY]: "0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a",
  [ChainId.HARMONY_TESTNET]: "0x7a2afac38517d512E55C0bCe3b6805c10a04D60F",
  [ChainId.OKEX]: "0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15",
  [ChainId.OKEX_TESTNET]: "0x2219845942d28716c0F7C605765fABDcA1a7d9E0",
  [ChainId.PALM]: "0xF98cABF0a963452C5536330408B2590567611a71",
  [ChainId.CELO]: "0x471EcE3750Da237f93B8E339c536989b8978a438",
  [ChainId.MOONRIVER]: "0xf50225a84382c74CbdeA10b0c176f71fc3DE0C4d",
  [ChainId.FUSE]: "0x0BE9e53fd7EDaC9F859882AfdDa116645287C629",
  [ChainId.TELOS]: "0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E",
  [ChainId.MOONBEAM]: "0xAcc15dC74880C9944775448304B263D191c6077F",
  [ChainId.KAVA]: "0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b",
  [ChainId.METIS]: "0x75cb093E4D61d2A2e65D8e0BBb01DE8d89b53481",
  [ChainId.ARBITRUM_NOVA]: WETH9_ADDRESS[ChainId.ARBITRUM_NOVA],
  [ChainId.BOBA]: WETH9_ADDRESS[ChainId.BOBA],
  [ChainId.BOBA_AVAX]: "0x26c319B7B2cF823365414d082698C8ac90cbBA63",
  [ChainId.BOBA_BNB]: "0xC58aaD327D6D58D979882601ba8DDa0685B505eA",
  [ChainId.BTTC]: "0x23181F21DEa5936e24163FFABa4Ea3B316B57f3C",
  // [ChainId.SEPOLIA]: WETH9_ADDRESS[ChainId.SEPOLIA],
  // [ChainId.CONSENSUS_ZKEVM_TESTNET]: WETH9_ADDRESS[ChainId.CONSENSUS_ZKEVM_TESTNET],
  // [ChainId.SCROLL_ALPHA_TESTNET]: WETH9_ADDRESS[ChainId.SCROLL_ALPHA_TESTNET],
  // [ChainId.BASE_TESTNET]: WETH9_ADDRESS[ChainId.BASE_TESTNET],
  [ChainId.POLYGON_ZKEVM]: WETH9_ADDRESS[ChainId.POLYGON_ZKEVM],
  [ChainId.THUNDERCORE]: "0x413cEFeA29F2d07B8F2acFA69d92466B9535f717",
  // [ChainId.FILECOIN]: '0x60E1773636CF5E4A227d9AC24F20fEca034ee25A',
  [ChainId.HAQQ]: "0xeC8CC083787c6e5218D86f9FF5f28d4cC377Ac54",
  [ChainId.CORE]: "0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f",
  [ChainId.ZKSYNC_ERA]: WETH9_ADDRESS[ChainId.ZKSYNC_ERA],
  [ChainId.LINEA]: WETH9_ADDRESS[ChainId.LINEA],
  [ChainId.BASE]: WETH9_ADDRESS[ChainId.BASE]
};
var SUSHI_ADDRESS = {
  [ChainId.ETHEREUM]: "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
  [ChainId.ROPSTEN]: "0x0769fd68dFb93167989C6f7254cd0D766Fb2841F",
  [ChainId.RINKEBY]: "0x0769fd68dFb93167989C6f7254cd0D766Fb2841F",
  [ChainId.G\u00D6RLI]: "0x0769fd68dFb93167989C6f7254cd0D766Fb2841F",
  [ChainId.KOVAN]: "0x0769fd68dFb93167989C6f7254cd0D766Fb2841F",
  [ChainId.FANTOM]: "0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC",
  [ChainId.POLYGON]: "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a",
  [ChainId.GNOSIS]: "0x2995D1317DcD4f0aB89f4AE60F3f020A4F17C7CE",
  [ChainId.BSC]: "0x986cdF0fd180b40c4D6aEAA01Ab740B996D8b782",
  [ChainId.ARBITRUM]: "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A",
  [ChainId.AVALANCHE]: "0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76",
  [ChainId.HECO]: "0x52E00B2dA5Bd7940fFe26B609A42F957f31118D5",
  [ChainId.HARMONY]: "0xBEC775Cb42AbFa4288dE81F387a9b1A3c4Bc552A",
  [ChainId.OKEX]: "0x2218E0D5E0173769F5b4939a3aE423f7e5E4EAB7",
  [ChainId.MOONRIVER]: "0xf390830DF829cf22c53c8840554B98eafC5dCBc2",
  [ChainId.CELO]: "0x29dFce9c22003A4999930382Fd00f9Fd6133Acd1",
  [ChainId.TELOS]: "0x922D641a426DcFFaeF11680e5358F34d97d112E1",
  [ChainId.FUSE]: "0x90708b20ccC1eb95a4FA7C8b18Fd2C22a0Ff9E78",
  [ChainId.MOONBEAM]: "0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e",
  [ChainId.KAVA]: "0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D",
  [ChainId.METIS]: "0x17Ee7E4dA37B01FC1bcc908fA63DF343F23B4B7C",
  [ChainId.BOBA]: "0x5fFccc55C0d2fd6D3AC32C26C020B3267e933F1b",
  [ChainId.ARBITRUM_NOVA]: "0xfe60A48a0bCf4636aFEcC9642a145D2F241A7011",
  [ChainId.BTTC]: "0x53C56ece35f8CaB135e13D6d00499Dfc7c07A92e",
  [ChainId.OPTIMISM]: "0x3eaEb77b03dBc0F6321AE1b72b2E9aDb0F60112B",
  [ChainId.THUNDERCORE]: "0xABd380327Fe66724FFDa91A87c772FB8D00bE488"
};
var XSUSHI_ADDRESS = {
  [ChainId.ETHEREUM]: "0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272"
};
var USDC_ADDRESS = {
  [ChainId.ETHEREUM]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  [ChainId.RINKEBY]: "0x1717A0D5C8705EE89A8aD6E808268D6A826C97A4",
  [ChainId.ROPSTEN]: "0x0D9C8723B343A8368BebE0B5E89273fF8D712e3C",
  [ChainId.KOVAN]: "0xb7a4F3E9097C08dA09517b5aB877F7a917224ede",
  [ChainId.POLYGON]: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  [ChainId.POLYGON_TESTNET]: "0x742DfA5Aa70a8212857966D491D67B09Ce7D6ec7",
  [ChainId.FANTOM]: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
  [ChainId.BSC]: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  [ChainId.HARMONY]: "0x985458E523dB3d53125813eD68c274899e9DfAb4",
  [ChainId.HECO]: "0x9362Bbef4B8313A8Aa9f0c9808B80577Aa26B73B",
  [ChainId.OKEX]: "0xc946DAf81b08146B1C7A8Da2A851Ddf2B3EAaf85",
  [ChainId.GNOSIS]: "0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83",
  [ChainId.ARBITRUM]: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
  [ChainId.AVALANCHE]: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
  [ChainId.MOONRIVER]: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
  [ChainId.CELO]: "0xef4229c8c3250C675F21BCefa42f58EfbfF6002a",
  [ChainId.TELOS]: "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
  [ChainId.FUSE]: "0x620fd5fa44BE6af63715Ef4E65DDFA0387aD13F5",
  [ChainId.MOONBEAM]: "0x8f552a71EFE5eeFc207Bf75485b356A0b3f01eC9",
  [ChainId.OPTIMISM]: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
  [ChainId.KAVA]: "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
  [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
  [ChainId.ARBITRUM_NOVA]: "0x750ba8b76187092B0D1E87E28daaf484d1b5273b",
  [ChainId.BOBA]: "0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc",
  [ChainId.BOBA_AVAX]: "0x12bb1A120dcF8Cb7152eDAC9f04d176DD7f41F7e",
  [ChainId.BOBA_BNB]: "0x9F98f9F312D23d078061962837042b8918e6aff2",
  [ChainId.BTTC]: "0xAE17940943BA9440540940DB0F1877f101D39e8b",
  // USDC.e
  [ChainId.POLYGON_ZKEVM]: "0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035",
  [ChainId.THUNDERCORE]: "0x22e89898A04eaf43379BeB70bf4E38b1faf8A31e",
  [ChainId.CORE]: "0xa4151B2B3e269645181dCcF2D426cE75fcbDeca9",
  [ChainId.HAQQ]: "0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd",
  [ChainId.ZKSYNC_ERA]: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
  [ChainId.LINEA]: axlUSDC_ADDRESS[ChainId.LINEA],
  [ChainId.BASE]: axlUSDC_ADDRESS[ChainId.BASE]
};
var USDT_ADDRESS = {
  [ChainId.ETHEREUM]: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  [ChainId.ROPSTEN]: "0x110a13FC3efE6A245B50102D2d79B3E76125Ae83",
  [ChainId.KOVAN]: "0x07de306FF27a2B630B1141956844eB1552B956B5",
  [ChainId.POLYGON]: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  [ChainId.FANTOM]: "0x049d68029688eAbF473097a2fC38ef61633A3C7A",
  [ChainId.BSC]: "0x55d398326f99059fF775485246999027B3197955",
  [ChainId.BSC_TESTNET]: "0xF49E250aEB5abDf660d643583AdFd0be41464EfD",
  [ChainId.HARMONY]: "0x3C2B8Be99c50593081EAA2A724F0B8285F5aba8f",
  [ChainId.HECO]: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
  [ChainId.OKEX]: "0x382bB369d343125BfB2117af9c149795C6C65C50",
  [ChainId.GNOSIS]: "0x4ECaBa5870353805a9F068101A40E0f32ed605C6",
  [ChainId.ARBITRUM]: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
  [ChainId.AVALANCHE]: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
  [ChainId.CELO]: "0x88eeC49252c8cbc039DCdB394c0c2BA2f1637EA0",
  [ChainId.MOONRIVER]: "0xB44a9B6905aF7c801311e8F4E76932ee959c663C",
  [ChainId.TELOS]: "0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73",
  [ChainId.FUSE]: "0xFaDbBF8Ce7D5b7041bE672561bbA99f79c532e10",
  [ChainId.MOONBEAM]: "0x8e70cd5b4ff3f62659049e74b6649c6603a0e594",
  [ChainId.OPTIMISM]: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
  [ChainId.KAVA]: "0xB44a9B6905aF7c801311e8F4E76932ee959c663C",
  [ChainId.METIS]: "0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC",
  [ChainId.ARBITRUM_NOVA]: "0xeD9d63a96c27f87B07115b56b2e3572827f21646",
  [ChainId.BOBA]: "0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d",
  [ChainId.BOBA_AVAX]: "0xfaA13D82756f1e0e4dec9416b83121db3Fc35199",
  [ChainId.BOBA_BNB]: "0x1E633Dcd0d3D349126983D58988051F7c62c543D",
  [ChainId.BTTC]: "0xE887512ab8BC60BcC9224e1c3b5Be68E26048B8B",
  // USDT.e
  [ChainId.POLYGON_ZKEVM]: "0x1E4a5963aBFD975d8c9021ce480b42188849D41d",
  [ChainId.THUNDERCORE]: "0x4f3C8E20942461e2c3Bdd8311AC57B0c222f2b82",
  [ChainId.CORE]: "0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1",
  [ChainId.HAQQ]: "0xd567B3d7B8FE3C79a1AD8dA978812cfC4Fa05e75"
};
var DAI_ADDRESS = {
  [ChainId.ETHEREUM]: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  [ChainId.ROPSTEN]: "0xc2118d4d90b274016cB7a54c03EF52E6c537D957",
  [ChainId.KOVAN]: "0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa",
  [ChainId.POLYGON]: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
  [ChainId.FANTOM]: "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E",
  [ChainId.BSC]: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
  [ChainId.HARMONY]: "0xEf977d2f931C1978Db5F6747666fa1eACB0d0339",
  [ChainId.HECO]: "0x3D760a45D0887DFD89A2F5385a236B29Cb46ED2a",
  [ChainId.OKEX]: "0x21cDE7E32a6CAF4742d00d44B07279e7596d26B9",
  [ChainId.GNOSIS]: "0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d",
  [ChainId.ARBITRUM]: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
  [ChainId.AVALANCHE]: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
  [ChainId.CELO]: "0x90Ca507a5D4458a4C6C6249d186b6dCb02a5BCCd",
  [ChainId.MOONRIVER]: "0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844",
  // [ChainId.TELOS]: '',
  [ChainId.FUSE]: "0x94Ba7A27c7A95863d1bdC7645AC2951E0cca06bA",
  [ChainId.MOONBEAM]: "0xc234A67a4F840E61adE794be47de455361b52413",
  [ChainId.OPTIMISM]: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
  [ChainId.KAVA]: "0x765277EebeCA2e31912C9946eAe1021199B39C61",
  [ChainId.METIS]: "0x4c078361FC9BbB78DF910800A991C7c3DD2F6ce0",
  [ChainId.ARBITRUM_NOVA]: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
  [ChainId.BOBA]: "0xf74195Bb8a5cf652411867c5C2C5b8C2a402be35",
  [ChainId.POLYGON_ZKEVM]: "0xC5015b9d9161Dca7e18e32f6f25C4aD850731Fd4",
  [ChainId.HAQQ]: "0xC5e00D3b04563950941f7137B5AfA3a534F0D6d6",
  [ChainId.LINEA]: axlDAI_ADDRESS[ChainId.LINEA],
  [ChainId.BASE]: axlDAI_ADDRESS[ChainId.BASE]
};
var MIM_ADDRESS = {
  [ChainId.ETHEREUM]: "0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3",
  [ChainId.FANTOM]: "0x82f0B8B456c1A451378467398982d4834b6829c1",
  [ChainId.BSC]: "0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba",
  [ChainId.ARBITRUM]: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",
  [ChainId.AVALANCHE]: "0x130966628846BFd36ff31a822705796e8cb8C18D",
  [ChainId.POLYGON]: "0x49a0400587A7F65072c87c4910449fDcC5c47242",
  [ChainId.MOONRIVER]: "0x0caE51e1032e8461f4806e26332c030E34De3aDb"
};
var FRAX_ADDRESS = {
  [ChainId.ETHEREUM]: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
  [ChainId.FANTOM]: "0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355",
  [ChainId.BSC]: "0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40",
  [ChainId.ARBITRUM]: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
  [ChainId.AVALANCHE]: "0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64",
  [ChainId.POLYGON]: "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89",
  [ChainId.MOONRIVER]: "0x1A93B23281CC1CDE4C4741353F3064709A16197d",
  [ChainId.MOONBEAM]: "0x322E86852e492a7Ee17f28a78c663da38FB33bfb",
  [ChainId.HARMONY]: "0xFa7191D292d5633f702B0bd7E3E3BcCC0e633200",
  [ChainId.BOBA]: "0xAb2AF3A98D229b7dAeD7305Bb88aD0BA2c42f9cA",
  [ChainId.OPTIMISM]: "0x2E3D870790dC77A83DD1d18184Acc7439A53f475"
};
var FXS_ADDRESS = {
  [ChainId.ETHEREUM]: "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
  [ChainId.FANTOM]: "0x7d016eec9c25232b01F23EF992D98ca97fc2AF5a",
  [ChainId.BSC]: "0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE",
  [ChainId.ARBITRUM]: "0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7",
  [ChainId.AVALANCHE]: "0x214DB107654fF987AD859F34125307783fC8e387",
  [ChainId.POLYGON]: "0x3e121107F6F22DA4911079845a470757aF4e1A1b",
  [ChainId.MOONRIVER]: "0x6f1D1Ee50846Fcbc3de91723E61cb68CFa6D0E98",
  [ChainId.MOONBEAM]: "0x2CC0A9D8047A5011dEfe85328a6f26968C8aaA1C",
  [ChainId.HARMONY]: "0x0767D8E1b05eFA8d6A301a65b324B6b66A1CC14c",
  [ChainId.BOBA]: "0xdc1664458d2f0B6090bEa60A8793A4E66c2F1c00",
  [ChainId.OPTIMISM]: "0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be"
};
var BCT_ADDRESS = {
  [ChainId.POLYGON]: "0x2F800Db0fdb5223b3C3f354886d907A671414A7F"
};
var KLIMA_ADDRESS = {
  [ChainId.POLYGON]: "0x4e78011Ce80ee02d2c3e649Fb657E45898257815"
};
var QUICK_ADDRESS = {
  [ChainId.POLYGON]: "0x831753dd7087cac61ab5644b308642cc1c33dc13"
};
var OP_ADDRESS = {
  [ChainId.OPTIMISM]: "0x4200000000000000000000000000000000000042"
};
var LINK_ADDRESS = {
  [ChainId.ETHEREUM]: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
  [ChainId.POLYGON]: "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
  [ChainId.GNOSIS]: "0xE2e73A1c69ecF83F464EFCE6A5be353a37cA09b2",
  [ChainId.BSC]: "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
  [ChainId.HECO]: "0x9e004545c59D359F6B7BFB06a26390b087717b42",
  [ChainId.OPTIMISM]: "0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6",
  [ChainId.HARMONY]: "0x218532a12a389a4a92fC0C5Fb22901D1c19198aA",
  [ChainId.AVALANCHE]: "0x5947BB275c521040051D82396192181b413227A3",
  [ChainId.ARBITRUM]: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
  [ChainId.FANTOM]: "0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8"
};
var AGEUR_ADDRESS = {
  [ChainId.ETHEREUM]: "0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8",
  [ChainId.BSC]: "0x12f31B73D812C6Bb0d735a218c086d44D5fe5f89",
  [ChainId.ARBITRUM]: "0xFA5Ed56A203466CbBC2430a43c66b9D8723528E7",
  [ChainId.AVALANCHE]: "0xAEC8318a9a59bAEb39861d10ff6C7f7bf1F96C57",
  [ChainId.POLYGON]: "0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4",
  [ChainId.OPTIMISM]: "0x9485aca5bbBE1667AD97c7fE7C4531a624C8b1ED",
  [ChainId.GNOSIS]: "0x4b1E2c2762667331Bc91648052F646d1b0d35984",
  [ChainId.CELO]: "0xC16B81Af351BA9e64C1a069E3Ab18c244A1E3049"
};

// src/constants/tokens.ts
var AMPL = addressMapToTokenMap({ decimals: 9, symbol: "AMPL", name: "Ampleforth" }, AMPL_ADDRESS);
var MANA = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "MANA",
    name: "Decentraland"
  },
  MANA_ADDRESS
);
var MKR = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "MKR",
    name: "Maker"
  },
  MKR_ADDRESS
);
var YFI = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "YFI",
    name: "yearn.finance"
  },
  YFI_ADDRESS
);
var ENJ = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "ENJ",
    name: "Enjin Coin"
  },
  ENJ_ADDRESS
);
var CRV = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "CRV",
    name: "Curve DAO Token"
  },
  CRV_ADDRESS
);
var SNX = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "SNX",
    name: "Synthetix Network Token"
  },
  SNX_ADDRESS
);
var GALA = addressMapToTokenMap(
  {
    decimals: 8,
    symbol: "GALA",
    name: "Gala"
  },
  GALA_ADDRESS
);
var MATIC = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "MATIC",
    name: "Matic Token"
  },
  MATIC_ADDRESS
);
var GNO = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "GNO",
    name: "Gnosis Token"
  },
  GNO_ADDRESS
);
var ARB = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "ARB",
    name: "Arbitrum"
  },
  ARB_ADDRESS
);
var KP3R = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "KP3R",
    name: "Keep3rV1"
  },
  KP3R_ADDRESS
);
var LDO = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "LDO",
    name: "Lido DAO Token"
  },
  LDO_ADDRESS
);
var APE = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "APE",
    name: "ApeCoin"
  },
  APE_ADDRESS
);
var PRIMATE = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "PRIMATE",
    name: "PRIMATE"
  },
  PRIMATE_ADDRESS
);
var rETH2 = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "rETH2",
    name: "StakeWise Reward ETH2"
  },
  rETH2_ADDRESS
);
var sETH2 = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "sETH2",
    name: "StakeWise Staked ETH2"
  },
  sETH2_ADDRESS
);
var SWISE = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "SWISE",
    name: "StakeWise"
  },
  SWISE_ADDRESS
);
var FEI = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "FEI",
    name: "Fei USD"
  },
  FEI_ADDRESS
);
var TRIBE = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "TRIBE",
    name: "Tribe"
  },
  TRIBE_ADDRESS
);
var renBTC = addressMapToTokenMap(
  {
    decimals: 8,
    symbol: "renBTC",
    name: "renBTC"
  },
  renBTC_ADDRESS
);
var NFTX = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "NFTX",
    name: "NFTX"
  },
  NFTX_ADDRESS
);
var OHM = addressMapToTokenMap(
  {
    decimals: 9,
    symbol: "OHM",
    name: "Olympus"
  },
  OHM_ADDRESS
);
var WBTC = addressMapToTokenMap(
  {
    decimals: 8,
    symbol: "WBTC",
    name: "Wrapped BTC"
  },
  WBTC_ADDRESS
);
var UNI = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "UNI",
    name: "Uniswap"
  },
  UNI_ADDRESS
);
var BUSD = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "BUSD",
    name: "BUSD Token"
  },
  BUSD_ADDRESS
);
var MAI = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "MAI",
    name: "Mai Stablecoin"
  },
  MAI_ADDRESS
);
var TUSD = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "TUSD",
    name: "TrueUSD"
  },
  TUSD_ADDRESS
);
var AGEUR = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "agEUR",
    name: "agEUR"
  },
  AGEUR_ADDRESS
);
var ANKR = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "ANKR",
    name: "Anker Network"
  },
  ANKR_ADDRESS
);
var AAVE = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "AAVE",
    name: "Aave Token"
  },
  AAVE_ADDRESS
);
var COMP = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "COMP",
    name: "Compound "
  },
  COMP_ADDRESS
);
var JPY = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "JPYC",
    name: "JPY Coin"
  },
  JPY_ADDRESS
);
var LUSD = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "LUSD",
    name: "LUSD Stablecoin"
  },
  LUSD_ADDRESS
);
var WETH9 = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "WETH",
    name: "Wrapped Ether"
  },
  WETH9_ADDRESS
);
var WNATIVE = {
  [ChainId2.ETHEREUM]: WETH9[ChainId2.ETHEREUM],
  [ChainId2.ROPSTEN]: WETH9[ChainId2.ROPSTEN],
  [ChainId2.RINKEBY]: WETH9[ChainId2.RINKEBY],
  [ChainId2.G\u00D6RLI]: WETH9[ChainId2.G\u00D6RLI],
  [ChainId2.KOVAN]: WETH9[ChainId2.KOVAN],
  [ChainId2.OPTIMISM]: WETH9[ChainId2.OPTIMISM],
  [ChainId2.FANTOM]: new Token({
    chainId: ChainId2.FANTOM,
    address: WNATIVE_ADDRESS[ChainId2.FANTOM],
    decimals: 18,
    symbol: "WFTM",
    name: "Wrapped FTM"
  }),
  [ChainId2.FANTOM_TESTNET]: new Token({
    chainId: ChainId2.FANTOM_TESTNET,
    address: WNATIVE_ADDRESS[ChainId2.FANTOM_TESTNET],
    decimals: 18,
    symbol: "WFTM",
    name: "Wrapped FTM"
  }),
  [ChainId2.POLYGON]: new Token({
    chainId: ChainId2.POLYGON,
    address: WNATIVE_ADDRESS[ChainId2.POLYGON],
    decimals: 18,
    symbol: "WMATIC",
    name: "Wrapped Matic"
  }),
  [ChainId2.POLYGON_TESTNET]: new Token({
    chainId: ChainId2.POLYGON_TESTNET,
    address: WNATIVE_ADDRESS[ChainId2.POLYGON_TESTNET],
    decimals: 18,
    symbol: "WMATIC",
    name: "Wrapped Matic"
  }),
  [ChainId2.GNOSIS]: new Token({
    chainId: ChainId2.GNOSIS,
    address: WNATIVE_ADDRESS[ChainId2.GNOSIS],
    decimals: 18,
    symbol: "WXDAI",
    name: "Wrapped xDai"
  }),
  [ChainId2.BSC]: new Token({
    chainId: ChainId2.BSC,
    address: WNATIVE_ADDRESS[ChainId2.BSC],
    decimals: 18,
    symbol: "WBNB",
    name: "Wrapped BNB"
  }),
  [ChainId2.BSC_TESTNET]: new Token({
    chainId: ChainId2.BSC_TESTNET,
    address: WNATIVE_ADDRESS[ChainId2.BSC_TESTNET],
    decimals: 18,
    symbol: "WBNB",
    name: "Wrapped BNB"
  }),
  [ChainId2.ARBITRUM]: WETH9[ChainId2.ARBITRUM],
  [ChainId2.ARBITRUM_TESTNET]: WETH9[ChainId2.ARBITRUM_TESTNET],
  [ChainId2.ARBITRUM_NOVA]: WETH9[ChainId2.ARBITRUM_NOVA],
  [ChainId2.AVALANCHE]: new Token({
    chainId: ChainId2.AVALANCHE,
    address: WNATIVE_ADDRESS[ChainId2.AVALANCHE],
    decimals: 18,
    symbol: "WAVAX",
    name: "Wrapped AVAX"
  }),
  [ChainId2.AVALANCHE_TESTNET]: new Token({
    chainId: ChainId2.AVALANCHE_TESTNET,
    address: WNATIVE_ADDRESS[ChainId2.AVALANCHE_TESTNET],
    decimals: 18,
    symbol: "WAVAX",
    name: "Wrapped AVAX"
  }),
  [ChainId2.HECO]: new Token({
    chainId: ChainId2.HECO,
    address: WNATIVE_ADDRESS[ChainId2.HECO],
    decimals: 18,
    symbol: "WHT",
    name: "Wrapped HT"
  }),
  [ChainId2.HECO_TESTNET]: new Token({
    chainId: ChainId2.HECO_TESTNET,
    address: WNATIVE_ADDRESS[ChainId2.HECO_TESTNET],
    decimals: 18,
    symbol: "WHT",
    name: "Wrapped HT"
  }),
  [ChainId2.HARMONY]: new Token({
    chainId: ChainId2.HARMONY,
    address: WNATIVE_ADDRESS[ChainId2.HARMONY],
    decimals: 18,
    symbol: "WONE",
    name: "Wrapped ONE"
  }),
  [ChainId2.HARMONY_TESTNET]: new Token({
    chainId: ChainId2.HARMONY_TESTNET,
    address: WNATIVE_ADDRESS[ChainId2.HARMONY_TESTNET],
    decimals: 18,
    symbol: "WONE",
    name: "Wrapped ONE"
  }),
  [ChainId2.OKEX]: new Token({
    chainId: ChainId2.OKEX,
    address: WNATIVE_ADDRESS[ChainId2.OKEX],
    decimals: 18,
    symbol: "WOKT",
    name: "Wrapped OKExChain"
  }),
  [ChainId2.OKEX_TESTNET]: new Token({
    chainId: ChainId2.OKEX_TESTNET,
    address: WNATIVE_ADDRESS[ChainId2.OKEX_TESTNET],
    decimals: 18,
    symbol: "WOKT",
    name: "Wrapped OKExChain"
  }),
  [ChainId2.CELO]: new Token({
    chainId: ChainId2.CELO,
    address: WNATIVE_ADDRESS[ChainId2.CELO],
    decimals: 18,
    symbol: "CELO",
    name: "Celo"
  }),
  [ChainId2.PALM]: new Token({
    chainId: ChainId2.PALM,
    address: WNATIVE_ADDRESS[ChainId2.PALM],
    decimals: 18,
    symbol: "WPALM",
    name: "Wrapped Palm"
  }),
  [ChainId2.MOONRIVER]: new Token({
    chainId: ChainId2.MOONRIVER,
    address: WNATIVE_ADDRESS[ChainId2.MOONRIVER],
    decimals: 18,
    symbol: "WMOVR",
    name: "Wrapped Moonriver"
  }),
  [ChainId2.FUSE]: new Token({
    chainId: ChainId2.FUSE,
    address: WNATIVE_ADDRESS[ChainId2.FUSE],
    decimals: 18,
    symbol: "WFUSE",
    name: "Wrapped Fuse"
  }),
  [ChainId2.TELOS]: new Token({
    chainId: ChainId2.TELOS,
    address: WNATIVE_ADDRESS[ChainId2.TELOS],
    decimals: 18,
    symbol: "WTLOS",
    name: "Wrapped Telos"
  }),
  [ChainId2.MOONBEAM]: new Token({
    chainId: ChainId2.MOONBEAM,
    address: WNATIVE_ADDRESS[ChainId2.MOONBEAM],
    decimals: 18,
    symbol: "WGLMR",
    name: "Wrapped Glimmer"
  }),
  [ChainId2.KAVA]: new Token({
    chainId: ChainId2.KAVA,
    address: WNATIVE_ADDRESS[ChainId2.KAVA],
    decimals: 18,
    symbol: "WKAVA",
    name: "Wrapped Kava"
  }),
  [ChainId2.METIS]: new Token({
    chainId: ChainId2.METIS,
    address: WNATIVE_ADDRESS[ChainId2.METIS],
    decimals: 18,
    symbol: "WMETIS",
    name: "Wrapped Metis"
  }),
  [ChainId2.BOBA]: WETH9[ChainId2.BOBA],
  [ChainId2.BOBA_AVAX]: new Token({
    chainId: ChainId2.BOBA_AVAX,
    address: WNATIVE_ADDRESS[ChainId2.BOBA_AVAX],
    decimals: 18,
    symbol: "WBOBA",
    name: "Wrapped Boba"
  }),
  [ChainId2.BOBA_BNB]: new Token({
    chainId: ChainId2.BOBA_BNB,
    address: WNATIVE_ADDRESS[ChainId2.BOBA_BNB],
    decimals: 18,
    symbol: "WBOBA",
    name: "Wrapped Boba"
  }),
  [ChainId2.BTTC]: new Token({
    chainId: ChainId2.BTTC,
    address: WNATIVE_ADDRESS[ChainId2.BTTC],
    decimals: 18,
    symbol: "WBTT",
    name: "Wrapped BitTorrent Token"
  }),
  // [ChainId.SEPOLIA]: WETH9[ChainId.SEPOLIA],
  // [ChainId.CONSENSUS_ZKEVM_TESTNET]: WETH9[ChainId.CONSENSUS_ZKEVM_TESTNET],
  // [ChainId.SCROLL_ALPHA_TESTNET]: WETH9[ChainId.SCROLL_ALPHA_TESTNET],
  // [ChainId.BASE_TESTNET]: WETH9[ChainId.BASE_TESTNET],
  [ChainId2.THUNDERCORE]: new Token({
    chainId: ChainId2.THUNDERCORE,
    address: WNATIVE_ADDRESS[ChainId2.THUNDERCORE],
    decimals: 18,
    symbol: "WTT",
    name: "Wrapped Thunder Token"
  }),
  [ChainId2.POLYGON_ZKEVM]: WETH9[ChainId2.POLYGON_ZKEVM],
  [ChainId2.HAQQ]: new Token({
    chainId: ChainId2.HAQQ,
    address: WNATIVE_ADDRESS[ChainId2.HAQQ],
    decimals: 18,
    symbol: "WISLM",
    name: "Wrapped Islamic Coin"
  }),
  [ChainId2.CORE]: new Token({
    chainId: ChainId2.CORE,
    address: WNATIVE_ADDRESS[ChainId2.CORE],
    decimals: 18,
    symbol: "WCORE",
    name: "Wrapped Core"
  }),
  [ChainId2.ZKSYNC_ERA]: WETH9[ChainId2.ZKSYNC_ERA],
  [ChainId2.LINEA]: WETH9[ChainId2.LINEA],
  [ChainId2.BASE]: WETH9[ChainId2.BASE]
};
var SUSHI = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "SUSHI",
    name: "SushiToken"
  },
  SUSHI_ADDRESS
);
var XSUSHI = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "XSUSHI",
    name: "SushiBar"
  },
  XSUSHI_ADDRESS
);
var axlUSDC = addressMapToTokenMap(
  {
    decimals: 6,
    symbol: "axlUSDC",
    name: "Axelar Wrapped USDC"
  },
  axlUSDC_ADDRESS
);
var USDC = {
  ...addressMapToTokenMap(
    {
      decimals: 6,
      symbol: "USDC",
      name: "USD Coin"
    },
    USDC_ADDRESS
  ),
  [ChainId2.BSC]: new Token({
    chainId: ChainId2.BSC,
    address: USDC_ADDRESS[ChainId2.BSC],
    decimals: 18,
    symbol: "USDC",
    name: "USD Coin"
  }),
  [ChainId2.BOBA_BNB]: new Token({
    chainId: ChainId2.BOBA_BNB,
    address: USDC_ADDRESS[ChainId2.BOBA_BNB],
    decimals: 18,
    symbol: "USDC",
    name: "USD Coin"
  })
};
var USDT = {
  ...addressMapToTokenMap(
    {
      decimals: 6,
      symbol: "USDT",
      name: "Tether USD"
    },
    USDT_ADDRESS
  ),
  [ChainId2.BSC]: new Token({
    chainId: ChainId2.BSC,
    address: USDT_ADDRESS[ChainId2.BSC],
    decimals: 18,
    symbol: "USDT",
    name: "Tether USD"
  }),
  [ChainId2.BSC_TESTNET]: new Token({
    chainId: ChainId2.BSC_TESTNET,
    address: USDT_ADDRESS[ChainId2.BSC_TESTNET],
    decimals: 18,
    symbol: "USDT",
    name: "Tether USD"
  }),
  [ChainId2.BOBA_BNB]: new Token({
    chainId: ChainId2.BOBA_BNB,
    address: USDT_ADDRESS[ChainId2.BOBA_BNB],
    decimals: 18,
    symbol: "USDT",
    name: "Tether USD"
  })
};
var DAI = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "DAI",
    name: "Dai Stablecoin"
  },
  DAI_ADDRESS
);
var MIM = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "MIM",
    name: "Magic Internet Money"
  },
  MIM_ADDRESS
);
var FRAX = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "FRAX",
    name: "Frax"
  },
  FRAX_ADDRESS
);
var FXS = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "FXS",
    name: "Frax Share"
  },
  FXS_ADDRESS
);
var BCT = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "BCT",
    name: "Toucan Protocol: Base Carbon Tonne"
  },
  BCT_ADDRESS
);
var KLIMA = addressMapToTokenMap(
  {
    decimals: 9,
    symbol: "KLIMA",
    name: "Klima DAO"
  },
  KLIMA_ADDRESS
);
var QUICK = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "QUICK",
    name: "Quickswap"
  },
  QUICK_ADDRESS
);
var OP = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "OP",
    name: "Optimism"
  },
  OP_ADDRESS
);
var LINK = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "LINK",
    name: "ChainLink Token"
  },
  LINK_ADDRESS
);

// src/Native.ts
var Native = class _Native extends Currency {
  id;
  isNative = true;
  isToken = false;
  symbol;
  name;
  constructor(native) {
    super(native);
    this.id = `${native.chainId}:NATIVE`;
    this.symbol = native.symbol;
    this.name = native.name;
  }
  get wrapped() {
    const wnative = WNATIVE[this.chainId];
    invariant3(!!wnative, "WRAPPED");
    return wnative;
  }
  // public get tokenURI(): string {
  //   return `native-currency/${this.symbol.toLowerCase()}.svg`
  // }
  static cache = {};
  static onChain(chainId) {
    if (chainId in this.cache) {
      return this.cache[chainId];
    }
    invariant3(!!(chainId in chains), "CHAINS");
    const { nativeCurrency } = chains[chainId];
    invariant3(!!nativeCurrency, "NATIVE_CURRENCY");
    const { decimals, name, symbol } = nativeCurrency;
    this.cache[chainId] = new _Native({
      chainId,
      decimals,
      name,
      symbol
    });
    return this.cache[chainId];
  }
  equals(other) {
    return other.isNative && other.chainId === this.chainId;
  }
  serialize() {
    return nativeSchema.parse({
      isNative: this.isNative,
      name: this.name,
      symbol: this.symbol,
      decimals: this.decimals,
      chainId: this.chainId
    });
  }
  static deserialize(native) {
    return _Native.onChain(native.chainId);
  }
};

// src/Share.ts
import { Big, Fraction, MAX_UINT128, Rounding, ZERO } from "@sushiswap/math";
import invariant4 from "tiny-invariant";
var Share = class _Share extends Fraction {
  currency;
  scale;
  static fromRawShare(currency, rawShare = 0) {
    return new _Share(currency, rawShare);
  }
  constructor(currency, numerator, denominator) {
    super(numerator, denominator);
    invariant4(this.quotient <= MAX_UINT128, "SHARE");
    this.currency = currency;
    this.scale = 10n ** BigInt(currency.decimals);
  }
  toAmount(rebase, roundUp = false) {
    if (rebase.base === ZERO)
      return Amount.fromRawAmount(this.currency, this.quotient);
    const elastic = this.quotient * rebase.elastic / rebase.base;
    if (roundUp && elastic * rebase.base / rebase.elastic < this.quotient) {
      return Amount.fromRawAmount(this.currency, elastic + 1n);
    }
    return Amount.fromRawAmount(this.currency, elastic);
  }
  /**
   * Construct a currency share with a denominator that is not equal to 1
   * @param currency the currency
   * @param numerator the numerator of the fractional token share
   * @param denominator the denominator of the fractional token share
   */
  static fromFractionalShare(currency, numerator, denominator) {
    return new _Share(currency, numerator, denominator);
  }
  add(other) {
    invariant4(this.currency.equals(other.currency), "CURRENCY");
    const added = super.add(other);
    return _Share.fromFractionalShare(this.currency, added.numerator, added.denominator);
  }
  subtract(other) {
    invariant4(this.currency.equals(other.currency), "CURRENCY");
    const subtracted = super.subtract(other);
    return _Share.fromFractionalShare(this.currency, subtracted.numerator, subtracted.denominator);
  }
  multiply(other) {
    const multiplied = super.multiply(other);
    return _Share.fromFractionalShare(this.currency, multiplied.numerator, multiplied.denominator);
  }
  divide(other) {
    const divided = super.divide(other);
    return _Share.fromFractionalShare(this.currency, divided.numerator, divided.denominator);
  }
  toSignificant(significantDigits = 6, format, rounding = Rounding.ROUND_DOWN) {
    return super.divide(this.scale).toSignificant(significantDigits, format, rounding);
  }
  toFixed(decimalPlaces = this.currency.decimals, format, rounding = Rounding.ROUND_DOWN) {
    invariant4(decimalPlaces <= this.currency.decimals, "DECIMALS");
    return super.divide(this.scale).toFixed(decimalPlaces, format, rounding);
  }
  toExact(format = { groupSeparator: "" }) {
    Big.DP = this.currency.decimals;
    return new Big(this.quotient.toString()).div(this.scale.toString()).toFormat(format);
  }
};

// src/Amount.ts
var Amount = class _Amount extends Fraction2 {
  currency;
  scale;
  /**
   * Returns a new currency amount instance from the unitless amount of token, i.e. the raw amount
   * @param currency the currency in the amount
   * @param rawAmount the raw token or ether amount
   */
  static fromRawAmount(currency, rawAmount) {
    return new _Amount(currency, rawAmount);
  }
  static fromShare(currency, shares, rebase, roundUp = false) {
    if (rebase.base === ZERO2)
      return new _Amount(currency, shares);
    const sharesBI = typeof shares === "bigint" ? shares : BigInt(shares.toString());
    const elastic = sharesBI * rebase.elastic / rebase.base;
    if (roundUp && elastic * rebase.base / rebase.elastic < sharesBI) {
      return new _Amount(currency, elastic + 1n);
    }
    return new _Amount(currency, elastic);
  }
  toShare(rebase, roundUp = false) {
    if (rebase.elastic === ZERO2)
      return Share.fromRawShare(this.currency, this.quotient);
    const base = this.quotient * rebase.base / rebase.elastic;
    if (roundUp && base * rebase.elastic / rebase.base < this.quotient) {
      return Share.fromRawShare(this.currency, base + 1n);
    }
    return Share.fromRawShare(this.currency, base);
  }
  /**
   * Construct a currency amount with a denominator that is not equal to 1
   * @param currency the currency
   * @param numerator the numerator of the fractional token amount
   * @param denominator the denominator of the fractional token amount
   */
  static fromFractionalAmount(currency, numerator, denominator) {
    return new _Amount(currency, numerator, denominator);
  }
  constructor(currency, numerator, denominator) {
    super(numerator, denominator);
    invariant5(this.quotient <= MAX_UINT256, "AMOUNT");
    this.currency = currency;
    this.scale = 10n ** BigInt(currency.decimals);
  }
  add(other) {
    invariant5(this.currency.equals(other.currency), "CURRENCY");
    const added = super.add(other);
    return _Amount.fromFractionalAmount(this.currency, added.numerator, added.denominator);
  }
  subtract(other) {
    invariant5(this.currency.equals(other.currency), "CURRENCY");
    const subtracted = super.subtract(other);
    return _Amount.fromFractionalAmount(this.currency, subtracted.numerator, subtracted.denominator);
  }
  multiply(other) {
    const multiplied = super.multiply(other);
    return _Amount.fromFractionalAmount(this.currency, multiplied.numerator, multiplied.denominator);
  }
  divide(other) {
    const divided = super.divide(other);
    return _Amount.fromFractionalAmount(this.currency, divided.numerator, divided.denominator);
  }
  toSignificant(significantDigits = 6, format, rounding = Rounding2.ROUND_DOWN) {
    return super.divide(this.scale).toSignificant(significantDigits, format, rounding);
  }
  toFixed(decimalPlaces = this.currency.decimals, format, rounding = Rounding2.ROUND_DOWN) {
    invariant5(decimalPlaces <= this.currency.decimals, "DECIMALS");
    return super.divide(this.scale).toFixed(decimalPlaces, format, rounding);
  }
  toHex() {
    return `0x${this.quotient.toString(16)}`;
  }
  toExact(format = { groupSeparator: "" }) {
    Big2.DP = this.currency.decimals;
    return new Big2(this.quotient.toString()).div(this.scale.toString()).toFormat(format);
  }
  get wrapped() {
    if (this.currency.isToken)
      return this;
    return _Amount.fromFractionalAmount(this.currency.wrapped, this.numerator, this.denominator);
  }
  serialize() {
    return amountSchema.parse({
      amount: this.quotient.toString(),
      currency: this.currency.serialize()
    });
  }
  static deserialize(amount) {
    if (amount.currency.isNative)
      return _Amount.fromRawAmount(Native.deserialize(amount.currency), amount.amount);
    return _Amount.fromRawAmount(Token.deserialize(amount.currency), amount.amount);
  }
};

// src/constants/token-map.ts
import { ChainId as ChainId3 } from "@sushiswap/chain";

// src/AddressMapper.ts
var AddressMapper = class {
  static generate(addressLists) {
    const result = {};
    for (const addressesByChain of addressLists) {
      Object.entries(addressesByChain).forEach(([chainId, address]) => {
        const sisterAddresses = Array.isArray(address) ? address : [address];
        sisterAddresses.forEach((address2) => {
          const currentId = `${chainId}:${address2.toLowerCase()}`;
          Object.entries(addressesByChain).forEach(([chainId2, addresses]) => {
            const currentSisterAddresses = Array.isArray(addresses) ? addresses : [addresses];
            currentSisterAddresses.forEach((address3) => {
              const id = `${chainId2}:${address3.toLowerCase()}`;
              if (currentId !== id) {
                if (!result[currentId]) {
                  result[currentId] = [];
                }
                if (!result[currentId].some((item) => item.chainId === Number(chainId2) && item.tokenAddress === address3.toLowerCase())) {
                  result[currentId].push({ chainId: Number(chainId2), tokenAddress: address3.toLowerCase() });
                }
              }
            });
          });
        });
      });
    }
    return result;
  }
  static merge(...addressLists) {
    const merged = {};
    for (const addressList of addressLists) {
      Object.entries(addressList).forEach(([chainId, address]) => {
        if (!merged[Number(chainId)]) {
          merged[Number(chainId)] = [address.toLowerCase()];
        } else {
          merged[Number(chainId)].push(address.toLowerCase());
        }
      });
    }
    return merged;
  }
};

// src/constants/token-map.ts
var THUNDERCORE_USDT_ADDRESS = { [ChainId3.THUNDERCORE]: "0x0dcb0cb0120d355cde1ce56040be57add0185baa" };
var THUNDERCORE_USDC_ADDRESS = { [ChainId3.THUNDERCORE]: "0xdc42728b0ea910349ed3c6e1c9dc06b5fb591f98" };
var THUNDERCORE_WETH_ADDRESS = { [ChainId3.THUNDERCORE]: "0xe6801928061cdbe32ac5ad0634427e140efd05f9" };
var THUNDERCORE_BUSD_ADDRESS = { [ChainId3.THUNDERCORE]: "0xb12c13e66ade1f72f71834f2fc5082db8c091358" };
var BRIDGE_BSC_USDC_ADDRESS = {
  [ChainId3.BTTC]: "0xca424b845497f7204d9301bd13ff87c0e2e86fcf"
};
var BRIDGE_ETHEREUM_USDC_ADDRESS = {
  [ChainId3.BTTC]: "0xae17940943ba9440540940db0f1877f101d39e8b"
};
var BRIDGE_TRON_USDC_ADDRESS = {
  [ChainId3.BTTC]: "0x935faa2fcec6ab81265b301a30467bbc804b43d3"
};
var BRIDGE_BSC_USDT_ADDRESS = {
  [ChainId3.BTTC]: "0x9b5f27f6ea9bbd753ce3793a07cba3c74644330d"
};
var BRIDGE_ETHEREUM_USDT_ADDRESS = {
  [ChainId3.BTTC]: "0xe887512ab8bc60bcc9224e1c3b5be68e26048b8b"
};
var BRIDGE_TRON_USDT_ADDRESS = {
  [ChainId3.BTTC]: "0xdb28719f7f938507dbfe4f0eae55668903d34a15"
};
var MERGED_USDC_ADDRESS = AddressMapper.merge(
  USDC_ADDRESS,
  THUNDERCORE_USDC_ADDRESS,
  BRIDGE_BSC_USDC_ADDRESS,
  BRIDGE_ETHEREUM_USDC_ADDRESS,
  BRIDGE_TRON_USDC_ADDRESS
);
var MERGED_USDT_ADDRESS = AddressMapper.merge(
  USDT_ADDRESS,
  THUNDERCORE_USDT_ADDRESS,
  BRIDGE_BSC_USDT_ADDRESS,
  BRIDGE_ETHEREUM_USDT_ADDRESS,
  BRIDGE_TRON_USDT_ADDRESS
);
var MERGED_WETH_ADDRESS = AddressMapper.merge(
  WETH9_ADDRESS,
  THUNDERCORE_WETH_ADDRESS
);
var MERGED_BUSD_ADDRESS = AddressMapper.merge(
  BUSD_ADDRESS,
  THUNDERCORE_BUSD_ADDRESS
);
var TOKEN_MAP = AddressMapper.generate([
  AAVE_ADDRESS,
  AGEUR_ADDRESS,
  ANKR_ADDRESS,
  APE_ADDRESS,
  ARB_ADDRESS,
  BCT_ADDRESS,
  COMP_ADDRESS,
  CRV_ADDRESS,
  DAI_ADDRESS,
  ENJ_ADDRESS,
  FEI_ADDRESS,
  FRAX_ADDRESS,
  FTM_ADDRESS,
  FXS_ADDRESS,
  GALA_ADDRESS,
  GNO_ADDRESS,
  JPY_ADDRESS,
  KLIMA_ADDRESS,
  KP3R_ADDRESS,
  LDO_ADDRESS,
  LINK_ADDRESS,
  LUSD_ADDRESS,
  MAI_ADDRESS,
  MANA_ADDRESS,
  MATIC_ADDRESS,
  MERGED_BUSD_ADDRESS,
  MERGED_USDC_ADDRESS,
  MERGED_USDT_ADDRESS,
  MERGED_WETH_ADDRESS,
  MIM_ADDRESS,
  MKR_ADDRESS,
  NFTX_ADDRESS,
  OHM_ADDRESS,
  OP_ADDRESS,
  PRIMATE_ADDRESS,
  QUICK_ADDRESS,
  renBTC_ADDRESS,
  rETH2_ADDRESS,
  sETH2_ADDRESS,
  SNX_ADDRESS,
  SUSHI_ADDRESS,
  SWISE_ADDRESS,
  TRIBE_ADDRESS,
  TUSD_ADDRESS,
  UNI_ADDRESS,
  WBTC_ADDRESS,
  XSUSHI_ADDRESS,
  YFI_ADDRESS
]);

// src/defaultQuoteCurrency.ts
import { ChainId as ChainId4 } from "@sushiswap/chain";
var defaultQuoteCurrency = {
  [ChainId4.ETHEREUM]: SUSHI[ChainId4.ETHEREUM],
  [ChainId4.ROPSTEN]: SUSHI[ChainId4.ROPSTEN],
  [ChainId4.RINKEBY]: SUSHI[ChainId4.RINKEBY],
  [ChainId4.G\u00D6RLI]: SUSHI[ChainId4.G\u00D6RLI],
  [ChainId4.KOVAN]: SUSHI[ChainId4.KOVAN],
  [ChainId4.POLYGON]: SUSHI[ChainId4.POLYGON],
  // [ChainId.POLYGON_TESTNET]: SUSHI[ChainId.POLYGON_TESTNET],
  [ChainId4.FANTOM]: SUSHI[ChainId4.FANTOM],
  // [ChainId.FANTOM_TESTNET]: SUSHI[ChainId.FANTOM_TESTNET],
  [ChainId4.GNOSIS]: GNO[ChainId4.GNOSIS],
  [ChainId4.BSC]: BUSD[ChainId4.BSC],
  // [ChainId.BSC_TESTNET]: SUSHI[ChainId.BSC_TESTNET],
  [ChainId4.ARBITRUM]: ARB[ChainId4.ARBITRUM],
  [ChainId4.ARBITRUM_NOVA]: ARB[ChainId4.ARBITRUM_NOVA],
  // [ChainId.ARBITRUM_TESTNET]: 'SUSHI',
  [ChainId4.AVALANCHE]: SUSHI[ChainId4.AVALANCHE],
  // [ChainId.AVALANCHE_TESTNET]: 'SUSHI',
  [ChainId4.HECO]: SUSHI[ChainId4.HECO],
  // [ChainId.HECO_TESTNET]: 'SUSHI',
  [ChainId4.HARMONY]: SUSHI[ChainId4.HARMONY],
  // [ChainId.HARMONY_TESTNET]: 'SUSHI',
  [ChainId4.OKEX]: SUSHI[ChainId4.OKEX],
  // [ChainId.OKEX_TESTNET]: 'SUSHI',
  [ChainId4.CELO]: SUSHI[ChainId4.CELO],
  // [ChainId.PALM]: SUSHI[ChainId.PALM],
  [ChainId4.MOONRIVER]: SUSHI[ChainId4.MOONRIVER],
  [ChainId4.FUSE]: SUSHI[ChainId4.FUSE],
  [ChainId4.TELOS]: SUSHI[ChainId4.TELOS],
  [ChainId4.MOONBEAM]: SUSHI[ChainId4.MOONBEAM],
  [ChainId4.OPTIMISM]: OP[ChainId4.OPTIMISM],
  [ChainId4.KAVA]: SUSHI[ChainId4.KAVA],
  [ChainId4.METIS]: SUSHI[ChainId4.METIS],
  [ChainId4.BOBA]: USDC[ChainId4.BOBA],
  [ChainId4.BOBA_AVAX]: new Token({
    chainId: ChainId4.BOBA_AVAX,
    address: "0x4200000000000000000000000000000000000023",
    decimals: 18,
    symbol: "AVAX",
    name: "Avalanche"
  }),
  [ChainId4.BOBA_BNB]: new Token({
    chainId: ChainId4.BOBA_BNB,
    address: "0x4200000000000000000000000000000000000023",
    decimals: 18,
    symbol: "BNB",
    name: "Binance Coin"
  }),
  [ChainId4.BTTC]: SUSHI[ChainId4.BTTC],
  [ChainId4.THUNDERCORE]: USDC[ChainId4.THUNDERCORE],
  // [ChainId.SEPOLIA]: USDT[ChainId.SEPOLIA],
  // [ChainId.CONSENSUS_ZKEVM_TESTNET]: WETH9[ChainId.CONSENSUS_ZKEVM_TESTNET],
  // [ChainId.SCROLL_ALPHA_TESTNET]: WETH9[ChainId.SCROLL_ALPHA_TESTNET],
  // [ChainId.BASE_TESTNET]: WETH9[ChainId.BASE_TESTNET],
  [ChainId4.POLYGON_ZKEVM]: USDC[ChainId4.POLYGON_ZKEVM],
  [ChainId4.HAQQ]: USDC[ChainId4.HAQQ],
  [ChainId4.CORE]: USDC[ChainId4.CORE],
  [ChainId4.ZKSYNC_ERA]: USDC[ChainId4.ZKSYNC_ERA],
  [ChainId4.LINEA]: axlUSDC[ChainId4.LINEA],
  [ChainId4.BASE]: axlUSDC[ChainId4.BASE]
};

// src/nativeCurrencyIds.ts
import { ChainId as ChainId5 } from "@sushiswap/chain";
var nativeCurrencyIds = {
  [ChainId5.ETHEREUM]: "ETH",
  [ChainId5.ROPSTEN]: "ETH",
  [ChainId5.RINKEBY]: "ETH",
  [ChainId5.G\u00D6RLI]: "ETH",
  [ChainId5.KOVAN]: "ETH",
  [ChainId5.POLYGON]: "MATIC",
  [ChainId5.POLYGON_TESTNET]: "MATIC",
  [ChainId5.FANTOM]: "FTM",
  [ChainId5.FANTOM_TESTNET]: "FTM",
  [ChainId5.GNOSIS]: "XDAI",
  [ChainId5.BSC]: "BNB",
  [ChainId5.BSC_TESTNET]: "BNB",
  [ChainId5.ARBITRUM]: "ETH",
  [ChainId5.ARBITRUM_NOVA]: "ETH",
  [ChainId5.ARBITRUM_TESTNET]: "ETH",
  [ChainId5.AVALANCHE]: "AVAX",
  [ChainId5.AVALANCHE_TESTNET]: "AVAX",
  [ChainId5.HECO]: "HT",
  [ChainId5.HECO_TESTNET]: "HT",
  [ChainId5.HARMONY]: "ONE",
  [ChainId5.HARMONY_TESTNET]: "ONE",
  [ChainId5.OKEX]: "OKT",
  [ChainId5.OKEX_TESTNET]: "OKT",
  [ChainId5.CELO]: "CELO",
  [ChainId5.PALM]: "PALM",
  [ChainId5.MOONRIVER]: "MOVR",
  [ChainId5.FUSE]: "FUSE",
  [ChainId5.TELOS]: "TLOS",
  [ChainId5.MOONBEAM]: "GLMR",
  [ChainId5.OPTIMISM]: "ETH",
  [ChainId5.KAVA]: "KAVA",
  [ChainId5.METIS]: "METIS",
  [ChainId5.BOBA]: "ETH",
  [ChainId5.BOBA]: "ETH",
  [ChainId5.BOBA_AVAX]: "BOBA",
  [ChainId5.BOBA_BNB]: "BOBA",
  [ChainId5.BTTC]: "BTT",
  [ChainId5.THUNDERCORE]: "TT",
  // [ChainId.CONSENSUS_ZKEVM_TESTNET]: 'ETH',
  // [ChainId.SCROLL_ALPHA_TESTNET]: 'ETH',
  // [ChainId.BASE_TESTNET]: 'ETH',
  [ChainId5.POLYGON_ZKEVM]: "ETH",
  [ChainId5.CORE]: "CORE",
  [ChainId5.HAQQ]: "ISLM",
  [ChainId5.ZKSYNC_ERA]: "ETH",
  [ChainId5.LINEA]: "ETH",
  [ChainId5.BASE]: "ETH"
  // [ChainId.SEPOLIA]: 'ETH',
};

// src/Price.ts
import { Fraction as Fraction3 } from "@sushiswap/math";
import invariant6 from "tiny-invariant";
var Price = class _Price extends Fraction3 {
  baseCurrency;
  // input i.e. denominator
  quoteCurrency;
  // output i.e. numerator
  scalar;
  // used to adjust the raw fraction w/r/t the decimals of the {base,quote}Token
  /**
   * Construct a price, either with the base and quote currency amount, or the
   * @param args
   */
  constructor(...args) {
    let baseCurrency, quoteCurrency, denominator, numerator;
    if (args.length === 4) {
      ;
      [baseCurrency, quoteCurrency, denominator, numerator] = args;
    } else {
      const result = args[0].quoteAmount.divide(args[0].baseAmount);
      [baseCurrency, quoteCurrency, denominator, numerator] = [
        args[0].baseAmount.currency,
        args[0].quoteAmount.currency,
        result.denominator,
        result.numerator
      ];
    }
    super(numerator, denominator);
    this.baseCurrency = baseCurrency;
    this.quoteCurrency = quoteCurrency;
    this.scalar = new Fraction3(10n ** BigInt(baseCurrency.decimals), 10n ** BigInt(quoteCurrency.decimals));
  }
  /**
   * Flip the price, switching the base and quote currency
   */
  invert() {
    return new _Price(this.quoteCurrency, this.baseCurrency, this.numerator, this.denominator);
  }
  /**
   * Multiply the price by another price, returning a new price. The other price must have the same base currency as this price's quote currency
   * @param other the other price
   */
  multiply(other) {
    invariant6(this.quoteCurrency.equals(other.baseCurrency), "TOKEN");
    const fraction = super.multiply(other);
    return new _Price(this.baseCurrency, other.quoteCurrency, fraction.denominator, fraction.numerator);
  }
  /**
   * Return the amount of quote currency corresponding to a given amount of the base currency
   * @param currencyAmount the amount of base currency to quote against the price
   */
  quote(currencyAmount) {
    invariant6(currencyAmount.currency.equals(this.baseCurrency), "TOKEN");
    const result = super.multiply(currencyAmount);
    return Amount.fromFractionalAmount(this.quoteCurrency, result.numerator, result.denominator);
  }
  /**
   * Get the value scaled by decimals for formatting
   * @private
   */
  get adjustedForDecimals() {
    return super.multiply(this.scalar);
  }
  toSignificant(significantDigits = 6, format, rounding) {
    return this.adjustedForDecimals.toSignificant(significantDigits, format, rounding);
  }
  toFixed(decimalPlaces = 4, format, rounding) {
    return this.adjustedForDecimals.toFixed(decimalPlaces, format, rounding);
  }
};

// src/shortNameToCurrency.ts
import { ChainId as ChainId6 } from "@sushiswap/chain";
var CHAIN_ID_SHORT_CURRENCY_NAME_TO_CURRENCY = {
  [ChainId6.ARBITRUM]: {
    // NATIVE: Native.onChain(ChainId.ARBITRUM),
    // WNATIVE: WNATIVE[ChainId.ARBITRUM],
    ETH: Native.onChain(ChainId6.ARBITRUM),
    WETH: WNATIVE[ChainId6.ARBITRUM],
    WBTC: WBTC[ChainId6.ARBITRUM],
    USDC: USDC[ChainId6.ARBITRUM],
    USDT: USDT[ChainId6.ARBITRUM],
    DAI: DAI[ChainId6.ARBITRUM],
    FRAX: FRAX[ChainId6.ARBITRUM],
    MIM: MIM[ChainId6.ARBITRUM],
    SUSHI: SUSHI[ChainId6.ARBITRUM],
    MAI: MAI[ChainId6.ARBITRUM],
    UNI: UNI[ChainId6.ARBITRUM],
    AGEUR: AGEUR[ChainId6.ARBITRUM]
  },
  [ChainId6.ARBITRUM_NOVA]: {
    ETH: Native.onChain(ChainId6.ARBITRUM_NOVA),
    WETH: WNATIVE[ChainId6.ARBITRUM_NOVA],
    SUSHI: SUSHI[ChainId6.ARBITRUM_NOVA]
  },
  [ChainId6.AVALANCHE]: {
    // NATIVE: Native.onChain(ChainId.AVALANCHE),
    // WNATIVE: WNATIVE[ChainId.AVALANCHE],
    AVAX: Native.onChain(ChainId6.AVALANCHE),
    WAVAX: WNATIVE[ChainId6.AVALANCHE],
    ETH: WETH9[ChainId6.AVALANCHE],
    WETH: WETH9[ChainId6.AVALANCHE],
    WBTC: WBTC[ChainId6.AVALANCHE],
    USDC: USDC[ChainId6.AVALANCHE],
    USDT: USDT[ChainId6.AVALANCHE],
    DAI: DAI[ChainId6.AVALANCHE],
    FRAX: FRAX[ChainId6.AVALANCHE],
    MIM: MIM[ChainId6.AVALANCHE],
    SUSHI: SUSHI[ChainId6.AVALANCHE],
    MAI: MAI[ChainId6.AVALANCHE],
    UNI: UNI[ChainId6.AVALANCHE],
    AGEUR: AGEUR[ChainId6.AVALANCHE]
  },
  [ChainId6.BOBA]: {
    ETH: Native.onChain(ChainId6.BOBA),
    WETH: WNATIVE[ChainId6.BOBA],
    SUSHI: SUSHI[ChainId6.BOBA]
  },
  [ChainId6.BOBA_AVAX]: {
    BOBA: Native.onChain(ChainId6.BOBA_AVAX),
    WBOBA: WNATIVE[ChainId6.BOBA_AVAX],
    USDC: USDC[ChainId6.BOBA_AVAX]
    // SUSHI: SUSHI[ChainId.BOBA_AVAX],
  },
  [ChainId6.BOBA_BNB]: {
    BOBA: Native.onChain(ChainId6.BOBA_BNB),
    WBOBA: WNATIVE[ChainId6.BOBA_BNB],
    USDC: USDC[ChainId6.BOBA_BNB],
    BNB: new Token({
      chainId: ChainId6.BOBA_BNB,
      symbol: "BNB",
      name: "Binance Coin",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000023"
    })
    // SUSHI: SUSHI[ChainId.BOBA_BNB],
  },
  [ChainId6.BSC]: {
    // NATIVE: Native.onChain(ChainId.BSC),
    // WNATIVE: WNATIVE[ChainId.BSC],
    BNB: Native.onChain(ChainId6.BSC),
    WBNB: WNATIVE[ChainId6.BSC],
    ETH: WETH9[ChainId6.BSC],
    WETH: WETH9[ChainId6.BSC],
    USDC: USDC[ChainId6.BSC],
    USDT: USDT[ChainId6.BSC],
    DAI: DAI[ChainId6.BSC],
    FRAX: FRAX[ChainId6.BSC],
    MIM: MIM[ChainId6.BSC],
    SUSHI: SUSHI[ChainId6.BSC],
    MAI: MAI[ChainId6.BSC],
    UNI: UNI[ChainId6.BSC],
    AGEUR: AGEUR[ChainId6.BSC]
  },
  [ChainId6.BTTC]: {
    BTT: Native.onChain(ChainId6.BTTC),
    WBTT: WNATIVE[ChainId6.BTTC],
    SUSHI: SUSHI[ChainId6.BTTC]
  },
  [ChainId6.CELO]: {
    CELO: Native.onChain(ChainId6.CELO),
    WCELO: WNATIVE[ChainId6.CELO],
    SUSHI: SUSHI[ChainId6.CELO],
    AGEUR: AGEUR[ChainId6.CELO]
  },
  [ChainId6.ETHEREUM]: {
    // NATIVE: Native.onChain(ChainId.ETHEREUM),
    // WNATIVE: WETH9[ChainId.ETHEREUM],
    ETH: Native.onChain(ChainId6.ETHEREUM),
    WETH: WETH9[ChainId6.ETHEREUM],
    WBTC: WBTC[ChainId6.ETHEREUM],
    USDC: USDC[ChainId6.ETHEREUM],
    USDT: USDT[ChainId6.ETHEREUM],
    DAI: DAI[ChainId6.ETHEREUM],
    FRAX: FRAX[ChainId6.ETHEREUM],
    MIM: MIM[ChainId6.ETHEREUM],
    SUSHI: SUSHI[ChainId6.ETHEREUM],
    MAI: MAI[ChainId6.ETHEREUM],
    UNI: UNI[ChainId6.ETHEREUM],
    LUSD: LUSD[ChainId6.ETHEREUM],
    AGEUR: AGEUR[ChainId6.ETHEREUM]
  },
  [ChainId6.FANTOM]: {
    // NATIVE: Native.onChain(ChainId.FANTOM),
    // WNATIVE: WNATIVE[ChainId.FANTOM],
    FTM: Native.onChain(ChainId6.FANTOM),
    WFTM: WNATIVE[ChainId6.FANTOM],
    ETH: WETH9[ChainId6.FANTOM],
    WETH: WETH9[ChainId6.FANTOM],
    WBTC: WBTC[ChainId6.FANTOM],
    USDC: USDC[ChainId6.FANTOM],
    USDT: USDT[ChainId6.FANTOM],
    DAI: DAI[ChainId6.FANTOM],
    FRAX: FRAX[ChainId6.FANTOM],
    MIM: MIM[ChainId6.FANTOM],
    SUSHI: SUSHI[ChainId6.FANTOM],
    MAI: MAI[ChainId6.FANTOM]
  },
  [ChainId6.FUSE]: {
    FUSE: Native.onChain(ChainId6.FUSE),
    WFUSE: WNATIVE[ChainId6.FUSE],
    SUSHI: SUSHI[ChainId6.FUSE]
  },
  [ChainId6.GNOSIS]: {
    XDAI: Native.onChain(ChainId6.GNOSIS),
    WXDAI: WNATIVE[ChainId6.GNOSIS],
    SUSHI: SUSHI[ChainId6.GNOSIS],
    AGEUR: AGEUR[ChainId6.GNOSIS]
  },
  [ChainId6.KAVA]: {
    KAVA: Native.onChain(ChainId6.KAVA),
    WKAVA: WNATIVE[ChainId6.KAVA],
    SUSHI: SUSHI[ChainId6.KAVA]
  },
  [ChainId6.METIS]: {
    METIS: Native.onChain(ChainId6.METIS),
    WMETIS: WNATIVE[ChainId6.METIS],
    SUSHI: SUSHI[ChainId6.METIS]
  },
  [ChainId6.MOONBEAM]: {
    GLMR: Native.onChain(ChainId6.MOONBEAM),
    WGLMR: WNATIVE[ChainId6.MOONBEAM],
    SUSHI: SUSHI[ChainId6.MOONBEAM]
  },
  [ChainId6.MOONRIVER]: {
    MOVR: Native.onChain(ChainId6.MOONRIVER),
    WMOVR: WNATIVE[ChainId6.MOONRIVER],
    SUSHI: SUSHI[ChainId6.MOONRIVER]
  },
  [ChainId6.OPTIMISM]: {
    // NATIVE: Native.onChain(ChainId.OPTIMISM),
    // WNATIVE: WNATIVE[ChainId.OPTIMISM],
    ETH: Native.onChain(ChainId6.OPTIMISM),
    WETH: WNATIVE[ChainId6.OPTIMISM],
    USDC: USDC[ChainId6.OPTIMISM],
    USDT: USDT[ChainId6.OPTIMISM],
    OP: OP[ChainId6.OPTIMISM],
    SUSHI: SUSHI[ChainId6.OPTIMISM],
    AGEUR: AGEUR[ChainId6.OPTIMISM]
  },
  [ChainId6.POLYGON]: {
    // NATIVE: Native.onChain(ChainId.POLYGON),
    // WNATIVE: WNATIVE[ChainId.POLYGON],
    MATIC: Native.onChain(ChainId6.POLYGON),
    WMATIC: WNATIVE[ChainId6.POLYGON],
    ETH: WETH9[ChainId6.POLYGON],
    WETH: WETH9[ChainId6.POLYGON],
    WBTC: WBTC[ChainId6.POLYGON],
    USDC: USDC[ChainId6.POLYGON],
    USDT: USDT[ChainId6.POLYGON],
    DAI: DAI[ChainId6.POLYGON],
    FRAX: FRAX[ChainId6.POLYGON],
    MIM: MIM[ChainId6.POLYGON],
    SUSHI: SUSHI[ChainId6.POLYGON],
    MAI: MAI[ChainId6.POLYGON],
    UNI: UNI[ChainId6.POLYGON],
    AGEUR: AGEUR[ChainId6.POLYGON]
  },
  [ChainId6.HARMONY]: {
    ONE: Native.onChain(ChainId6.HARMONY),
    WONE: WNATIVE[ChainId6.HARMONY],
    SUSHI: SUSHI[ChainId6.HARMONY]
  },
  // [ChainId.SCROLL_ALPHA_TESTNET]: {
  //   ETH: Native.onChain(ChainId.SCROLL_ALPHA_TESTNET),
  //   WETH: WNATIVE[ChainId.SCROLL_ALPHA_TESTNET],
  // },
  // [ChainId.CONSENSUS_ZKEVM_TESTNET]: {
  //   ETH: Native.onChain(ChainId.CONSENSUS_ZKEVM_TESTNET),
  //   WETH: WNATIVE[ChainId.CONSENSUS_ZKEVM_TESTNET],
  // },
  // [ChainId.BASE_TESTNET]: {
  //   ETH: Native.onChain(ChainId.BASE_TESTNET),
  //   WETH: WNATIVE[ChainId.BASE_TESTNET],
  // },
  [ChainId6.THUNDERCORE]: {
    NATIVE: Native.onChain(ChainId6.THUNDERCORE),
    WNATIVE: WNATIVE[ChainId6.THUNDERCORE],
    SUSHI: SUSHI[ChainId6.THUNDERCORE]
  },
  [ChainId6.POLYGON_ZKEVM]: {
    NATIVE: Native.onChain(ChainId6.POLYGON_ZKEVM),
    WNATIVE: WNATIVE[ChainId6.POLYGON_ZKEVM],
    ETH: Native.onChain(ChainId6.POLYGON_ZKEVM),
    WETH: WNATIVE[ChainId6.POLYGON_ZKEVM]
  },
  [ChainId6.CORE]: {
    NATIVE: Native.onChain(ChainId6.CORE),
    WNATIVE: WNATIVE[ChainId6.CORE],
    ETH: WETH9[ChainId6.CORE],
    WETH: WETH9[ChainId6.CORE]
  },
  [ChainId6.HAQQ]: {
    NATIVE: Native.onChain(ChainId6.HAQQ),
    WNATIVE: WNATIVE[ChainId6.HAQQ],
    ETH: WETH9[ChainId6.HAQQ],
    WETH: WETH9[ChainId6.HAQQ]
  },
  [ChainId6.ZKSYNC_ERA]: {
    NATIVE: Native.onChain(ChainId6.ZKSYNC_ERA),
    WNATIVE: WNATIVE[ChainId6.ZKSYNC_ERA],
    ETH: Native.onChain(ChainId6.ZKSYNC_ERA),
    WETH: WNATIVE[ChainId6.ZKSYNC_ERA]
  },
  [ChainId6.LINEA]: {
    NATIVE: Native.onChain(ChainId6.LINEA),
    WNATIVE: WNATIVE[ChainId6.LINEA],
    ETH: Native.onChain(ChainId6.LINEA),
    WETH: WNATIVE[ChainId6.LINEA]
  },
  [ChainId6.BASE]: {
    NATIVE: Native.onChain(ChainId6.BASE),
    WNATIVE: WNATIVE[ChainId6.BASE],
    ETH: Native.onChain(ChainId6.BASE),
    WETH: WNATIVE[ChainId6.BASE]
  }
};
var isShortCurrencyNameSupported = (chainId) => chainId in CHAIN_ID_SHORT_CURRENCY_NAME_TO_CURRENCY;
var isShortCurrencyName = (chainId, shortCurrencyName) => {
  return isShortCurrencyNameSupported(chainId) && shortCurrencyName in CHAIN_ID_SHORT_CURRENCY_NAME_TO_CURRENCY[chainId];
};
var currencyFromShortCurrencyName = (chainId, shortCurrencyName) => {
  if (!isShortCurrencyNameSupported(chainId))
    throw new Error(`Unsupported chain id ${chainId} for short currency name ${shortCurrencyName}`);
  if (!(shortCurrencyName in CHAIN_ID_SHORT_CURRENCY_NAME_TO_CURRENCY[chainId]))
    throw new Error(`Unsupported short currency name ${shortCurrencyName} on chain ${chainId}`);
  return CHAIN_ID_SHORT_CURRENCY_NAME_TO_CURRENCY[chainId][shortCurrencyName];
};

// src/tryParseAmount.ts
import { parseUnits } from "@ethersproject/units";
function tryParseAmount(value, currency) {
  if (!value || !currency) {
    return void 0;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== "0") {
      return Amount.fromRawAmount(currency, BigInt(typedValueParsed));
    }
  } catch (error) {
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  return void 0;
}

// src/unwrap.ts
var unwrapToken = (currency) => {
  return currency.wrapped.address === Native.onChain(currency.chainId).wrapped.address ? Native.onChain(currency.chainId) : currency;
};
export {
  AAVE,
  AAVE_ADDRESS,
  AGEUR,
  AGEUR_ADDRESS,
  AMPL,
  AMPL_ADDRESS,
  ANKR,
  ANKR_ADDRESS,
  APE,
  APE_ADDRESS,
  ARB,
  ARB_ADDRESS,
  Amount,
  BCT,
  BCT_ADDRESS,
  BUSD,
  BUSD_ADDRESS,
  COMP,
  COMP_ADDRESS,
  CRV,
  CRV_ADDRESS,
  DAI,
  DAI_ADDRESS,
  ENJ,
  ENJ_ADDRESS,
  FEI,
  FEI_ADDRESS,
  FRAX,
  FRAX_ADDRESS,
  FTM_ADDRESS,
  FXS,
  FXS_ADDRESS,
  GALA,
  GALA_ADDRESS,
  GNO,
  GNO_ADDRESS,
  JPY,
  JPY_ADDRESS,
  KLIMA,
  KLIMA_ADDRESS,
  KP3R,
  KP3R_ADDRESS,
  LDO,
  LDO_ADDRESS,
  LINK,
  LINK_ADDRESS,
  LUSD,
  LUSD_ADDRESS,
  MAI,
  MAI_ADDRESS,
  MANA,
  MANA_ADDRESS,
  MATIC,
  MATIC_ADDRESS,
  MIM,
  MIM_ADDRESS,
  MKR,
  MKR_ADDRESS,
  NFTX,
  NFTX_ADDRESS,
  Native,
  OHM,
  OHM_ADDRESS,
  OP,
  OP_ADDRESS,
  PRIMATE,
  PRIMATE_ADDRESS,
  Price,
  QUICK,
  QUICK_ADDRESS,
  SNX,
  SNX_ADDRESS,
  SUSHI,
  SUSHI_ADDRESS,
  SWISE,
  SWISE_ADDRESS,
  Share,
  TOKEN_MAP,
  TRIBE,
  TRIBE_ADDRESS,
  TUSD,
  TUSD_ADDRESS,
  Token,
  UNI,
  UNI_ADDRESS,
  USDC,
  USDC_ADDRESS,
  USDT,
  USDT_ADDRESS,
  WBTC,
  WBTC_ADDRESS,
  WETH9,
  WETH9_ADDRESS,
  WNATIVE,
  WNATIVE_ADDRESS,
  XSUSHI,
  XSUSHI_ADDRESS,
  YFI,
  YFI_ADDRESS,
  addressMapToTokenMap,
  amountSchema,
  axlDAI_ADDRESS,
  axlUSDC,
  axlUSDC_ADDRESS,
  currencyFromShortCurrencyName,
  defaultQuoteCurrency,
  isShortCurrencyName,
  isShortCurrencyNameSupported,
  nativeCurrencyIds,
  nativeSchema,
  rETH2,
  rETH2_ADDRESS,
  renBTC,
  renBTC_ADDRESS,
  sETH2,
  sETH2_ADDRESS,
  tokenSchema,
  tryParseAmount,
  unwrapToken
};
