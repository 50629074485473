import * as React from 'react'

import { IconComponent } from '../../../../types'

export const HecoNaked: IconComponent = (props) => (
  <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M72.0248 48.6063C72.0248 37.1273 66.4914 27.2376 62.2529 24C62.2529 24 61.9586 23.8234 61.9586 24.2943C61.6054 46.4871 50.3618 52.5504 44.122 60.6151C29.8173 79.3348 43.1212 99.8204 56.6606 103.588C64.2544 105.707 54.8946 99.8204 53.7173 87.4584C52.3045 72.4474 72.0248 61.0272 72.0248 48.6063"
      fill="#282E5B"
    />
    <path
      d="M79.4424 56.2002C79.3836 56.1413 79.2658 56.1413 79.1481 56.2002C79.1481 56.2002 79.1481 56.2002 79.0892 56.259C78.8538 59.0258 75.7927 65.0302 71.9664 70.5048C58.9568 89.1067 66.374 98.0545 70.5536 102.882C72.9671 105.707 70.5536 102.882 76.6168 99.9971C77.0878 99.7616 88.3902 93.7572 89.6264 80.159C90.8626 66.9139 82.4447 58.6137 79.4424 56.2002"
      fill="#059BDC"
    />
  </svg>
)
