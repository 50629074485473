import * as React from 'react'

import { IconComponent } from '../../../../types'

export const MoonbeamNaked: IconComponent = (props) => (
  <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.59 84.589a1.542 1.542 0 1 0 0-3.084 1.542 1.542 0 0 0 0 3.084ZM94.618 74.519H42.955c-1.149 0-1.88 1.227-1.335 2.24l.026.05c.263.49.778.794 1.334.794h51.612c.557 0 1.072-.305 1.335-.795l.026-.049c.542-1.013-.187-2.24-1.335-2.24ZM101.484 60.544H42.952c-.873 0-1.56.734-1.514 1.605v.05a1.517 1.517 0 0 0 1.519 1.427h58.527c.804 0 1.472-.627 1.517-1.428v-.049a1.519 1.519 0 0 0-1.512-1.605h-.005ZM79.444 95.477H53.066c-1.602 0-2.108 2.155-.675 2.877.032.016.065.032.095.049.21.104.443.158.678.158h26.184a1.525 1.525 0 0 0 .774-.207c1.43-.722.927-2.877-.676-2.877h-.002ZM95.562 88.49H69.184c-1.603 0-2.108 2.156-.676 2.878.033.016.065.032.096.049.21.104.442.158.677.158h26.185a1.524 1.524 0 0 0 .773-.207c1.43-.722.927-2.877-.675-2.877h-.002ZM59.594 84.102c-.013-.016-.03-.032-.044-.049-.903-.966-.212-2.55 1.114-2.55h42.812c1.325 0 2.017 1.581 1.113 2.55-.013.017-.03.033-.044.05a1.53 1.53 0 0 1-1.113.484H60.705a1.53 1.53 0 0 1-1.113-.485h.002ZM34.095 81.505h20.443c1.602 0 2.108 2.155.675 2.877a1.517 1.517 0 0 1-.773.207H34.193a1.525 1.525 0 0 1-.774-.207c-1.43-.722-.927-2.877.676-2.877ZM37.449 63.628a1.542 1.542 0 1 0 0-3.084 1.542 1.542 0 0 0 0 3.084ZM75.319 69.49l.013-.05a1.517 1.517 0 0 0-1.467-1.91H31.048c-.997 0-1.728.948-1.467 1.91l.014.05c.181.663.78 1.125 1.467 1.125h42.79c.686 0 1.285-.462 1.466-1.126ZM25.542 70.615a1.542 1.542 0 1 0 0-3.084 1.542 1.542 0 0 0 0 3.084ZM37.449 77.6a1.542 1.542 0 1 0 0-3.084 1.542 1.542 0 0 0 0 3.085ZM63.68 91.575a1.542 1.542 0 1 0 0-3.084 1.542 1.542 0 0 0 0 3.084ZM47.562 98.561a1.542 1.542 0 1 0 0-3.084 1.542 1.542 0 0 0 0 3.084Z"
      fill="#E1147B"
    />
    <path
      d="M72.5 29c-14.086 0-25.502 11.512-25.502 25.711v.095c.038.669.592 1.194 1.258 1.194h48.487c.666 0 1.22-.523 1.255-1.194v-.04c.002-.02 0-.036 0-.055C98 40.512 86.584 29 72.499 29Z"
      fill="#53CBC8"
    />
  </svg>
)
