import { parseUnits } from '@ethersproject/units'
import { ChainId } from '@sushiswap/chain'
import { Fraction } from '@sushiswap/math'
import { useQuery } from '@tanstack/react-query'

interface UsePrice {
  chainId: ChainId | undefined
  address: string | undefined
}

export async function getPrice({ chainId, address }: { chainId: ChainId; address: string }): Promise<number> {
  return fetch(`https://api.sushi.com/price/v1/${chainId}/${address}`)
    .then((res) => res.json())
    .catch((e) => {
      console.error('Error fetching token prices', chainId, e)
      throw e
    })
}

export const usePrice = ({ chainId, address }: UsePrice) => {
  return useQuery({
    queryKey: [`https://token-price.sushi.com/v1/${chainId}/${address}`],
    queryFn: async () => {
      if (!chainId || !address) return

      const data = await getPrice({ chainId, address })
      return new Fraction(parseUnits(data.toFixed(18), 18).toString(), parseUnits('1', 18).toString())
    },
    enabled: Boolean(chainId && address),
    staleTime: 900000, // 15 mins
    cacheTime: 3600000, // 1hr
    refetchOnWindowFocus: false,
  })
}
