// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import AutoPaginationTransform from "@graphprotocol/client-auto-pagination";
import BareMerger from "@graphql-mesh/merger-bare";
import { printWithCache } from '@graphql-mesh/utils';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { FuroStreamTypes } from './sources/FuroStream/types';
import * as importedModule$0 from "./sources/FuroStream/introspectionSchema";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  Int8: any;
  Timestamp: any;
};

export type Aggregation_interval =
  | 'hour'
  | 'day';

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type FuroStatus =
  | 'ACTIVE'
  | 'CANCELLED';

export type Global = {
  id: Scalars['ID'];
  vestingCount: Scalars['BigInt'];
  streamCount: Scalars['BigInt'];
  userCount: Scalars['BigInt'];
  transactionCount: Scalars['BigInt'];
};

export type Global_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  vestingCount?: InputMaybe<Scalars['BigInt']>;
  vestingCount_not?: InputMaybe<Scalars['BigInt']>;
  vestingCount_gt?: InputMaybe<Scalars['BigInt']>;
  vestingCount_lt?: InputMaybe<Scalars['BigInt']>;
  vestingCount_gte?: InputMaybe<Scalars['BigInt']>;
  vestingCount_lte?: InputMaybe<Scalars['BigInt']>;
  vestingCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  vestingCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  streamCount?: InputMaybe<Scalars['BigInt']>;
  streamCount_not?: InputMaybe<Scalars['BigInt']>;
  streamCount_gt?: InputMaybe<Scalars['BigInt']>;
  streamCount_lt?: InputMaybe<Scalars['BigInt']>;
  streamCount_gte?: InputMaybe<Scalars['BigInt']>;
  streamCount_lte?: InputMaybe<Scalars['BigInt']>;
  streamCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  streamCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  userCount?: InputMaybe<Scalars['BigInt']>;
  userCount_not?: InputMaybe<Scalars['BigInt']>;
  userCount_gt?: InputMaybe<Scalars['BigInt']>;
  userCount_lt?: InputMaybe<Scalars['BigInt']>;
  userCount_gte?: InputMaybe<Scalars['BigInt']>;
  userCount_lte?: InputMaybe<Scalars['BigInt']>;
  userCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  userCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionCount?: InputMaybe<Scalars['BigInt']>;
  transactionCount_not?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Global_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Global_filter>>>;
};

export type Global_orderBy =
  | 'id'
  | 'vestingCount'
  | 'streamCount'
  | 'userCount'
  | 'transactionCount';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type Query = {
  global?: Maybe<Global>;
  globals: Array<Global>;
  stream?: Maybe<Stream>;
  streams: Array<Stream>;
  vesting?: Maybe<Vesting>;
  vestings: Array<Vesting>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  user?: Maybe<User>;
  users: Array<User>;
  rebase?: Maybe<Rebase>;
  rebases: Array<Rebase>;
  tokenHourSnapshot?: Maybe<TokenHourSnapshot>;
  tokenHourSnapshots: Array<TokenHourSnapshot>;
  tokenDaySnapshot?: Maybe<TokenDaySnapshot>;
  tokenDaySnapshots: Array<TokenDaySnapshot>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QueryglobalArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryglobalsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Global_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Global_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystreamArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystreamsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stream_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryvestingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryvestingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Vesting_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Vesting_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransactionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryusersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryrebaseArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryrebasesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Rebase_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Rebase_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenHourSnapshotArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenHourSnapshotsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenHourSnapshot_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenHourSnapshot_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenDaySnapshotArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenDaySnapshotsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenDaySnapshot_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenDaySnapshot_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type Rebase = {
  /**  Token address  */
  id: Scalars['ID'];
  /**  Token this rebase belongs to  */
  token: Token;
  /**  Base (Share)  */
  base: Scalars['BigInt'];
  /**  Elastic (Amount)  */
  elastic: Scalars['BigInt'];
  /**  created at block, used internally to know when to ignore updates for the rebase  */
  createdAtBlock: Scalars['BigInt'];
};

export type Rebase_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  token?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<Token_filter>;
  base?: InputMaybe<Scalars['BigInt']>;
  base_not?: InputMaybe<Scalars['BigInt']>;
  base_gt?: InputMaybe<Scalars['BigInt']>;
  base_lt?: InputMaybe<Scalars['BigInt']>;
  base_gte?: InputMaybe<Scalars['BigInt']>;
  base_lte?: InputMaybe<Scalars['BigInt']>;
  base_in?: InputMaybe<Array<Scalars['BigInt']>>;
  base_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  elastic?: InputMaybe<Scalars['BigInt']>;
  elastic_not?: InputMaybe<Scalars['BigInt']>;
  elastic_gt?: InputMaybe<Scalars['BigInt']>;
  elastic_lt?: InputMaybe<Scalars['BigInt']>;
  elastic_gte?: InputMaybe<Scalars['BigInt']>;
  elastic_lte?: InputMaybe<Scalars['BigInt']>;
  elastic_in?: InputMaybe<Array<Scalars['BigInt']>>;
  elastic_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtBlock?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Rebase_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Rebase_filter>>>;
};

export type Rebase_orderBy =
  | 'id'
  | 'token'
  | 'token__id'
  | 'token__liquidityShares'
  | 'token__symbol'
  | 'token__symbolSuccess'
  | 'token__name'
  | 'token__nameSuccess'
  | 'token__decimals'
  | 'token__decimalsSuccess'
  | 'token__createdAtBlock'
  | 'token__createdAtTimestamp'
  | 'base'
  | 'elastic'
  | 'createdAtBlock';

export type Stream = {
  id: Scalars['ID'];
  recipient: User;
  /**  Initial shares  */
  initialShares: Scalars['BigInt'];
  /**  Initial amount  */
  initialAmount: Scalars['BigInt'];
  /**  Extended shares, increases for each time the stream is updated with a top up amount  */
  extendedShares: Scalars['BigInt'];
  /**  Set when a stream is extended, useful to keep track of how much many shares a stream contained after extending  */
  initialSharesExtended: Scalars['BigInt'];
  /**  Amount that has been withdrawn after extending the stream  */
  withdrawnAmountAfterExtension: Scalars['BigInt'];
  /**  Remaining shares  */
  remainingShares: Scalars['BigInt'];
  /**  Withdrawn amount  */
  withdrawnAmount: Scalars['BigInt'];
  token: Token;
  status: FuroStatus;
  createdBy: User;
  fromBentoBox: Scalars['Boolean'];
  startedAt: Scalars['BigInt'];
  expiresAt: Scalars['BigInt'];
  txHash: Scalars['String'];
  transactionCount: Scalars['BigInt'];
  createdAtBlock: Scalars['BigInt'];
  createdAtTimestamp: Scalars['BigInt'];
  extendedAtBlock: Scalars['BigInt'];
  extendedAtTimestamp: Scalars['BigInt'];
  modifiedAtBlock: Scalars['BigInt'];
  modifiedAtTimestamp: Scalars['BigInt'];
};

export type Stream_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  recipient?: InputMaybe<Scalars['String']>;
  recipient_not?: InputMaybe<Scalars['String']>;
  recipient_gt?: InputMaybe<Scalars['String']>;
  recipient_lt?: InputMaybe<Scalars['String']>;
  recipient_gte?: InputMaybe<Scalars['String']>;
  recipient_lte?: InputMaybe<Scalars['String']>;
  recipient_in?: InputMaybe<Array<Scalars['String']>>;
  recipient_not_in?: InputMaybe<Array<Scalars['String']>>;
  recipient_contains?: InputMaybe<Scalars['String']>;
  recipient_contains_nocase?: InputMaybe<Scalars['String']>;
  recipient_not_contains?: InputMaybe<Scalars['String']>;
  recipient_not_contains_nocase?: InputMaybe<Scalars['String']>;
  recipient_starts_with?: InputMaybe<Scalars['String']>;
  recipient_starts_with_nocase?: InputMaybe<Scalars['String']>;
  recipient_not_starts_with?: InputMaybe<Scalars['String']>;
  recipient_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  recipient_ends_with?: InputMaybe<Scalars['String']>;
  recipient_ends_with_nocase?: InputMaybe<Scalars['String']>;
  recipient_not_ends_with?: InputMaybe<Scalars['String']>;
  recipient_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  recipient_?: InputMaybe<User_filter>;
  initialShares?: InputMaybe<Scalars['BigInt']>;
  initialShares_not?: InputMaybe<Scalars['BigInt']>;
  initialShares_gt?: InputMaybe<Scalars['BigInt']>;
  initialShares_lt?: InputMaybe<Scalars['BigInt']>;
  initialShares_gte?: InputMaybe<Scalars['BigInt']>;
  initialShares_lte?: InputMaybe<Scalars['BigInt']>;
  initialShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialAmount?: InputMaybe<Scalars['BigInt']>;
  initialAmount_not?: InputMaybe<Scalars['BigInt']>;
  initialAmount_gt?: InputMaybe<Scalars['BigInt']>;
  initialAmount_lt?: InputMaybe<Scalars['BigInt']>;
  initialAmount_gte?: InputMaybe<Scalars['BigInt']>;
  initialAmount_lte?: InputMaybe<Scalars['BigInt']>;
  initialAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  extendedShares?: InputMaybe<Scalars['BigInt']>;
  extendedShares_not?: InputMaybe<Scalars['BigInt']>;
  extendedShares_gt?: InputMaybe<Scalars['BigInt']>;
  extendedShares_lt?: InputMaybe<Scalars['BigInt']>;
  extendedShares_gte?: InputMaybe<Scalars['BigInt']>;
  extendedShares_lte?: InputMaybe<Scalars['BigInt']>;
  extendedShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  extendedShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialSharesExtended?: InputMaybe<Scalars['BigInt']>;
  initialSharesExtended_not?: InputMaybe<Scalars['BigInt']>;
  initialSharesExtended_gt?: InputMaybe<Scalars['BigInt']>;
  initialSharesExtended_lt?: InputMaybe<Scalars['BigInt']>;
  initialSharesExtended_gte?: InputMaybe<Scalars['BigInt']>;
  initialSharesExtended_lte?: InputMaybe<Scalars['BigInt']>;
  initialSharesExtended_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialSharesExtended_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  withdrawnAmountAfterExtension?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmountAfterExtension_not?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmountAfterExtension_gt?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmountAfterExtension_lt?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmountAfterExtension_gte?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmountAfterExtension_lte?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmountAfterExtension_in?: InputMaybe<Array<Scalars['BigInt']>>;
  withdrawnAmountAfterExtension_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  remainingShares?: InputMaybe<Scalars['BigInt']>;
  remainingShares_not?: InputMaybe<Scalars['BigInt']>;
  remainingShares_gt?: InputMaybe<Scalars['BigInt']>;
  remainingShares_lt?: InputMaybe<Scalars['BigInt']>;
  remainingShares_gte?: InputMaybe<Scalars['BigInt']>;
  remainingShares_lte?: InputMaybe<Scalars['BigInt']>;
  remainingShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  remainingShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  withdrawnAmount?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_not?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_gt?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_lt?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_gte?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_lte?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  withdrawnAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  token?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<Token_filter>;
  status?: InputMaybe<FuroStatus>;
  status_not?: InputMaybe<FuroStatus>;
  status_in?: InputMaybe<Array<FuroStatus>>;
  status_not_in?: InputMaybe<Array<FuroStatus>>;
  createdBy?: InputMaybe<Scalars['String']>;
  createdBy_not?: InputMaybe<Scalars['String']>;
  createdBy_gt?: InputMaybe<Scalars['String']>;
  createdBy_lt?: InputMaybe<Scalars['String']>;
  createdBy_gte?: InputMaybe<Scalars['String']>;
  createdBy_lte?: InputMaybe<Scalars['String']>;
  createdBy_in?: InputMaybe<Array<Scalars['String']>>;
  createdBy_not_in?: InputMaybe<Array<Scalars['String']>>;
  createdBy_contains?: InputMaybe<Scalars['String']>;
  createdBy_contains_nocase?: InputMaybe<Scalars['String']>;
  createdBy_not_contains?: InputMaybe<Scalars['String']>;
  createdBy_not_contains_nocase?: InputMaybe<Scalars['String']>;
  createdBy_starts_with?: InputMaybe<Scalars['String']>;
  createdBy_starts_with_nocase?: InputMaybe<Scalars['String']>;
  createdBy_not_starts_with?: InputMaybe<Scalars['String']>;
  createdBy_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  createdBy_ends_with?: InputMaybe<Scalars['String']>;
  createdBy_ends_with_nocase?: InputMaybe<Scalars['String']>;
  createdBy_not_ends_with?: InputMaybe<Scalars['String']>;
  createdBy_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  createdBy_?: InputMaybe<User_filter>;
  fromBentoBox?: InputMaybe<Scalars['Boolean']>;
  fromBentoBox_not?: InputMaybe<Scalars['Boolean']>;
  fromBentoBox_in?: InputMaybe<Array<Scalars['Boolean']>>;
  fromBentoBox_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  startedAt?: InputMaybe<Scalars['BigInt']>;
  startedAt_not?: InputMaybe<Scalars['BigInt']>;
  startedAt_gt?: InputMaybe<Scalars['BigInt']>;
  startedAt_lt?: InputMaybe<Scalars['BigInt']>;
  startedAt_gte?: InputMaybe<Scalars['BigInt']>;
  startedAt_lte?: InputMaybe<Scalars['BigInt']>;
  startedAt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startedAt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiresAt?: InputMaybe<Scalars['BigInt']>;
  expiresAt_not?: InputMaybe<Scalars['BigInt']>;
  expiresAt_gt?: InputMaybe<Scalars['BigInt']>;
  expiresAt_lt?: InputMaybe<Scalars['BigInt']>;
  expiresAt_gte?: InputMaybe<Scalars['BigInt']>;
  expiresAt_lte?: InputMaybe<Scalars['BigInt']>;
  expiresAt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiresAt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txHash?: InputMaybe<Scalars['String']>;
  txHash_not?: InputMaybe<Scalars['String']>;
  txHash_gt?: InputMaybe<Scalars['String']>;
  txHash_lt?: InputMaybe<Scalars['String']>;
  txHash_gte?: InputMaybe<Scalars['String']>;
  txHash_lte?: InputMaybe<Scalars['String']>;
  txHash_in?: InputMaybe<Array<Scalars['String']>>;
  txHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  txHash_contains?: InputMaybe<Scalars['String']>;
  txHash_contains_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_contains?: InputMaybe<Scalars['String']>;
  txHash_not_contains_nocase?: InputMaybe<Scalars['String']>;
  txHash_starts_with?: InputMaybe<Scalars['String']>;
  txHash_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_starts_with?: InputMaybe<Scalars['String']>;
  txHash_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_ends_with?: InputMaybe<Scalars['String']>;
  txHash_ends_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_ends_with?: InputMaybe<Scalars['String']>;
  txHash_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transactionCount?: InputMaybe<Scalars['BigInt']>;
  transactionCount_not?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtBlock?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  extendedAtBlock?: InputMaybe<Scalars['BigInt']>;
  extendedAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  extendedAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  extendedAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  extendedAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  extendedAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  extendedAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  extendedAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  extendedAtTimestamp?: InputMaybe<Scalars['BigInt']>;
  extendedAtTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  extendedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  extendedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  extendedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  extendedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  extendedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  extendedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  modifiedAtBlock?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  modifiedAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  modifiedAtTimestamp?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  modifiedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Stream_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Stream_filter>>>;
};

export type Stream_orderBy =
  | 'id'
  | 'recipient'
  | 'recipient__id'
  | 'recipient__createdAtBlock'
  | 'recipient__createdAtTimestamp'
  | 'initialShares'
  | 'initialAmount'
  | 'extendedShares'
  | 'initialSharesExtended'
  | 'withdrawnAmountAfterExtension'
  | 'remainingShares'
  | 'withdrawnAmount'
  | 'token'
  | 'token__id'
  | 'token__liquidityShares'
  | 'token__symbol'
  | 'token__symbolSuccess'
  | 'token__name'
  | 'token__nameSuccess'
  | 'token__decimals'
  | 'token__decimalsSuccess'
  | 'token__createdAtBlock'
  | 'token__createdAtTimestamp'
  | 'status'
  | 'createdBy'
  | 'createdBy__id'
  | 'createdBy__createdAtBlock'
  | 'createdBy__createdAtTimestamp'
  | 'fromBentoBox'
  | 'startedAt'
  | 'expiresAt'
  | 'txHash'
  | 'transactionCount'
  | 'createdAtBlock'
  | 'createdAtTimestamp'
  | 'extendedAtBlock'
  | 'extendedAtTimestamp'
  | 'modifiedAtBlock'
  | 'modifiedAtTimestamp';

export type Subscription = {
  global?: Maybe<Global>;
  globals: Array<Global>;
  stream?: Maybe<Stream>;
  streams: Array<Stream>;
  vesting?: Maybe<Vesting>;
  vestings: Array<Vesting>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  user?: Maybe<User>;
  users: Array<User>;
  rebase?: Maybe<Rebase>;
  rebases: Array<Rebase>;
  tokenHourSnapshot?: Maybe<TokenHourSnapshot>;
  tokenHourSnapshots: Array<TokenHourSnapshot>;
  tokenDaySnapshot?: Maybe<TokenDaySnapshot>;
  tokenDaySnapshots: Array<TokenDaySnapshot>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionglobalArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionglobalsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Global_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Global_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstreamArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstreamsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stream_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionvestingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionvestingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Vesting_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Vesting_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransactionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionusersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionrebaseArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionrebasesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Rebase_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Rebase_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenHourSnapshotArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenHourSnapshotsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenHourSnapshot_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenHourSnapshot_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenDaySnapshotArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenDaySnapshotsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenDaySnapshot_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenDaySnapshot_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type Token = {
  id: Scalars['ID'];
  liquidityShares: Scalars['BigInt'];
  symbol: Scalars['String'];
  symbolSuccess: Scalars['Boolean'];
  name: Scalars['String'];
  nameSuccess: Scalars['Boolean'];
  decimals: Scalars['BigInt'];
  decimalsSuccess: Scalars['Boolean'];
  rebase: Rebase;
  createdAtBlock: Scalars['BigInt'];
  createdAtTimestamp: Scalars['BigInt'];
};

export type TokenDaySnapshot = {
  /**  {tokenId}-day-{timestamp}  */
  id: Scalars['ID'];
  date: Scalars['Int'];
  token: Token;
  cumulativeLiquidityShares: Scalars['BigInt'];
  cumulativeLiquidityAmount: Scalars['BigInt'];
  transactionCount: Scalars['BigInt'];
};

export type TokenDaySnapshot_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  date?: InputMaybe<Scalars['Int']>;
  date_not?: InputMaybe<Scalars['Int']>;
  date_gt?: InputMaybe<Scalars['Int']>;
  date_lt?: InputMaybe<Scalars['Int']>;
  date_gte?: InputMaybe<Scalars['Int']>;
  date_lte?: InputMaybe<Scalars['Int']>;
  date_in?: InputMaybe<Array<Scalars['Int']>>;
  date_not_in?: InputMaybe<Array<Scalars['Int']>>;
  token?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<Token_filter>;
  cumulativeLiquidityShares?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_not?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_gt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_lt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_gte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_lte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cumulativeLiquidityShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cumulativeLiquidityAmount?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_not?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_gt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_lt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_gte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_lte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cumulativeLiquidityAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionCount?: InputMaybe<Scalars['BigInt']>;
  transactionCount_not?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenDaySnapshot_filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenDaySnapshot_filter>>>;
};

export type TokenDaySnapshot_orderBy =
  | 'id'
  | 'date'
  | 'token'
  | 'token__id'
  | 'token__liquidityShares'
  | 'token__symbol'
  | 'token__symbolSuccess'
  | 'token__name'
  | 'token__nameSuccess'
  | 'token__decimals'
  | 'token__decimalsSuccess'
  | 'token__createdAtBlock'
  | 'token__createdAtTimestamp'
  | 'cumulativeLiquidityShares'
  | 'cumulativeLiquidityAmount'
  | 'transactionCount';

export type TokenHourSnapshot = {
  /**  {tokenId}-hour-{timestamp}  */
  id: Scalars['ID'];
  date: Scalars['Int'];
  token: Token;
  cumulativeLiquidityShares: Scalars['BigInt'];
  cumulativeLiquidityAmount: Scalars['BigInt'];
  transactionCount: Scalars['BigInt'];
};

export type TokenHourSnapshot_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  date?: InputMaybe<Scalars['Int']>;
  date_not?: InputMaybe<Scalars['Int']>;
  date_gt?: InputMaybe<Scalars['Int']>;
  date_lt?: InputMaybe<Scalars['Int']>;
  date_gte?: InputMaybe<Scalars['Int']>;
  date_lte?: InputMaybe<Scalars['Int']>;
  date_in?: InputMaybe<Array<Scalars['Int']>>;
  date_not_in?: InputMaybe<Array<Scalars['Int']>>;
  token?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<Token_filter>;
  cumulativeLiquidityShares?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_not?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_gt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_lt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_gte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_lte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cumulativeLiquidityShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cumulativeLiquidityAmount?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_not?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_gt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_lt?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_gte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_lte?: InputMaybe<Scalars['BigInt']>;
  cumulativeLiquidityAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cumulativeLiquidityAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionCount?: InputMaybe<Scalars['BigInt']>;
  transactionCount_not?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenHourSnapshot_filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenHourSnapshot_filter>>>;
};

export type TokenHourSnapshot_orderBy =
  | 'id'
  | 'date'
  | 'token'
  | 'token__id'
  | 'token__liquidityShares'
  | 'token__symbol'
  | 'token__symbolSuccess'
  | 'token__name'
  | 'token__nameSuccess'
  | 'token__decimals'
  | 'token__decimalsSuccess'
  | 'token__createdAtBlock'
  | 'token__createdAtTimestamp'
  | 'cumulativeLiquidityShares'
  | 'cumulativeLiquidityAmount'
  | 'transactionCount';

export type Token_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidityShares?: InputMaybe<Scalars['BigInt']>;
  liquidityShares_not?: InputMaybe<Scalars['BigInt']>;
  liquidityShares_gt?: InputMaybe<Scalars['BigInt']>;
  liquidityShares_lt?: InputMaybe<Scalars['BigInt']>;
  liquidityShares_gte?: InputMaybe<Scalars['BigInt']>;
  liquidityShares_lte?: InputMaybe<Scalars['BigInt']>;
  liquidityShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidityShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbolSuccess?: InputMaybe<Scalars['Boolean']>;
  symbolSuccess_not?: InputMaybe<Scalars['Boolean']>;
  symbolSuccess_in?: InputMaybe<Array<Scalars['Boolean']>>;
  symbolSuccess_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  name?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nameSuccess?: InputMaybe<Scalars['Boolean']>;
  nameSuccess_not?: InputMaybe<Scalars['Boolean']>;
  nameSuccess_in?: InputMaybe<Array<Scalars['Boolean']>>;
  nameSuccess_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  decimals?: InputMaybe<Scalars['BigInt']>;
  decimals_not?: InputMaybe<Scalars['BigInt']>;
  decimals_gt?: InputMaybe<Scalars['BigInt']>;
  decimals_lt?: InputMaybe<Scalars['BigInt']>;
  decimals_gte?: InputMaybe<Scalars['BigInt']>;
  decimals_lte?: InputMaybe<Scalars['BigInt']>;
  decimals_in?: InputMaybe<Array<Scalars['BigInt']>>;
  decimals_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  decimalsSuccess?: InputMaybe<Scalars['Boolean']>;
  decimalsSuccess_not?: InputMaybe<Scalars['Boolean']>;
  decimalsSuccess_in?: InputMaybe<Array<Scalars['Boolean']>>;
  decimalsSuccess_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  rebase?: InputMaybe<Scalars['String']>;
  rebase_not?: InputMaybe<Scalars['String']>;
  rebase_gt?: InputMaybe<Scalars['String']>;
  rebase_lt?: InputMaybe<Scalars['String']>;
  rebase_gte?: InputMaybe<Scalars['String']>;
  rebase_lte?: InputMaybe<Scalars['String']>;
  rebase_in?: InputMaybe<Array<Scalars['String']>>;
  rebase_not_in?: InputMaybe<Array<Scalars['String']>>;
  rebase_contains?: InputMaybe<Scalars['String']>;
  rebase_contains_nocase?: InputMaybe<Scalars['String']>;
  rebase_not_contains?: InputMaybe<Scalars['String']>;
  rebase_not_contains_nocase?: InputMaybe<Scalars['String']>;
  rebase_starts_with?: InputMaybe<Scalars['String']>;
  rebase_starts_with_nocase?: InputMaybe<Scalars['String']>;
  rebase_not_starts_with?: InputMaybe<Scalars['String']>;
  rebase_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  rebase_ends_with?: InputMaybe<Scalars['String']>;
  rebase_ends_with_nocase?: InputMaybe<Scalars['String']>;
  rebase_not_ends_with?: InputMaybe<Scalars['String']>;
  rebase_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  rebase_?: InputMaybe<Rebase_filter>;
  createdAtBlock?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Token_filter>>>;
};

export type Token_orderBy =
  | 'id'
  | 'liquidityShares'
  | 'symbol'
  | 'symbolSuccess'
  | 'name'
  | 'nameSuccess'
  | 'decimals'
  | 'decimalsSuccess'
  | 'rebase'
  | 'rebase__id'
  | 'rebase__base'
  | 'rebase__elastic'
  | 'rebase__createdAtBlock'
  | 'createdAtBlock'
  | 'createdAtTimestamp';

export type Transaction = {
  id: Scalars['ID'];
  type: TransactionType;
  stream?: Maybe<Stream>;
  vesting?: Maybe<Vesting>;
  amount: Scalars['BigInt'];
  to: User;
  token: Token;
  toBentoBox: Scalars['Boolean'];
  txHash: Scalars['String'];
  createdAtBlock: Scalars['BigInt'];
  createdAtTimestamp: Scalars['BigInt'];
};

export type TransactionType =
  | 'DEPOSIT'
  | 'EXTEND'
  | 'WITHDRAWAL'
  | 'DISBURSEMENT';

export type Transaction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  type?: InputMaybe<TransactionType>;
  type_not?: InputMaybe<TransactionType>;
  type_in?: InputMaybe<Array<TransactionType>>;
  type_not_in?: InputMaybe<Array<TransactionType>>;
  stream?: InputMaybe<Scalars['String']>;
  stream_not?: InputMaybe<Scalars['String']>;
  stream_gt?: InputMaybe<Scalars['String']>;
  stream_lt?: InputMaybe<Scalars['String']>;
  stream_gte?: InputMaybe<Scalars['String']>;
  stream_lte?: InputMaybe<Scalars['String']>;
  stream_in?: InputMaybe<Array<Scalars['String']>>;
  stream_not_in?: InputMaybe<Array<Scalars['String']>>;
  stream_contains?: InputMaybe<Scalars['String']>;
  stream_contains_nocase?: InputMaybe<Scalars['String']>;
  stream_not_contains?: InputMaybe<Scalars['String']>;
  stream_not_contains_nocase?: InputMaybe<Scalars['String']>;
  stream_starts_with?: InputMaybe<Scalars['String']>;
  stream_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stream_not_starts_with?: InputMaybe<Scalars['String']>;
  stream_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stream_ends_with?: InputMaybe<Scalars['String']>;
  stream_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stream_not_ends_with?: InputMaybe<Scalars['String']>;
  stream_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  stream_?: InputMaybe<Stream_filter>;
  vesting?: InputMaybe<Scalars['String']>;
  vesting_not?: InputMaybe<Scalars['String']>;
  vesting_gt?: InputMaybe<Scalars['String']>;
  vesting_lt?: InputMaybe<Scalars['String']>;
  vesting_gte?: InputMaybe<Scalars['String']>;
  vesting_lte?: InputMaybe<Scalars['String']>;
  vesting_in?: InputMaybe<Array<Scalars['String']>>;
  vesting_not_in?: InputMaybe<Array<Scalars['String']>>;
  vesting_contains?: InputMaybe<Scalars['String']>;
  vesting_contains_nocase?: InputMaybe<Scalars['String']>;
  vesting_not_contains?: InputMaybe<Scalars['String']>;
  vesting_not_contains_nocase?: InputMaybe<Scalars['String']>;
  vesting_starts_with?: InputMaybe<Scalars['String']>;
  vesting_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vesting_not_starts_with?: InputMaybe<Scalars['String']>;
  vesting_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  vesting_ends_with?: InputMaybe<Scalars['String']>;
  vesting_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vesting_not_ends_with?: InputMaybe<Scalars['String']>;
  vesting_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  vesting_?: InputMaybe<Vesting_filter>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  to?: InputMaybe<Scalars['String']>;
  to_not?: InputMaybe<Scalars['String']>;
  to_gt?: InputMaybe<Scalars['String']>;
  to_lt?: InputMaybe<Scalars['String']>;
  to_gte?: InputMaybe<Scalars['String']>;
  to_lte?: InputMaybe<Scalars['String']>;
  to_in?: InputMaybe<Array<Scalars['String']>>;
  to_not_in?: InputMaybe<Array<Scalars['String']>>;
  to_contains?: InputMaybe<Scalars['String']>;
  to_contains_nocase?: InputMaybe<Scalars['String']>;
  to_not_contains?: InputMaybe<Scalars['String']>;
  to_not_contains_nocase?: InputMaybe<Scalars['String']>;
  to_starts_with?: InputMaybe<Scalars['String']>;
  to_starts_with_nocase?: InputMaybe<Scalars['String']>;
  to_not_starts_with?: InputMaybe<Scalars['String']>;
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  to_ends_with?: InputMaybe<Scalars['String']>;
  to_ends_with_nocase?: InputMaybe<Scalars['String']>;
  to_not_ends_with?: InputMaybe<Scalars['String']>;
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  to_?: InputMaybe<User_filter>;
  token?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<Token_filter>;
  toBentoBox?: InputMaybe<Scalars['Boolean']>;
  toBentoBox_not?: InputMaybe<Scalars['Boolean']>;
  toBentoBox_in?: InputMaybe<Array<Scalars['Boolean']>>;
  toBentoBox_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  txHash?: InputMaybe<Scalars['String']>;
  txHash_not?: InputMaybe<Scalars['String']>;
  txHash_gt?: InputMaybe<Scalars['String']>;
  txHash_lt?: InputMaybe<Scalars['String']>;
  txHash_gte?: InputMaybe<Scalars['String']>;
  txHash_lte?: InputMaybe<Scalars['String']>;
  txHash_in?: InputMaybe<Array<Scalars['String']>>;
  txHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  txHash_contains?: InputMaybe<Scalars['String']>;
  txHash_contains_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_contains?: InputMaybe<Scalars['String']>;
  txHash_not_contains_nocase?: InputMaybe<Scalars['String']>;
  txHash_starts_with?: InputMaybe<Scalars['String']>;
  txHash_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_starts_with?: InputMaybe<Scalars['String']>;
  txHash_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_ends_with?: InputMaybe<Scalars['String']>;
  txHash_ends_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_ends_with?: InputMaybe<Scalars['String']>;
  txHash_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  createdAtBlock?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Transaction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Transaction_filter>>>;
};

export type Transaction_orderBy =
  | 'id'
  | 'type'
  | 'stream'
  | 'stream__id'
  | 'stream__initialShares'
  | 'stream__initialAmount'
  | 'stream__extendedShares'
  | 'stream__initialSharesExtended'
  | 'stream__withdrawnAmountAfterExtension'
  | 'stream__remainingShares'
  | 'stream__withdrawnAmount'
  | 'stream__status'
  | 'stream__fromBentoBox'
  | 'stream__startedAt'
  | 'stream__expiresAt'
  | 'stream__txHash'
  | 'stream__transactionCount'
  | 'stream__createdAtBlock'
  | 'stream__createdAtTimestamp'
  | 'stream__extendedAtBlock'
  | 'stream__extendedAtTimestamp'
  | 'stream__modifiedAtBlock'
  | 'stream__modifiedAtTimestamp'
  | 'vesting'
  | 'vesting__id'
  | 'vesting__cliffDuration'
  | 'vesting__stepDuration'
  | 'vesting__steps'
  | 'vesting__cliffShares'
  | 'vesting__stepShares'
  | 'vesting__remainingShares'
  | 'vesting__initialShares'
  | 'vesting__initialAmount'
  | 'vesting__withdrawnAmount'
  | 'vesting__status'
  | 'vesting__fromBentoBox'
  | 'vesting__startedAt'
  | 'vesting__expiresAt'
  | 'vesting__txHash'
  | 'vesting__transactionCount'
  | 'vesting__createdAtBlock'
  | 'vesting__createdAtTimestamp'
  | 'vesting__modifiedAtBlock'
  | 'vesting__modifiedAtTimestamp'
  | 'vesting__cancelledAtBlock'
  | 'vesting__cancelledAtTimestamp'
  | 'amount'
  | 'to'
  | 'to__id'
  | 'to__createdAtBlock'
  | 'to__createdAtTimestamp'
  | 'token'
  | 'token__id'
  | 'token__liquidityShares'
  | 'token__symbol'
  | 'token__symbolSuccess'
  | 'token__name'
  | 'token__nameSuccess'
  | 'token__decimals'
  | 'token__decimalsSuccess'
  | 'token__createdAtBlock'
  | 'token__createdAtTimestamp'
  | 'toBentoBox'
  | 'txHash'
  | 'createdAtBlock'
  | 'createdAtTimestamp';

export type User = {
  id: Scalars['ID'];
  incomingStreams: Array<Stream>;
  outgoingStreams: Array<Stream>;
  incomingVestings: Array<Vesting>;
  outgoingVestings: Array<Vesting>;
  createdAtBlock: Scalars['BigInt'];
  createdAtTimestamp: Scalars['BigInt'];
};


export type UserincomingStreamsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stream_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_filter>;
};


export type UseroutgoingStreamsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stream_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_filter>;
};


export type UserincomingVestingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Vesting_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Vesting_filter>;
};


export type UseroutgoingVestingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Vesting_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Vesting_filter>;
};

export type User_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  incomingStreams_?: InputMaybe<Stream_filter>;
  outgoingStreams_?: InputMaybe<Stream_filter>;
  incomingVestings_?: InputMaybe<Vesting_filter>;
  outgoingVestings_?: InputMaybe<Vesting_filter>;
  createdAtBlock?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<User_filter>>>;
  or?: InputMaybe<Array<InputMaybe<User_filter>>>;
};

export type User_orderBy =
  | 'id'
  | 'incomingStreams'
  | 'outgoingStreams'
  | 'incomingVestings'
  | 'outgoingVestings'
  | 'createdAtBlock'
  | 'createdAtTimestamp';

export type Vesting = {
  id: Scalars['ID'];
  recipient: User;
  cliffDuration: Scalars['BigInt'];
  stepDuration: Scalars['BigInt'];
  steps: Scalars['BigInt'];
  cliffShares: Scalars['BigInt'];
  stepShares: Scalars['BigInt'];
  /**  shares remaining, decreases on withdrawal  */
  remainingShares: Scalars['BigInt'];
  /**  Initial shares  */
  initialShares: Scalars['BigInt'];
  /**  Initial amount  */
  initialAmount: Scalars['BigInt'];
  /**  Withdrawn amount  */
  withdrawnAmount: Scalars['BigInt'];
  token: Token;
  status: FuroStatus;
  createdBy: User;
  fromBentoBox: Scalars['Boolean'];
  startedAt: Scalars['BigInt'];
  expiresAt: Scalars['BigInt'];
  txHash: Scalars['String'];
  transactionCount: Scalars['BigInt'];
  createdAtBlock: Scalars['BigInt'];
  createdAtTimestamp: Scalars['BigInt'];
  modifiedAtBlock: Scalars['BigInt'];
  modifiedAtTimestamp: Scalars['BigInt'];
  cancelledAtBlock?: Maybe<Scalars['BigInt']>;
  cancelledAtTimestamp?: Maybe<Scalars['BigInt']>;
};

export type Vesting_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  recipient?: InputMaybe<Scalars['String']>;
  recipient_not?: InputMaybe<Scalars['String']>;
  recipient_gt?: InputMaybe<Scalars['String']>;
  recipient_lt?: InputMaybe<Scalars['String']>;
  recipient_gte?: InputMaybe<Scalars['String']>;
  recipient_lte?: InputMaybe<Scalars['String']>;
  recipient_in?: InputMaybe<Array<Scalars['String']>>;
  recipient_not_in?: InputMaybe<Array<Scalars['String']>>;
  recipient_contains?: InputMaybe<Scalars['String']>;
  recipient_contains_nocase?: InputMaybe<Scalars['String']>;
  recipient_not_contains?: InputMaybe<Scalars['String']>;
  recipient_not_contains_nocase?: InputMaybe<Scalars['String']>;
  recipient_starts_with?: InputMaybe<Scalars['String']>;
  recipient_starts_with_nocase?: InputMaybe<Scalars['String']>;
  recipient_not_starts_with?: InputMaybe<Scalars['String']>;
  recipient_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  recipient_ends_with?: InputMaybe<Scalars['String']>;
  recipient_ends_with_nocase?: InputMaybe<Scalars['String']>;
  recipient_not_ends_with?: InputMaybe<Scalars['String']>;
  recipient_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  recipient_?: InputMaybe<User_filter>;
  cliffDuration?: InputMaybe<Scalars['BigInt']>;
  cliffDuration_not?: InputMaybe<Scalars['BigInt']>;
  cliffDuration_gt?: InputMaybe<Scalars['BigInt']>;
  cliffDuration_lt?: InputMaybe<Scalars['BigInt']>;
  cliffDuration_gte?: InputMaybe<Scalars['BigInt']>;
  cliffDuration_lte?: InputMaybe<Scalars['BigInt']>;
  cliffDuration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cliffDuration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stepDuration?: InputMaybe<Scalars['BigInt']>;
  stepDuration_not?: InputMaybe<Scalars['BigInt']>;
  stepDuration_gt?: InputMaybe<Scalars['BigInt']>;
  stepDuration_lt?: InputMaybe<Scalars['BigInt']>;
  stepDuration_gte?: InputMaybe<Scalars['BigInt']>;
  stepDuration_lte?: InputMaybe<Scalars['BigInt']>;
  stepDuration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stepDuration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  steps?: InputMaybe<Scalars['BigInt']>;
  steps_not?: InputMaybe<Scalars['BigInt']>;
  steps_gt?: InputMaybe<Scalars['BigInt']>;
  steps_lt?: InputMaybe<Scalars['BigInt']>;
  steps_gte?: InputMaybe<Scalars['BigInt']>;
  steps_lte?: InputMaybe<Scalars['BigInt']>;
  steps_in?: InputMaybe<Array<Scalars['BigInt']>>;
  steps_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cliffShares?: InputMaybe<Scalars['BigInt']>;
  cliffShares_not?: InputMaybe<Scalars['BigInt']>;
  cliffShares_gt?: InputMaybe<Scalars['BigInt']>;
  cliffShares_lt?: InputMaybe<Scalars['BigInt']>;
  cliffShares_gte?: InputMaybe<Scalars['BigInt']>;
  cliffShares_lte?: InputMaybe<Scalars['BigInt']>;
  cliffShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cliffShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stepShares?: InputMaybe<Scalars['BigInt']>;
  stepShares_not?: InputMaybe<Scalars['BigInt']>;
  stepShares_gt?: InputMaybe<Scalars['BigInt']>;
  stepShares_lt?: InputMaybe<Scalars['BigInt']>;
  stepShares_gte?: InputMaybe<Scalars['BigInt']>;
  stepShares_lte?: InputMaybe<Scalars['BigInt']>;
  stepShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stepShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  remainingShares?: InputMaybe<Scalars['BigInt']>;
  remainingShares_not?: InputMaybe<Scalars['BigInt']>;
  remainingShares_gt?: InputMaybe<Scalars['BigInt']>;
  remainingShares_lt?: InputMaybe<Scalars['BigInt']>;
  remainingShares_gte?: InputMaybe<Scalars['BigInt']>;
  remainingShares_lte?: InputMaybe<Scalars['BigInt']>;
  remainingShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  remainingShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialShares?: InputMaybe<Scalars['BigInt']>;
  initialShares_not?: InputMaybe<Scalars['BigInt']>;
  initialShares_gt?: InputMaybe<Scalars['BigInt']>;
  initialShares_lt?: InputMaybe<Scalars['BigInt']>;
  initialShares_gte?: InputMaybe<Scalars['BigInt']>;
  initialShares_lte?: InputMaybe<Scalars['BigInt']>;
  initialShares_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialShares_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialAmount?: InputMaybe<Scalars['BigInt']>;
  initialAmount_not?: InputMaybe<Scalars['BigInt']>;
  initialAmount_gt?: InputMaybe<Scalars['BigInt']>;
  initialAmount_lt?: InputMaybe<Scalars['BigInt']>;
  initialAmount_gte?: InputMaybe<Scalars['BigInt']>;
  initialAmount_lte?: InputMaybe<Scalars['BigInt']>;
  initialAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  initialAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  withdrawnAmount?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_not?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_gt?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_lt?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_gte?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_lte?: InputMaybe<Scalars['BigInt']>;
  withdrawnAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  withdrawnAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  token?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<Token_filter>;
  status?: InputMaybe<FuroStatus>;
  status_not?: InputMaybe<FuroStatus>;
  status_in?: InputMaybe<Array<FuroStatus>>;
  status_not_in?: InputMaybe<Array<FuroStatus>>;
  createdBy?: InputMaybe<Scalars['String']>;
  createdBy_not?: InputMaybe<Scalars['String']>;
  createdBy_gt?: InputMaybe<Scalars['String']>;
  createdBy_lt?: InputMaybe<Scalars['String']>;
  createdBy_gte?: InputMaybe<Scalars['String']>;
  createdBy_lte?: InputMaybe<Scalars['String']>;
  createdBy_in?: InputMaybe<Array<Scalars['String']>>;
  createdBy_not_in?: InputMaybe<Array<Scalars['String']>>;
  createdBy_contains?: InputMaybe<Scalars['String']>;
  createdBy_contains_nocase?: InputMaybe<Scalars['String']>;
  createdBy_not_contains?: InputMaybe<Scalars['String']>;
  createdBy_not_contains_nocase?: InputMaybe<Scalars['String']>;
  createdBy_starts_with?: InputMaybe<Scalars['String']>;
  createdBy_starts_with_nocase?: InputMaybe<Scalars['String']>;
  createdBy_not_starts_with?: InputMaybe<Scalars['String']>;
  createdBy_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  createdBy_ends_with?: InputMaybe<Scalars['String']>;
  createdBy_ends_with_nocase?: InputMaybe<Scalars['String']>;
  createdBy_not_ends_with?: InputMaybe<Scalars['String']>;
  createdBy_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  createdBy_?: InputMaybe<User_filter>;
  fromBentoBox?: InputMaybe<Scalars['Boolean']>;
  fromBentoBox_not?: InputMaybe<Scalars['Boolean']>;
  fromBentoBox_in?: InputMaybe<Array<Scalars['Boolean']>>;
  fromBentoBox_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  startedAt?: InputMaybe<Scalars['BigInt']>;
  startedAt_not?: InputMaybe<Scalars['BigInt']>;
  startedAt_gt?: InputMaybe<Scalars['BigInt']>;
  startedAt_lt?: InputMaybe<Scalars['BigInt']>;
  startedAt_gte?: InputMaybe<Scalars['BigInt']>;
  startedAt_lte?: InputMaybe<Scalars['BigInt']>;
  startedAt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startedAt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiresAt?: InputMaybe<Scalars['BigInt']>;
  expiresAt_not?: InputMaybe<Scalars['BigInt']>;
  expiresAt_gt?: InputMaybe<Scalars['BigInt']>;
  expiresAt_lt?: InputMaybe<Scalars['BigInt']>;
  expiresAt_gte?: InputMaybe<Scalars['BigInt']>;
  expiresAt_lte?: InputMaybe<Scalars['BigInt']>;
  expiresAt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiresAt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txHash?: InputMaybe<Scalars['String']>;
  txHash_not?: InputMaybe<Scalars['String']>;
  txHash_gt?: InputMaybe<Scalars['String']>;
  txHash_lt?: InputMaybe<Scalars['String']>;
  txHash_gte?: InputMaybe<Scalars['String']>;
  txHash_lte?: InputMaybe<Scalars['String']>;
  txHash_in?: InputMaybe<Array<Scalars['String']>>;
  txHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  txHash_contains?: InputMaybe<Scalars['String']>;
  txHash_contains_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_contains?: InputMaybe<Scalars['String']>;
  txHash_not_contains_nocase?: InputMaybe<Scalars['String']>;
  txHash_starts_with?: InputMaybe<Scalars['String']>;
  txHash_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_starts_with?: InputMaybe<Scalars['String']>;
  txHash_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_ends_with?: InputMaybe<Scalars['String']>;
  txHash_ends_with_nocase?: InputMaybe<Scalars['String']>;
  txHash_not_ends_with?: InputMaybe<Scalars['String']>;
  txHash_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transactionCount?: InputMaybe<Scalars['BigInt']>;
  transactionCount_not?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtBlock?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  modifiedAtBlock?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  modifiedAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  modifiedAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  modifiedAtTimestamp?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  modifiedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  modifiedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cancelledAtBlock?: InputMaybe<Scalars['BigInt']>;
  cancelledAtBlock_not?: InputMaybe<Scalars['BigInt']>;
  cancelledAtBlock_gt?: InputMaybe<Scalars['BigInt']>;
  cancelledAtBlock_lt?: InputMaybe<Scalars['BigInt']>;
  cancelledAtBlock_gte?: InputMaybe<Scalars['BigInt']>;
  cancelledAtBlock_lte?: InputMaybe<Scalars['BigInt']>;
  cancelledAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cancelledAtBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cancelledAtTimestamp?: InputMaybe<Scalars['BigInt']>;
  cancelledAtTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  cancelledAtTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  cancelledAtTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  cancelledAtTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  cancelledAtTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  cancelledAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cancelledAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Vesting_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Vesting_filter>>>;
};

export type Vesting_orderBy =
  | 'id'
  | 'recipient'
  | 'recipient__id'
  | 'recipient__createdAtBlock'
  | 'recipient__createdAtTimestamp'
  | 'cliffDuration'
  | 'stepDuration'
  | 'steps'
  | 'cliffShares'
  | 'stepShares'
  | 'remainingShares'
  | 'initialShares'
  | 'initialAmount'
  | 'withdrawnAmount'
  | 'token'
  | 'token__id'
  | 'token__liquidityShares'
  | 'token__symbol'
  | 'token__symbolSuccess'
  | 'token__name'
  | 'token__nameSuccess'
  | 'token__decimals'
  | 'token__decimalsSuccess'
  | 'token__createdAtBlock'
  | 'token__createdAtTimestamp'
  | 'status'
  | 'createdBy'
  | 'createdBy__id'
  | 'createdBy__createdAtBlock'
  | 'createdBy__createdAtTimestamp'
  | 'fromBentoBox'
  | 'startedAt'
  | 'expiresAt'
  | 'txHash'
  | 'transactionCount'
  | 'createdAtBlock'
  | 'createdAtTimestamp'
  | 'modifiedAtBlock'
  | 'modifiedAtTimestamp'
  | 'cancelledAtBlock'
  | 'cancelledAtTimestamp';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Aggregation_interval: Aggregation_interval;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FuroStatus: FuroStatus;
  Global: ResolverTypeWrapper<Global>;
  Global_filter: Global_filter;
  Global_orderBy: Global_orderBy;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int8: ResolverTypeWrapper<Scalars['Int8']>;
  OrderDirection: OrderDirection;
  Query: ResolverTypeWrapper<{}>;
  Rebase: ResolverTypeWrapper<Rebase>;
  Rebase_filter: Rebase_filter;
  Rebase_orderBy: Rebase_orderBy;
  Stream: ResolverTypeWrapper<Stream>;
  Stream_filter: Stream_filter;
  Stream_orderBy: Stream_orderBy;
  String: ResolverTypeWrapper<Scalars['String']>;
  Subscription: ResolverTypeWrapper<{}>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']>;
  Token: ResolverTypeWrapper<Token>;
  TokenDaySnapshot: ResolverTypeWrapper<TokenDaySnapshot>;
  TokenDaySnapshot_filter: TokenDaySnapshot_filter;
  TokenDaySnapshot_orderBy: TokenDaySnapshot_orderBy;
  TokenHourSnapshot: ResolverTypeWrapper<TokenHourSnapshot>;
  TokenHourSnapshot_filter: TokenHourSnapshot_filter;
  TokenHourSnapshot_orderBy: TokenHourSnapshot_orderBy;
  Token_filter: Token_filter;
  Token_orderBy: Token_orderBy;
  Transaction: ResolverTypeWrapper<Transaction>;
  TransactionType: TransactionType;
  Transaction_filter: Transaction_filter;
  Transaction_orderBy: Transaction_orderBy;
  User: ResolverTypeWrapper<User>;
  User_filter: User_filter;
  User_orderBy: User_orderBy;
  Vesting: ResolverTypeWrapper<Vesting>;
  Vesting_filter: Vesting_filter;
  Vesting_orderBy: Vesting_orderBy;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  BigDecimal: Scalars['BigDecimal'];
  BigInt: Scalars['BigInt'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: Scalars['Boolean'];
  Bytes: Scalars['Bytes'];
  Float: Scalars['Float'];
  Global: Global;
  Global_filter: Global_filter;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Int8: Scalars['Int8'];
  Query: {};
  Rebase: Rebase;
  Rebase_filter: Rebase_filter;
  Stream: Stream;
  Stream_filter: Stream_filter;
  String: Scalars['String'];
  Subscription: {};
  Timestamp: Scalars['Timestamp'];
  Token: Token;
  TokenDaySnapshot: TokenDaySnapshot;
  TokenDaySnapshot_filter: TokenDaySnapshot_filter;
  TokenHourSnapshot: TokenHourSnapshot;
  TokenHourSnapshot_filter: TokenHourSnapshot_filter;
  Token_filter: Token_filter;
  Transaction: Transaction;
  Transaction_filter: Transaction_filter;
  User: User;
  User_filter: User_filter;
  Vesting: Vesting;
  Vesting_filter: Vesting_filter;
  _Block_: _Block_;
  _Meta_: _Meta_;
}>;

export type entityDirectiveArgs = { };

export type entityDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = entityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type subgraphIdDirectiveArgs = {
  id: Scalars['String'];
};

export type subgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = subgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type derivedFromDirectiveArgs = {
  field: Scalars['String'];
};

export type derivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = derivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type GlobalResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Global'] = ResolversParentTypes['Global']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  vestingCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  streamCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Int8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int8'], any> {
  name: 'Int8';
}

export type QueryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  global?: Resolver<Maybe<ResolversTypes['Global']>, ParentType, ContextType, RequireFields<QueryglobalArgs, 'id' | 'subgraphError'>>;
  globals?: Resolver<Array<ResolversTypes['Global']>, ParentType, ContextType, RequireFields<QueryglobalsArgs, 'skip' | 'first' | 'subgraphError'>>;
  stream?: Resolver<Maybe<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<QuerystreamArgs, 'id' | 'subgraphError'>>;
  streams?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<QuerystreamsArgs, 'skip' | 'first' | 'subgraphError'>>;
  vesting?: Resolver<Maybe<ResolversTypes['Vesting']>, ParentType, ContextType, RequireFields<QueryvestingArgs, 'id' | 'subgraphError'>>;
  vestings?: Resolver<Array<ResolversTypes['Vesting']>, ParentType, ContextType, RequireFields<QueryvestingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  transaction?: Resolver<Maybe<ResolversTypes['Transaction']>, ParentType, ContextType, RequireFields<QuerytransactionArgs, 'id' | 'subgraphError'>>;
  transactions?: Resolver<Array<ResolversTypes['Transaction']>, ParentType, ContextType, RequireFields<QuerytransactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  token?: Resolver<Maybe<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<QuerytokenArgs, 'id' | 'subgraphError'>>;
  tokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<QuerytokensArgs, 'skip' | 'first' | 'subgraphError'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryuserArgs, 'id' | 'subgraphError'>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  rebase?: Resolver<Maybe<ResolversTypes['Rebase']>, ParentType, ContextType, RequireFields<QueryrebaseArgs, 'id' | 'subgraphError'>>;
  rebases?: Resolver<Array<ResolversTypes['Rebase']>, ParentType, ContextType, RequireFields<QueryrebasesArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenHourSnapshot?: Resolver<Maybe<ResolversTypes['TokenHourSnapshot']>, ParentType, ContextType, RequireFields<QuerytokenHourSnapshotArgs, 'id' | 'subgraphError'>>;
  tokenHourSnapshots?: Resolver<Array<ResolversTypes['TokenHourSnapshot']>, ParentType, ContextType, RequireFields<QuerytokenHourSnapshotsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenDaySnapshot?: Resolver<Maybe<ResolversTypes['TokenDaySnapshot']>, ParentType, ContextType, RequireFields<QuerytokenDaySnapshotArgs, 'id' | 'subgraphError'>>;
  tokenDaySnapshots?: Resolver<Array<ResolversTypes['TokenDaySnapshot']>, ParentType, ContextType, RequireFields<QuerytokenDaySnapshotsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>;
}>;

export type RebaseResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Rebase'] = ResolversParentTypes['Rebase']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  base?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  elastic?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StreamResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Stream'] = ResolversParentTypes['Stream']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  initialShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  initialAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  extendedShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  initialSharesExtended?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  withdrawnAmountAfterExtension?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  remainingShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  withdrawnAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['FuroStatus'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  fromBentoBox?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  txHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  extendedAtBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  extendedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  modifiedAtBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  modifiedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  global?: SubscriptionResolver<Maybe<ResolversTypes['Global']>, "global", ParentType, ContextType, RequireFields<SubscriptionglobalArgs, 'id' | 'subgraphError'>>;
  globals?: SubscriptionResolver<Array<ResolversTypes['Global']>, "globals", ParentType, ContextType, RequireFields<SubscriptionglobalsArgs, 'skip' | 'first' | 'subgraphError'>>;
  stream?: SubscriptionResolver<Maybe<ResolversTypes['Stream']>, "stream", ParentType, ContextType, RequireFields<SubscriptionstreamArgs, 'id' | 'subgraphError'>>;
  streams?: SubscriptionResolver<Array<ResolversTypes['Stream']>, "streams", ParentType, ContextType, RequireFields<SubscriptionstreamsArgs, 'skip' | 'first' | 'subgraphError'>>;
  vesting?: SubscriptionResolver<Maybe<ResolversTypes['Vesting']>, "vesting", ParentType, ContextType, RequireFields<SubscriptionvestingArgs, 'id' | 'subgraphError'>>;
  vestings?: SubscriptionResolver<Array<ResolversTypes['Vesting']>, "vestings", ParentType, ContextType, RequireFields<SubscriptionvestingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  transaction?: SubscriptionResolver<Maybe<ResolversTypes['Transaction']>, "transaction", ParentType, ContextType, RequireFields<SubscriptiontransactionArgs, 'id' | 'subgraphError'>>;
  transactions?: SubscriptionResolver<Array<ResolversTypes['Transaction']>, "transactions", ParentType, ContextType, RequireFields<SubscriptiontransactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  token?: SubscriptionResolver<Maybe<ResolversTypes['Token']>, "token", ParentType, ContextType, RequireFields<SubscriptiontokenArgs, 'id' | 'subgraphError'>>;
  tokens?: SubscriptionResolver<Array<ResolversTypes['Token']>, "tokens", ParentType, ContextType, RequireFields<SubscriptiontokensArgs, 'skip' | 'first' | 'subgraphError'>>;
  user?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "user", ParentType, ContextType, RequireFields<SubscriptionuserArgs, 'id' | 'subgraphError'>>;
  users?: SubscriptionResolver<Array<ResolversTypes['User']>, "users", ParentType, ContextType, RequireFields<SubscriptionusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  rebase?: SubscriptionResolver<Maybe<ResolversTypes['Rebase']>, "rebase", ParentType, ContextType, RequireFields<SubscriptionrebaseArgs, 'id' | 'subgraphError'>>;
  rebases?: SubscriptionResolver<Array<ResolversTypes['Rebase']>, "rebases", ParentType, ContextType, RequireFields<SubscriptionrebasesArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenHourSnapshot?: SubscriptionResolver<Maybe<ResolversTypes['TokenHourSnapshot']>, "tokenHourSnapshot", ParentType, ContextType, RequireFields<SubscriptiontokenHourSnapshotArgs, 'id' | 'subgraphError'>>;
  tokenHourSnapshots?: SubscriptionResolver<Array<ResolversTypes['TokenHourSnapshot']>, "tokenHourSnapshots", ParentType, ContextType, RequireFields<SubscriptiontokenHourSnapshotsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenDaySnapshot?: SubscriptionResolver<Maybe<ResolversTypes['TokenDaySnapshot']>, "tokenDaySnapshot", ParentType, ContextType, RequireFields<SubscriptiontokenDaySnapshotArgs, 'id' | 'subgraphError'>>;
  tokenDaySnapshots?: SubscriptionResolver<Array<ResolversTypes['TokenDaySnapshot']>, "tokenDaySnapshots", ParentType, ContextType, RequireFields<SubscriptiontokenDaySnapshotsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_metaArgs>>;
}>;

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export type TokenResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Token'] = ResolversParentTypes['Token']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liquidityShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbolSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  decimalsSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rebase?: Resolver<ResolversTypes['Rebase'], ParentType, ContextType>;
  createdAtBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenDaySnapshotResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TokenDaySnapshot'] = ResolversParentTypes['TokenDaySnapshot']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  cumulativeLiquidityShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  cumulativeLiquidityAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenHourSnapshotResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TokenHourSnapshot'] = ResolversParentTypes['TokenHourSnapshot']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  cumulativeLiquidityShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  cumulativeLiquidityAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransactionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TransactionType'], ParentType, ContextType>;
  stream?: Resolver<Maybe<ResolversTypes['Stream']>, ParentType, ContextType>;
  vesting?: Resolver<Maybe<ResolversTypes['Vesting']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  toBentoBox?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  txHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAtBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  incomingStreams?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<UserincomingStreamsArgs, 'skip' | 'first'>>;
  outgoingStreams?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<UseroutgoingStreamsArgs, 'skip' | 'first'>>;
  incomingVestings?: Resolver<Array<ResolversTypes['Vesting']>, ParentType, ContextType, RequireFields<UserincomingVestingsArgs, 'skip' | 'first'>>;
  outgoingVestings?: Resolver<Array<ResolversTypes['Vesting']>, ParentType, ContextType, RequireFields<UseroutgoingVestingsArgs, 'skip' | 'first'>>;
  createdAtBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VestingResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Vesting'] = ResolversParentTypes['Vesting']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  cliffDuration?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  stepDuration?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  steps?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  cliffShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  stepShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  remainingShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  initialShares?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  initialAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  withdrawnAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['FuroStatus'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  fromBentoBox?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  txHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  modifiedAtBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  modifiedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  cancelledAtBlock?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  cancelledAtTimestamp?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Block_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentHash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  Bytes?: GraphQLScalarType;
  Global?: GlobalResolvers<ContextType>;
  Int8?: GraphQLScalarType;
  Query?: QueryResolvers<ContextType>;
  Rebase?: RebaseResolvers<ContextType>;
  Stream?: StreamResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Timestamp?: GraphQLScalarType;
  Token?: TokenResolvers<ContextType>;
  TokenDaySnapshot?: TokenDaySnapshotResolvers<ContextType>;
  TokenHourSnapshot?: TokenHourSnapshotResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  Vesting?: VestingResolvers<ContextType>;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>;
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = FuroStreamTypes.Context & BaseMeshContext;


const baseDir = pathModule.join(typeof __dirname === 'string' ? __dirname : '/', '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/FuroStream/introspectionSchema":
      return Promise.resolve(importedModule$0) as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const furoStreamTransforms = [];
const additionalTypeDefs = [] as any[];
const furoStreamHandler = new GraphqlHandler({
              name: "FuroStream",
              config: {"endpoint":"https://{context.url:api.studio.thegraph.com/query/32073/furo-ethereum/v0.0.1}"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("FuroStream"),
              logger: logger.child("FuroStream"),
              importFn,
            });
furoStreamTransforms[0] = new AutoPaginationTransform({
                  apiName: "FuroStream",
                  config: {"validateSchema":true},
                  baseDir,
                  cache,
                  pubsub,
                  importFn,
                  logger,
                });
sources[0] = {
          name: 'FuroStream',
          handler: furoStreamHandler,
          transforms: furoStreamTransforms
        }
const additionalResolvers = [] as any[]
const merger = new(BareMerger as any)({
        cache,
        pubsub,
        logger: logger.child('bareMerger'),
        store: rootStore.child('bareMerger')
      })

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: UserVestingsDocument,
        get rawSDL() {
          return printWithCache(UserVestingsDocument);
        },
        location: 'UserVestingsDocument.graphql'
      },{
        document: VestingIdsDocument,
        get rawSDL() {
          return printWithCache(VestingIdsDocument);
        },
        location: 'VestingIdsDocument.graphql'
      },{
        document: VestingDocument,
        get rawSDL() {
          return printWithCache(VestingDocument);
        },
        location: 'VestingDocument.graphql'
      },{
        document: VestingTransactionsDocument,
        get rawSDL() {
          return printWithCache(VestingTransactionsDocument);
        },
        location: 'VestingTransactionsDocument.graphql'
      },{
        document: UserStreamsDocument,
        get rawSDL() {
          return printWithCache(UserStreamsDocument);
        },
        location: 'UserStreamsDocument.graphql'
      },{
        document: StreamIdsDocument,
        get rawSDL() {
          return printWithCache(StreamIdsDocument);
        },
        location: 'StreamIdsDocument.graphql'
      },{
        document: StreamDocument,
        get rawSDL() {
          return printWithCache(StreamDocument);
        },
        location: 'StreamDocument.graphql'
      },{
        document: StreamTransactionsDocument,
        get rawSDL() {
          return printWithCache(StreamTransactionsDocument);
        },
        location: 'StreamTransactionsDocument.graphql'
      },{
        document: BentoBoxRebaseDocument,
        get rawSDL() {
          return printWithCache(BentoBoxRebaseDocument);
        },
        location: 'BentoBoxRebaseDocument.graphql'
      },{
        document: BentoBoxRebasesDocument,
        get rawSDL() {
          return printWithCache(BentoBoxRebasesDocument);
        },
        location: 'BentoBoxRebasesDocument.graphql'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler<TServerContext = {}>(): MeshHTTPHandler<TServerContext> {
  return createMeshHTTPHandler<TServerContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type userVestingsQueryVariables = Exact<{
  block?: InputMaybe<Block_height>;
  id: Scalars['String'];
}>;


export type userVestingsQuery = { incomingVestings: Array<(
    { __typename: 'Vesting' }
    & Pick<Vesting, 'id' | 'status' | 'steps' | 'startedAt' | 'expiresAt' | 'modifiedAtTimestamp' | 'cliffDuration' | 'stepDuration' | 'stepShares' | 'cliffShares' | 'initialAmount' | 'initialShares' | 'remainingShares' | 'withdrawnAmount' | 'fromBentoBox'>
    & { token: Pick<Token, 'id' | 'name' | 'symbol' | 'decimals'>, recipient: Pick<User, 'id'>, createdBy: Pick<User, 'id'> }
  )>, outgoingVestings: Array<(
    { __typename: 'Vesting' }
    & Pick<Vesting, 'id' | 'status' | 'steps' | 'startedAt' | 'expiresAt' | 'modifiedAtTimestamp' | 'cliffDuration' | 'stepDuration' | 'stepShares' | 'cliffShares' | 'initialAmount' | 'initialShares' | 'remainingShares' | 'withdrawnAmount' | 'fromBentoBox'>
    & { token: Pick<Token, 'id' | 'name' | 'symbol' | 'decimals'>, recipient: Pick<User, 'id'>, createdBy: Pick<User, 'id'> }
  )> };

export type vestingIdsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Vesting_filter>;
  block?: InputMaybe<Block_height>;
}>;


export type vestingIdsQuery = { vestings: Array<Pick<Vesting, 'id'>> };

export type vestingQueryVariables = Exact<{
  block?: InputMaybe<Block_height>;
  id: Scalars['ID'];
}>;


export type vestingQuery = { vesting?: Maybe<(
    { __typename: 'Vesting' }
    & Pick<Vesting, 'id' | 'status' | 'steps' | 'startedAt' | 'expiresAt' | 'modifiedAtTimestamp' | 'cliffDuration' | 'stepDuration' | 'stepShares' | 'cliffShares' | 'initialShares' | 'initialAmount' | 'remainingShares' | 'withdrawnAmount' | 'fromBentoBox' | 'txHash'>
    & { token: Pick<Token, 'id' | 'name' | 'symbol' | 'decimals'>, recipient: Pick<User, 'id'>, createdBy: Pick<User, 'id'> }
  )> };

export type vestingTransactionsQueryVariables = Exact<{
  block?: InputMaybe<Block_height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Transaction_filter>;
}>;


export type vestingTransactionsQuery = { transactions: Array<(
    Pick<Transaction, 'id' | 'type' | 'amount' | 'toBentoBox' | 'createdAtBlock' | 'createdAtTimestamp' | 'txHash'>
    & { token: Pick<Token, 'id' | 'name' | 'symbol' | 'decimals'>, to: Pick<User, 'id'> }
  )> };

export type userStreamsQueryVariables = Exact<{
  block?: InputMaybe<Block_height>;
  id: Scalars['String'];
}>;


export type userStreamsQuery = { incomingStreams: Array<(
    { __typename: 'Stream' }
    & Pick<Stream, 'id' | 'status' | 'startedAt' | 'expiresAt' | 'extendedAtTimestamp' | 'modifiedAtTimestamp' | 'initialShares' | 'initialAmount' | 'extendedShares' | 'initialSharesExtended' | 'remainingShares' | 'withdrawnAmount' | 'withdrawnAmountAfterExtension'>
    & { recipient: Pick<User, 'id'>, createdBy: Pick<User, 'id'>, token: Pick<Token, 'id' | 'symbol' | 'name' | 'decimals'> }
  )>, outgoingStreams: Array<(
    { __typename: 'Stream' }
    & Pick<Stream, 'id' | 'status' | 'startedAt' | 'expiresAt' | 'extendedAtTimestamp' | 'modifiedAtTimestamp' | 'initialShares' | 'initialAmount' | 'extendedShares' | 'initialSharesExtended' | 'remainingShares' | 'withdrawnAmount' | 'withdrawnAmountAfterExtension'>
    & { recipient: Pick<User, 'id'>, createdBy: Pick<User, 'id'>, token: Pick<Token, 'id' | 'symbol' | 'name' | 'decimals'> }
  )> };

export type streamIdsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Stream_filter>;
  block?: InputMaybe<Block_height>;
}>;


export type streamIdsQuery = { streams: Array<Pick<Stream, 'id'>> };

export type streamQueryVariables = Exact<{
  block?: InputMaybe<Block_height>;
  id: Scalars['ID'];
}>;


export type streamQuery = { stream?: Maybe<(
    { __typename: 'Stream' }
    & Pick<Stream, 'id' | 'status' | 'startedAt' | 'expiresAt' | 'extendedAtTimestamp' | 'modifiedAtTimestamp' | 'initialShares' | 'initialAmount' | 'extendedShares' | 'initialSharesExtended' | 'remainingShares' | 'withdrawnAmount' | 'withdrawnAmountAfterExtension' | 'txHash'>
    & { recipient: Pick<User, 'id'>, createdBy: Pick<User, 'id'>, token: Pick<Token, 'id' | 'symbol' | 'name' | 'decimals'> }
  )> };

export type streamTransactionsQueryVariables = Exact<{
  block?: InputMaybe<Block_height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Transaction_filter>;
}>;


export type streamTransactionsQuery = { transactions: Array<(
    Pick<Transaction, 'id' | 'type' | 'amount' | 'toBentoBox' | 'createdAtBlock' | 'createdAtTimestamp' | 'txHash'>
    & { token: Pick<Token, 'id' | 'name' | 'symbol' | 'decimals'>, to: Pick<User, 'id'> }
  )> };

export type bentoBoxRebaseQueryVariables = Exact<{
  block?: InputMaybe<Block_height>;
  id: Scalars['ID'];
}>;


export type bentoBoxRebaseQuery = { rebase?: Maybe<Pick<Rebase, 'id' | 'base' | 'elastic'>> };

export type bentoBoxRebasesQueryVariables = Exact<{
  block?: InputMaybe<Block_height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Rebase_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Rebase_filter>;
}>;


export type bentoBoxRebasesQuery = { rebases: Array<Pick<Rebase, 'id' | 'base' | 'elastic'>> };


export const userVestingsDocument = gql`
    query userVestings($block: Block_height, $id: String!) {
  incomingVestings: vestings(
    where: {recipient: $id}
    orderBy: startedAt
    block: $block
  ) {
    id
    __typename
    status
    steps
    startedAt
    expiresAt
    modifiedAtTimestamp
    cliffDuration
    stepDuration
    stepShares
    cliffShares
    initialAmount
    initialShares
    remainingShares
    withdrawnAmount
    fromBentoBox
    token {
      id
      name
      symbol
      decimals
    }
    recipient {
      id
    }
    createdBy {
      id
    }
  }
  outgoingVestings: vestings(
    where: {createdBy: $id}
    orderBy: startedAt
    block: $block
  ) {
    id
    __typename
    status
    steps
    startedAt
    expiresAt
    modifiedAtTimestamp
    cliffDuration
    stepDuration
    stepShares
    cliffShares
    initialAmount
    initialShares
    remainingShares
    withdrawnAmount
    fromBentoBox
    token {
      id
      name
      symbol
      decimals
    }
    recipient {
      id
    }
    createdBy {
      id
    }
  }
}
    ` as unknown as DocumentNode<userVestingsQuery, userVestingsQueryVariables>;
export const vestingIdsDocument = gql`
    query vestingIds($first: Int = 1000, $skip: Int = 0, $where: Vesting_filter, $block: Block_height) {
  vestings(first: $first, skip: $skip, where: $where, block: $block) {
    id
  }
}
    ` as unknown as DocumentNode<vestingIdsQuery, vestingIdsQueryVariables>;
export const vestingDocument = gql`
    query vesting($block: Block_height, $id: ID!) {
  vesting(block: $block, id: $id) {
    id
    __typename
    status
    steps
    startedAt
    expiresAt
    modifiedAtTimestamp
    cliffDuration
    stepDuration
    stepShares
    cliffShares
    initialShares
    initialAmount
    remainingShares
    withdrawnAmount
    fromBentoBox
    txHash
    token {
      id
      name
      symbol
      decimals
    }
    status
    recipient {
      id
    }
    createdBy {
      id
    }
  }
}
    ` as unknown as DocumentNode<vestingQuery, vestingQueryVariables>;
export const vestingTransactionsDocument = gql`
    query vestingTransactions($block: Block_height, $first: Int = 1000, $orderBy: Transaction_orderBy = createdAtTimestamp, $orderDirection: OrderDirection = desc, $skip: Int, $where: Transaction_filter) {
  transactions(
    block: $block
    first: $first
    orderBy: $orderBy
    orderDirection: $orderDirection
    skip: $skip
    where: $where
  ) {
    id
    type
    amount
    toBentoBox
    createdAtBlock
    createdAtTimestamp
    txHash
    token {
      id
      name
      symbol
      decimals
    }
    to {
      id
    }
  }
}
    ` as unknown as DocumentNode<vestingTransactionsQuery, vestingTransactionsQueryVariables>;
export const userStreamsDocument = gql`
    query userStreams($block: Block_height, $id: String!) {
  incomingStreams: streams(
    where: {recipient: $id}
    orderBy: startedAt
    block: $block
  ) {
    id
    __typename
    status
    startedAt
    expiresAt
    extendedAtTimestamp
    modifiedAtTimestamp
    initialShares
    initialAmount
    extendedShares
    initialSharesExtended
    remainingShares
    withdrawnAmount
    withdrawnAmountAfterExtension
    recipient {
      id
    }
    createdBy {
      id
    }
    token {
      id
      symbol
      name
      decimals
    }
  }
  outgoingStreams: streams(
    where: {createdBy: $id}
    orderBy: startedAt
    block: $block
  ) {
    id
    __typename
    status
    startedAt
    expiresAt
    extendedAtTimestamp
    modifiedAtTimestamp
    initialShares
    initialAmount
    extendedShares
    initialSharesExtended
    remainingShares
    withdrawnAmount
    withdrawnAmountAfterExtension
    recipient {
      id
    }
    createdBy {
      id
    }
    token {
      id
      symbol
      name
      decimals
    }
  }
}
    ` as unknown as DocumentNode<userStreamsQuery, userStreamsQueryVariables>;
export const streamIdsDocument = gql`
    query streamIds($first: Int = 1000, $skip: Int = 0, $where: Stream_filter, $block: Block_height) {
  streams(first: $first, skip: $skip, where: $where, block: $block) {
    id
  }
}
    ` as unknown as DocumentNode<streamIdsQuery, streamIdsQueryVariables>;
export const streamDocument = gql`
    query stream($block: Block_height, $id: ID!) {
  stream(block: $block, id: $id) {
    id
    __typename
    status
    startedAt
    expiresAt
    extendedAtTimestamp
    modifiedAtTimestamp
    initialShares
    initialAmount
    extendedShares
    initialSharesExtended
    remainingShares
    withdrawnAmount
    withdrawnAmountAfterExtension
    txHash
    recipient {
      id
    }
    createdBy {
      id
    }
    token {
      id
      symbol
      name
      decimals
    }
  }
}
    ` as unknown as DocumentNode<streamQuery, streamQueryVariables>;
export const streamTransactionsDocument = gql`
    query streamTransactions($block: Block_height, $first: Int = 1000, $orderBy: Transaction_orderBy = createdAtTimestamp, $orderDirection: OrderDirection = desc, $skip: Int, $where: Transaction_filter) {
  transactions(
    block: $block
    first: $first
    orderBy: $orderBy
    orderDirection: $orderDirection
    skip: $skip
    where: $where
  ) {
    id
    type
    amount
    toBentoBox
    createdAtBlock
    createdAtTimestamp
    txHash
    token {
      id
      name
      symbol
      decimals
    }
    to {
      id
    }
  }
}
    ` as unknown as DocumentNode<streamTransactionsQuery, streamTransactionsQueryVariables>;
export const bentoBoxRebaseDocument = gql`
    query bentoBoxRebase($block: Block_height, $id: ID!) {
  rebase(block: $block, id: $id) {
    id
    base
    elastic
  }
}
    ` as unknown as DocumentNode<bentoBoxRebaseQuery, bentoBoxRebaseQueryVariables>;
export const bentoBoxRebasesDocument = gql`
    query bentoBoxRebases($block: Block_height, $first: Int = 1000, $orderBy: Rebase_orderBy, $orderDirection: OrderDirection, $skip: Int, $where: Rebase_filter) {
  rebases(
    block: $block
    first: $first
    orderBy: $orderBy
    orderDirection: $orderDirection
    skip: $skip
    where: $where
  ) {
    id
    base
    elastic
  }
}
    ` as unknown as DocumentNode<bentoBoxRebasesQuery, bentoBoxRebasesQueryVariables>;











export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    userVestings(variables: userVestingsQueryVariables, options?: C): Promise<userVestingsQuery> {
      return requester<userVestingsQuery, userVestingsQueryVariables>(userVestingsDocument, variables, options) as Promise<userVestingsQuery>;
    },
    vestingIds(variables?: vestingIdsQueryVariables, options?: C): Promise<vestingIdsQuery> {
      return requester<vestingIdsQuery, vestingIdsQueryVariables>(vestingIdsDocument, variables, options) as Promise<vestingIdsQuery>;
    },
    vesting(variables: vestingQueryVariables, options?: C): Promise<vestingQuery> {
      return requester<vestingQuery, vestingQueryVariables>(vestingDocument, variables, options) as Promise<vestingQuery>;
    },
    vestingTransactions(variables?: vestingTransactionsQueryVariables, options?: C): Promise<vestingTransactionsQuery> {
      return requester<vestingTransactionsQuery, vestingTransactionsQueryVariables>(vestingTransactionsDocument, variables, options) as Promise<vestingTransactionsQuery>;
    },
    userStreams(variables: userStreamsQueryVariables, options?: C): Promise<userStreamsQuery> {
      return requester<userStreamsQuery, userStreamsQueryVariables>(userStreamsDocument, variables, options) as Promise<userStreamsQuery>;
    },
    streamIds(variables?: streamIdsQueryVariables, options?: C): Promise<streamIdsQuery> {
      return requester<streamIdsQuery, streamIdsQueryVariables>(streamIdsDocument, variables, options) as Promise<streamIdsQuery>;
    },
    stream(variables: streamQueryVariables, options?: C): Promise<streamQuery> {
      return requester<streamQuery, streamQueryVariables>(streamDocument, variables, options) as Promise<streamQuery>;
    },
    streamTransactions(variables?: streamTransactionsQueryVariables, options?: C): Promise<streamTransactionsQuery> {
      return requester<streamTransactionsQuery, streamTransactionsQueryVariables>(streamTransactionsDocument, variables, options) as Promise<streamTransactionsQuery>;
    },
    bentoBoxRebase(variables: bentoBoxRebaseQueryVariables, options?: C): Promise<bentoBoxRebaseQuery> {
      return requester<bentoBoxRebaseQuery, bentoBoxRebaseQueryVariables>(bentoBoxRebaseDocument, variables, options) as Promise<bentoBoxRebaseQuery>;
    },
    bentoBoxRebases(variables?: bentoBoxRebasesQueryVariables, options?: C): Promise<bentoBoxRebasesQuery> {
      return requester<bentoBoxRebasesQuery, bentoBoxRebasesQueryVariables>(bentoBoxRebasesDocument, variables, options) as Promise<bentoBoxRebasesQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;