import React from 'react'

import { IconComponent } from '../../types'

export const RabbyIcon: IconComponent = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g clip-path="url(#clip0_8493_47054)">
        <path
          d="M31.0134 17.8891C32.2049 15.2272 26.3141 7.79012 20.6864 4.69171C17.1391 2.2914 13.4428 2.62117 12.6941 3.67508C11.0512 5.988 18.1343 7.94784 22.8713 10.2348C21.8531 10.6771 20.8935 11.4708 20.3292 12.4858C18.5632 10.5577 14.687 8.89745 10.1388 10.2348C7.07384 11.1361 4.52662 13.2608 3.54214 16.4699C3.30292 16.3636 3.03807 16.3045 2.75942 16.3045C1.69387 16.3045 0.830078 17.1683 0.830078 18.2339C0.830078 19.2994 1.69387 20.1632 2.75942 20.1632C2.95693 20.1632 3.57446 20.0307 3.57446 20.0307L13.4428 20.1023C9.49621 26.363 6.37732 27.2782 6.37732 28.3629C6.37732 29.4475 9.36154 29.1536 10.4821 28.7493C15.846 26.8139 21.6072 20.7819 22.5958 19.0455C26.7474 19.5635 30.2364 19.6248 31.0134 17.8891Z"
          fill="url(#paint0_linear_8493_47054)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.8635 10.2299C22.8668 10.2313 22.8701 10.2328 22.8733 10.2342C23.0929 10.1477 23.0574 9.82342 22.9971 9.56876C22.8585 8.98343 20.4676 6.62243 18.2223 5.56493C15.1659 4.12536 12.9144 4.19809 12.5781 4.86044C13.2067 6.13575 16.092 7.33338 19.1053 8.58413C20.3927 9.11847 21.7034 9.66251 22.8714 10.2264C22.8688 10.2276 22.8661 10.2287 22.8635 10.2299Z"
          fill="url(#paint1_linear_8493_47054)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.9891 23.0765C18.3701 22.8399 17.6709 22.6229 16.8759 22.4259C17.7231 20.9099 17.9009 18.6656 17.1008 17.2466C15.9778 15.2552 14.5682 14.1953 11.2926 14.1953C9.49097 14.1953 4.64026 14.8022 4.55415 18.8514C4.54511 19.2765 4.55393 19.666 4.58472 20.0243L13.4432 20.0884C12.2491 21.9827 11.1308 23.3876 10.1518 24.4559C11.3269 24.757 12.2967 25.0099 13.1871 25.242C14.0326 25.4624 14.8065 25.6641 15.6165 25.8708C16.8377 24.981 17.9858 24.0108 18.9891 23.0765Z"
          fill="url(#paint2_linear_8493_47054)"
        />
        <path
          d="M3.42431 19.6145C3.78616 22.6905 5.53433 23.896 9.10656 24.2528C12.6788 24.6095 14.7279 24.3702 17.4559 24.6184C19.7343 24.8257 21.7687 25.9867 22.5234 25.5855C23.2026 25.2244 22.8226 23.9198 21.9137 23.0828C20.7356 21.9979 19.1052 21.2435 16.2362 20.9759C16.8079 19.4104 16.6477 17.2154 15.7598 16.0212C14.4758 14.2946 12.106 13.5139 9.10656 13.855C5.97287 14.2113 2.97019 15.7541 3.42431 19.6145Z"
          fill="url(#paint3_linear_8493_47054)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8493_47054"
          x1="9.78192"
          y1="15.5318"
          x2="30.7568"
          y2="21.4799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8797FF" />
          <stop offset="1" stopColor="#AAA8FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8493_47054"
          x1="27.2261"
          y1="15.1318"
          x2="12.0919"
          y2="-0.0381601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B22A0" />
          <stop offset="1" stopColor="#5156D8" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8493_47054"
          x1="19.4102"
          y1="23.6048"
          x2="4.87137"
          y2="15.2457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B1E8F" />
          <stop offset="1" stopColor="#6A6FFB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8493_47054"
          x1="11.0345"
          y1="15.3678"
          x2="20.8612"
          y2="27.8537"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8898FF" />
          <stop offset="0.983895" stopColor="#5F47F1" />
        </linearGradient>
        <clipPath id="clip0_8493_47054">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
