import * as React from 'react'

import { IconComponent } from '../../../../types'

export const ArbitrumNovaCircle: IconComponent = (props) => (
  <svg {...props} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="128" height="128" rx="64" fill="#2D374B" />
    <path
      d="M52.0997 37H44.6809C44.1689 37 43.7115 37.3197 43.5359 37.8001L24.0373 91.1822C23.8927 91.5786 24.1863 92 24.6098 92H32.0286C32.5406 92 32.998 91.6803 33.1736 91.1999L52.6737 37.8178C52.8183 37.4199 52.5232 37 52.0997 37ZM61.1238 59.3672C60.929 58.8338 60.1736 58.8338 59.9788 59.3672L56.1322 69.8966C56.0334 70.1662 56.0334 70.4609 56.1322 70.7305L63.6085 91.1984C63.7841 91.6788 64.2415 91.9985 64.7535 91.9985H72.1723C72.5958 91.9985 72.8894 91.5786 72.7448 91.1808L61.1238 59.3672ZM80.0352 69.6741C80.23 70.2075 80.9854 70.2075 81.1802 69.6741L92.8159 37.8178C92.9605 37.4214 92.6669 37 92.2434 37H84.8246C84.3126 37 83.8552 37.3197 83.6797 37.8001L76.1886 58.3092C76.0897 58.5789 76.0897 58.8736 76.1886 59.1432L80.0352 69.6741ZM65.4086 37.7942C65.2345 37.3168 64.7801 37 64.2725 37H56.8287C56.3167 37 55.8593 37.3197 55.6837 37.8001L36.185 91.1822C36.0404 91.5786 36.3341 92 36.7575 92H44.1763C44.6883 92 45.1457 91.6803 45.3213 91.1999L60.2503 50.3275C60.3477 50.0593 60.7284 50.0593 60.8258 50.3275L75.7548 91.1999C75.9304 91.6803 76.3878 92 76.8998 92H84.3185C84.742 92 85.0356 91.5801 84.891 91.1822L65.4086 37.7942ZM104.39 37H96.9709C96.4589 37 96.0015 37.3197 95.8259 37.8001L82.2617 74.9358C82.1629 75.2055 82.1629 75.5001 82.2617 75.7698L86.1083 86.3006C86.3031 86.834 87.0585 86.834 87.2533 86.3006L100.68 49.5407L104.962 37.8178C105.108 37.4199 104.813 37 104.39 37Z"
      fill="#EF8220"
    />
  </svg>
)
