import * as React from 'react'

import { IconComponent } from '../../../../types'

export const OkexNaked: IconComponent = (props) => (
  <svg
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className="dark:text-white text-gray-700"
  >
    <path
      d="M75.5727 50.6799H52.4501C51.9783 50.6799 51.5259 50.8673 51.1923 51.2009C50.8588 51.5345 50.6714 51.9869 50.6714 52.4586V75.5813C50.6714 76.053 50.8588 76.5054 51.1923 76.839C51.5259 77.1726 51.9783 77.36 52.4501 77.36H75.5727C76.0445 77.36 76.4969 77.1726 76.8304 76.839C77.164 76.5054 77.3514 76.053 77.3514 75.5813V52.4586C77.3514 51.9869 77.164 51.5345 76.8304 51.2009C76.4969 50.8673 76.0445 50.6799 75.5727 50.6799Z"
      fill="currentColor"
    />
    <path
      d="M48.9014 24H25.7787C25.3069 24 24.8546 24.1874 24.521 24.521C24.1874 24.8545 24 25.3069 24 25.7787V48.9014C24 49.3731 24.1874 49.8255 24.521 50.1591C24.8546 50.4926 25.3069 50.68 25.7787 50.68H48.9014C49.3731 50.68 49.8255 50.4926 50.1591 50.1591C50.4926 49.8255 50.68 49.3731 50.68 48.9014V25.7787C50.68 25.3069 50.4926 24.8545 50.1591 24.521C49.8255 24.1874 49.3731 24 48.9014 24Z"
      fill="currentColor"
    />
    <path
      d="M102.222 24H79.099C78.6272 24 78.1748 24.1874 77.8413 24.521C77.5077 24.8545 77.3203 25.3069 77.3203 25.7787V48.9014C77.3203 49.3731 77.5077 49.8255 77.8413 50.1591C78.1748 50.4926 78.6272 50.68 79.099 50.68H102.222C102.693 50.68 103.146 50.4926 103.479 50.1591C103.813 49.8255 104 49.3731 104 48.9014V25.7787C104 25.3069 103.813 24.8545 103.479 24.521C103.146 24.1874 102.693 24 102.222 24Z"
      fill="currentColor"
    />
    <path
      d="M48.9014 77.3201H25.7787C25.3069 77.3201 24.8546 77.5075 24.521 77.841C24.1874 78.1746 24 78.627 24 79.0987V102.221C24 102.693 24.1874 103.146 24.521 103.479C24.8546 103.813 25.3069 104 25.7787 104H48.9014C49.3731 104 49.8255 103.813 50.1591 103.479C50.4926 103.146 50.68 102.693 50.68 102.221V79.0987C50.68 78.627 50.4926 78.1746 50.1591 77.841C49.8255 77.5075 49.3731 77.3201 48.9014 77.3201Z"
      fill="currentColor"
    />
    <path
      d="M102.222 77.3201H79.099C78.6272 77.3201 78.1748 77.5075 77.8413 77.841C77.5077 78.1746 77.3203 78.627 77.3203 79.0987V102.221C77.3203 102.693 77.5077 103.146 77.8413 103.479C78.1748 103.813 78.6272 104 79.099 104H102.222C102.693 104 103.146 103.813 103.479 103.479C103.813 103.146 104 102.693 104 102.221V79.0987C104 78.627 103.813 78.1746 103.479 77.841C103.146 77.5075 102.693 77.3201 102.222 77.3201Z"
      fill="currentColor"
    />
  </svg>
)
