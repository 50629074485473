import { useCallback, useMemo, useState } from 'react';
export var FundSource;
(function (FundSource) {
    FundSource["WALLET"] = "WALLET";
    FundSource["BENTOBOX"] = "BENTOBOX";
})(FundSource || (FundSource = {}));
export const useFundSourceToggler = (initialValue) => {
    const [fundSource, setFundSource] = useState(initialValue);
    const toggle = useCallback(() => {
        setFundSource((prevState) => (prevState === FundSource.BENTOBOX ? FundSource.WALLET : FundSource.BENTOBOX));
    }, []);
    return useMemo(() => ({
        value: fundSource,
        fromWallet: fundSource === FundSource.WALLET,
        fromBentobox: fundSource === FundSource.BENTOBOX,
        toggle,
        setValue: setFundSource,
    }), [fundSource, toggle]);
};
