import React from 'react'

import { IconComponent } from '../../../../types'

export const CoreNaked: IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="none" viewBox="0 0 128 128" {...props}>
      <path
        fill="#FF9500"
        d="M64.33 32.525c.722 0 1.378.196 1.968.524l24.197 13.967a3.935 3.935 0 011.967 3.41v27.148a3.935 3.935 0 01-1.967 3.41L66.298 94.95a4.005 4.005 0 01-1.967.524 4.006 4.006 0 01-1.968-.524L38.102 80.984a3.935 3.935 0 01-1.967-3.41V50.426c0-1.377.721-2.688 1.967-3.41l24.197-13.901c.656-.394 1.311-.59 2.033-.59zm0-7.87c-2.032 0-4.065.525-5.9 1.575L34.166 40.197c-3.672 2.098-5.902 6.032-5.902 10.23v27.147c0 4.197 2.23 8.13 5.902 10.23l24.197 13.967a11.882 11.882 0 005.901 1.573c2.033 0 4.066-.524 5.902-1.573l24.197-13.968c3.672-2.098 5.901-6.032 5.901-10.23V50.427c0-4.197-2.23-8.13-5.901-10.23L70.231 26.23a11.881 11.881 0 00-5.901-1.574z"
      ></path>
    </svg>
  )
}
