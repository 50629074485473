import * as React from 'react'

import { IconComponent } from '../../../../types'

export const BobaCircle: IconComponent = (props) => (
  <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="128" height="128" rx="64" fill="url(#paint0_linear_706_782)" />
    <g clipPath="url(#clip0_706_782)">
      <path
        d="M50.3959 79.5006C48.9278 79.5006 47.6793 78.4519 47.4297 76.9937L39.0401 27.7153C38.9103 26.9263 39.0901 26.1273 39.5495 25.4682C40.0089 24.809 40.7081 24.3795 41.5071 24.2497C43.145 23.98 44.7031 25.0786 44.9827 26.7166L53.3622 75.995C53.4921 76.784 53.3123 77.583 52.8529 78.2422C52.3835 78.9014 51.6943 79.3308 50.9053 79.4706C50.7355 79.4906 50.5657 79.5006 50.3959 79.5006Z"
        fill="black"
      />
      <path
        d="M64.4386 56.7791C59.6446 56.7791 55.1902 58.2173 51.4648 60.6742L52.4836 66.6367C55.6895 63.8502 59.8743 62.1523 64.4386 62.1523C74.496 62.1523 82.6758 70.3321 82.6758 80.3895C82.6758 90.447 74.496 98.6267 64.4386 98.6267C54.3812 98.6267 46.2014 90.447 46.2014 80.3895C46.2014 79.0512 46.3512 77.7428 46.6309 76.4844L45.0129 66.9863C42.3762 70.7915 40.8281 75.4158 40.8281 80.3895C40.8281 93.4033 51.4149 104 64.4386 104C77.4623 104 88.0491 93.4033 88.0491 80.3895C88.0491 67.3758 77.4623 56.7791 64.4386 56.7791Z"
        fill="black"
      />
      <path
        d="M63.1501 94.1624C64.9262 94.1624 66.366 92.7226 66.366 90.9464C66.366 89.1703 64.9262 87.7305 63.1501 87.7305C61.3739 87.7305 59.9341 89.1703 59.9341 90.9464C59.9341 92.7226 61.3739 94.1624 63.1501 94.1624Z"
        fill="black"
      />
      <path
        d="M73.1974 90.4171C74.9736 90.4171 76.4134 88.9772 76.4134 87.2011C76.4134 85.425 74.9736 83.9851 73.1974 83.9851C71.4213 83.9851 69.9814 85.425 69.9814 87.2011C69.9814 88.9772 71.4213 90.4171 73.1974 90.4171Z"
        fill="black"
      />
      <path
        d="M65.297 83.5257C67.0732 83.5257 68.513 82.0859 68.513 80.3097C68.513 78.5336 67.0732 77.0938 65.297 77.0938C63.5209 77.0938 62.0811 78.5336 62.0811 80.3097C62.0811 82.0859 63.5209 83.5257 65.297 83.5257Z"
        fill="black"
      />
      <path
        d="M55.3097 86.8116C57.0859 86.8116 58.5257 85.3718 58.5257 83.5956C58.5257 81.8195 57.0859 80.3796 55.3097 80.3796C53.5336 80.3796 52.0938 81.8195 52.0938 83.5956C52.0938 85.3718 53.5336 86.8116 55.3097 86.8116Z"
        fill="black"
      />
      <path
        d="M75.2448 80.2298C77.0209 80.2298 78.4608 78.79 78.4608 77.0138C78.4608 75.2377 77.0209 73.7979 75.2448 73.7979C73.4687 73.7979 72.0288 75.2377 72.0288 77.0138C72.0288 78.79 73.4687 80.2298 75.2448 80.2298Z"
        fill="black"
      />
      <path
        d="M67.4445 72.889C69.2206 72.889 70.6605 71.4491 70.6605 69.673C70.6605 67.8969 69.2206 66.457 67.4445 66.457C65.6684 66.457 64.2285 67.8969 64.2285 69.673C64.2285 71.4491 65.6684 72.889 67.4445 72.889Z"
        fill="black"
      />
      <path
        d="M57.3673 76.6243C59.1435 76.6243 60.5833 75.1845 60.5833 73.4084C60.5833 71.6322 59.1435 70.1924 57.3673 70.1924C55.5912 70.1924 54.1514 71.6322 54.1514 73.4084C54.1514 75.1845 55.5912 76.6243 57.3673 76.6243Z"
        fill="black"
      />
      <path
        d="M89.7867 35.5256L89.6069 36.1349V53.9326L89.7867 54.1224L98.0464 49.2385L89.7867 35.5256Z"
        fill="black"
      />
      <path d="M89.7868 35.5256L81.5171 49.2385L89.7868 54.1224V45.4732V35.5256Z" fill="black" />
      <path d="M89.7869 55.6805L89.687 55.8104V62.1424L89.7869 62.4421L98.0466 50.7966L89.7869 55.6805Z" fill="black" />
      <path d="M89.7868 62.4421V55.6805L81.5171 50.7966L89.7868 62.4421Z" fill="black" />
      <path d="M89.7866 54.1223L98.0463 49.2384L89.7866 45.4731V54.1223Z" fill="black" />
      <path d="M81.5171 49.2384L89.7868 54.1223V45.4731L81.5171 49.2384Z" fill="black" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_706_782" x1="0" y1="0" x2="128" y2="140.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1CD8D2" />
        <stop offset="1" stopColor="#CCFF00" />
      </linearGradient>
      <clipPath id="clip0_706_782">
        <rect width="59.0262" height="80" fill="white" transform="translate(39 24)" />
      </clipPath>
    </defs>
  </svg>
)
